import http_multipart from "@/http-multipart";
import http from "@/http-common";


class FinanceKidsV2Service {

    /**
     * Đọc dữ liệu từ file Excel hiển thị thông báo lên màn hình
     */
    importExcelFile(formExcelData) {
        return http_multipart.post("/fn/fees/callReadDataExcel", formExcelData);
    }

    /**
     * Lưu dữ liệu đọc được từ file Excel vào DB
     */
    saveDataFromExcelToDB(listObjectKid) {
        return http.post("/fn/fees/saveDataFromExcelToDB", listObjectKid);
    }

    exportExcelTemplate(orderRequest) {
        return http.get("/fn/fees/tuition/exportExcel", orderRequest);
    }

    downloadExcelFile(guiId) {
        return http.get("/fn/fees/downloadFileTemplate", {
            responseType: "blob",
            params: {guiId},
        });
    }

    downloadLinkExcel(guiId) {
        return http.get("/fn/fees/linkPrintOrder", {
            params: {guiId},
        })
    }

    // lấy danh sách template có sẵn
    listFileSchoolTemplate(idClass, year) {
        return http.get("fn/fees/templateSchool", {params: {idClass, year}});
    }

    /**
     * Lưu lịch sử thanh toán theo đợt cho từng học sinh
     */
    saveKidPaymentForTurn(kid_info) {
        return http.post("/fn/fees/order/paymentForTurn", kid_info);
    }

    deleteTemplateSchool(template_info) {
        return http.get('/fn/fees/deleteTemplateSchool', {params: template_info})
    }

    // tra ve thong tin hoa don 1 hoc sinh
    getKidExcelInfoByMonthAndTurn(month, idKid, collectionTurn, year) {
        return http.get(
            '/fn/fees/orderKidsExcel/forMonthAndTurn', {params: {month, idKid, collectionTurn, year}}
        );
    }

    //luu thong tin diem danh, ghi chu cho 1 hoc sinh từ pop up thông tin hiển thị


    // tạo ghi chú cho1 , nhiều hs
    createNoteForListKids(noteRequestParams) {
        return http.post(
            "/fn/fees/orderKidsExcel/createNoteForListKids",
            noteRequestParams
        );
    }

    //hủy hóa đơn theo đợt
    deleteOrderKidsExcel(date_idKid_turnParams) {
        return http.post(
            "/fn/fees/orderKidsExcel/deleteOrderKidsExcel",
            date_idKid_turnParams
        );
    }

    //khôi phục hóa đơn theo đợt
    openOrderKidsExcel(date_idKid_turnParams) {
        return http.post(
            "/fn/fees/orderKidsExcel/openOrderKidsExcel",
            date_idKid_turnParams
        );
    }

    //xóa hóa đơn theo đợt
    destroyOrderKidsExcel(date_idKid_turnParams) {
        return http.post(
            "/fn/fees/orderKidsExcel/destroyOrderKidsExcel",
            date_idKid_turnParams
        );
    }

    // sửa học phí học sinh theo tháng
    updateExcelKidMoney(kidParam) {
        return http.post(
            "/fn/fees/orderKidsExcel/modifyMoneyOderKidsExcel",
            kidParam
        );
    }

    // xem trước xuất hóa đơn
    showListPrintOrderKidsExcel(export_preview_param) {
        return http.get("/fn/fees/showPrintOrderKidsExcel", {
            params: export_preview_param,
        });
    }

    getListGradeBySchoolId(schoolId) {
        return http.get("/fn/fees/listGrade", schoolId);
    }

    getListClass(idGrade) {
        return http.get(`/fn/fees/listClass`, idGrade);
    }

    getKidsByName(idSchool, idGrade, idClass, name) {
        return http.get(`/fn/fees/listName`, idSchool, idGrade, idClass, name);
    }

    /**
     * Hiển thị toàn bộ lịch sử thanh toán
     */
    getHistoryOrderKidsCode(idKid, month, collectionTurn, year) {
        return http.get(
            `/fn/fees/orderKidsExcel/showAllHistoryOrder/${idKid}/${month}/${collectionTurn}/${year}`
        );
    }
    //Hiển thị toàn bộ lịch sử thanh toán cho group
    getHistoryOrderKidsCodeGroup(idKid, month, collectionTurn, year) {
        return http.get(
            `/fn/fees/orderKidsExcel/showAllHistoryOrderForGroup/${idKid}/${month}/${collectionTurn}/${year}`
        );
    }
    // filter
    getListKidsStatus() {
        return http.get("/fn/fees/listKidStatus");
    }

    getGradeInPrinciple() {
        return http.get("/common-data/grade-in-school");
    }

    getClassInGrade(idGrade) {
        return http.get(`/common-data/class-in-grade?idGrade=${idGrade}`);
    }

    getListGrade() {
        return http.get("/fn/fees/listGrade"); //filter danh sách khối
    }

    getListStatusExcel(month, idGrade, idClass, createdYear) {
        return http.get("/fn/fees/listStatusExcel", {
            params: {month, idGrade, idClass, createdYear},
        });
    }

    getCollectionTurn(month, idGrade, idClass, createdYear) {
        return http.get("/fn/fees/collectionTurn", {
            params: {
                month,
                idGrade,
                idClass, createdYear
            },
        }); // lấy lượt thu
    }

    get_kid_init(filterParams) {
        return http.post("/fn/fees/filterExcel",  filterParams);
    }

    // getAllFilter(month,kidStatus,idSchool,idGrade){
    //   return http.get('/fn/fees/filterExcel?month=1&kidStatus=STUDYING&idSchool=1&idGrade=1&idClass=1&status=Đóng thừa&name=An&collectionTurn=1&page=1&limit=2')
    // }
    searchKidsBirthday(date, week, month, name) {
        return http.get(`/teacher-birthday/searchnew?date=${date}&week=${week}&month=${month}&name=${name}`);
    }

    showCollectedMoney(month, idKid, collectionTurn, year) {
        return http.get(
            '/fn/fees/orderKidsExcel/showCollectedMoney', {params: {month, idKid, collectionTurn, year}}
        );
    }

    showListOrderApp(showParam) {
        return http.post("/fn/fees/orderKidsExcel/showListOrderForApp", showParam);
    }

    hiddenListOrderForApp(hiddenParam) {
        return http.post(
            "/fn/fees/orderKidsExcel/hiddenListOrderForApp",
            hiddenParam
        );
    }

    getListInventoryOrder(param) {
        return http.post("/fn/fees/listRemainingBill", param);
    }

    showDetailPayment(param) {
        return http.post("/fn/fees/orderKidsExcel/showDetailPayment", param);
    }

    saveDetailKT(param) {
        return http.post("/fn/fees/orderKidsExcel/modifyDetailPayment", param);
    }

    /**
     * Lấy danh sách khoản thu hiển thị trên màn hình lưu lịch sử thanh toán
     */

    showAllPaymentForKid(kidCode, collectionTurn, month, year) {
        return http.get(
            '/fn/fees/orderKidsExcel/showAllPaymentForKid', {params: {kidCode, collectionTurn, month, year}}
        );
    }

    /**
     * Dữ liệu hiển thị màn thông tin chi tiết
     */

    saveInfoAttendanceKid(param) {
        return http.post("/fn/fees/orderKidsExcel/saveInfoOderKidsExcel", param);
    }

// Hển thị/ bỏ hiện thị HĐ
    showOrHiddenOrderKids(kid_rows_info) {
        return http.post('/fn/fees/orderKidsExcel/showOrderHidden', kid_rows_info)
    }

//    Api xuất Excel theo tháng
    exportExcelByMonth(guiId, date, idKidList, collectionTurn, status, dateAttendance) {
        return http.get(
            `/fn/fees/tuition/exportExcelForMonth?guiId=${guiId}&date=${date}&idKidList=${idKidList}&collectionTurn=${collectionTurn}&status=${status}&dateAttendance=${dateAttendance}`);
    }

    // Gửi SMS
    sendSMS(request) {
        return http.post(`/fn/fees/orderKidExcel/notify`, request)
    }

    /**
     * Gửi thông báo học phí qua App kiểu mới
     */
    sendNotifyFinanceForApp(formData) {
        return http_multipart.post(`/fn/fees/orderKidExcel/notify/appNew`, formData);
    }

    // createdNotifyStudentSms(data) {
    //     return http.post("/student/student-sms", data);
    // }

    exportBillToExcel(bill_params) {
        return http.get('/fn/fees/fillDataExcel', {params: bill_params})
    }

    exportExcelForOneKid(onekidParam, guiId) {
        return http.get('/fn/fees/fillDataForOneKid', {params: {...onekidParam, guiId}})

    }

    dowloadBillToExcel(guiId) {
        return http.get('/fn/fees/downloadFileOrder',
            {params: {guiId}, responseType: "blob",})
    }


    /**
     * Sửa trạng thái kết chuyển, mở nút thanh toán màn tồn hóa đơn
     */

    changeStatusTransactionEntry(request) {
        return http.post(`/fn/fees/updateTransactionEntry`, request)
    }


//    Xuất file excel hàng loạt theo tháng
    downloadFileExcelAllByMonth(exportExcelAll) {
        return http.get(`/fn/fees/exportExcelCollectionTurns`, {params: exportExcelAll})
    }


    // Xuất hóa đơn hàng loạt theo tháng
    exportBillAllByMonth(exportBillAll) {
        return http.get(`/fn/fees/printOrderCollectionTurns`, {params: exportBillAll})
    }

    // Upload template nhà trường
    uploadFileTemplateSchool(formData) {
        return http_multipart.post(`/fn/fees/uploadTemplateSchool`, formData)
    }

    // Cập nhật dữ liệu điểm danh
    updateAttendanceData(idGrade, idClass, month, year) {
        return http.get(`/fn/fees/totalKidsArrive`, {params: {idGrade, idClass, month, year}})
    }

    // Ngày bắt đầu va kết thúc của đợt thu
    showStartAndEndDate(param) {
        return http.post(`/fn/fees/orderKidsExcel/showStartAndEndDate`, param)
    }

    // Tải hướng dẫn file mẫu
    instructUploadFileSample() {
        return http.get(`/fn/fees/downloadFileInstruction`, {responseType: "blob"})
    }

    // Thay đổi tên khoản thu
    changeNameKT(formData) {
        return http.post(`/fn/fees/orderKidsExcel/modifyNameKt`, formData)
    }

//    Thanh toán hàng loạt
    paymentAll(dataSubmit) {
        return http.post("/fn/fees/orderKidExcel/notify/appNewForStudents", dataSubmit)
    }

//     Lấy toàn bộ lớp trong trường
    getAllClassToSchool(){
        return http.get(`/fn/fees/getListClassInSchool`)
    }

// KHởi tạo hóa đơn cho học sinh chưa có hóa đơn trong đợt thu
    createInvoice(data){
        return http.get(`/fn/fees/orderKidsExcel/createNotHaveOrder`,{params: data})
    }

    // Thêm khoản thu mới
    addOneExpense(body){
        return http.post(`/fn/fees/orderKidsExcel/addOneKt`,body)
    }

    // Xóa khoản thu
    deleteLastExpense(body) {
        return http.post(`/fn/fees/orderKidsExcel/deleteLastKt`, body)
    }
}


export default new FinanceKidsV2Service();
