import {
    FILTER_STUDENTS_GROUP,
    SET_ID_KID_LIST,
    SET_RESET_FILTER,
    SET_TYPE_INPUT,
    UPDATE_DATA_SEARCH
} from "@/constant/actions";
import StudentGroupService from "@/services/Finance_Kids_V2/StudentGroupService";

export const studentGroupModule = {
    namespaced: true,
    state: {
        studentList: [],
        idKidList: "",
        totalPages:1,
        dataSearch:{},
        type:'',
        resetFilter: false
    },
    mutations: {
        [FILTER_STUDENTS_GROUP](state, payload) {
            state.studentList = payload.filterKidsGroupDTOList
            state.totalPages=payload.totalPage
            state.idKidList = ""
        },
        [UPDATE_DATA_SEARCH](state,payload){
            state.dataSearch=payload
        },
        [SET_ID_KID_LIST](state, payload){
            state.idKidList = payload.join(`,`)
        },
        [SET_TYPE_INPUT](state,payload){
            state.type=payload
        },
        [SET_RESET_FILTER](state,payload){
            state.resetFilter=payload
        }
    },
    actions: {
        [FILTER_STUDENTS_GROUP]({commit}, filter) {
            commit(UPDATE_DATA_SEARCH,filter)
            return StudentGroupService.filterListKidsForGroup(filter).then((res) => {
                if (res && res.data) {
                    commit(FILTER_STUDENTS_GROUP, res.data)
                }
            })
        }

    }
}