var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-main" },
    [
      _c(
        "el-menu",
        {
          staticClass: "el-menu-vertical-demo",
          attrs: {
            collapse: _vm.getIsOpenCloseManu,
            "background-color": "#304156",
            "text-color": "#bfcbd9",
            "active-text-color": "#409EFF",
            "unique-opened": true,
          },
        },
        [
          _c(
            "router-link",
            { staticClass: "router-link", attrs: { to: "/dashboard" } },
            [
              _c("el-menu-item", { attrs: { index: "1" } }, [
                _c("i", { staticClass: "el-icon-s-home" }),
                _c(
                  "span",
                  {
                    staticClass: "onekids-title",
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [_vm._v("ONEKIDS PLUS")]
                ),
              ]),
            ],
            1
          ),
          _vm.checkPermission(["news"])
            ? _c(
                "el-submenu",
                { attrs: { index: "2" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("i", { staticClass: "el-icon-notebook-2" }),
                    _c("span", [_vm._v("Bảng tin")]),
                  ]),
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: { to: "/appsend/notification" },
                    },
                    [
                      _vm.checkPermission(["news_notifySystem"])
                        ? _c(
                            "el-menu-item",
                            {
                              attrs: { index: "2-1" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "appsend-notification"
                                  )
                                },
                              },
                            },
                            [_vm._v("Thông báo hệ thống\n        ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: { to: "/notifihis/notify-school" },
                    },
                    [
                      _c(
                        "el-menu-item",
                        {
                          attrs: { index: "2-2" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "notifihis-notify-school"
                              )
                            },
                          },
                        },
                        [_vm._v("Tin tức nhà trường\n        ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: { to: "/appsend/parent-feedback" },
                    },
                    [
                      _vm.checkPermission(["news_feedbackParent"])
                        ? _c(
                            "el-menu-item",
                            {
                              attrs: { index: "2-3" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "appsend-parent-feedback"
                                  )
                                },
                              },
                            },
                            [_vm._v("Phụ huynh góp ý\n        ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: { to: "/appsend/teacher-feedback" },
                    },
                    [
                      _vm.checkPermission(["news_feedbackTeacher"])
                        ? _c(
                            "el-menu-item",
                            {
                              attrs: { index: "2-4" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "appsend-teacher-feedback"
                                  )
                                },
                              },
                            },
                            [_vm._v("Giáo viên góp ý\n        ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          _c(
            "el-submenu",
            { attrs: { index: "3" } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "el-icon-reading" }),
                _c("span", [_vm._v("Nhật ký phụ huynh")]),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/parentdiary/message-parent" },
                },
                [
                  _vm.checkPermission(["parentDiary_message"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "3-1" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "parentdiary-message-parent"
                              )
                            },
                          },
                        },
                        [_vm._v("Lời nhắn\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/parentdiary/medicine" },
                },
                [
                  _vm.checkPermission(["parentDiary_medicine"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "3-2" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "parentdiary-medicine"
                              )
                            },
                          },
                        },
                        [_vm._v("Dặn thuốc\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/parentdiary/take-leave" },
                },
                [
                  _vm.checkPermission(["parentDiary_absenLetter"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "3-3" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "parentdiary-take-leave"
                              )
                            },
                          },
                        },
                        [_vm._v("Xin nghỉ\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-submenu",
            { attrs: { index: "4" } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "el-icon-user" }),
                _c("span", [_vm._v("Quản lý nhân sự")]),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/employee/department" },
                },
                [
                  _vm.checkPermission(["infoEmployee_department"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "4-1" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "employee-department"
                              )
                            },
                          },
                        },
                        [_vm._v("Danh sách phòng ban\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/employee/employees" },
                },
                [
                  _vm.checkPermission(["infoEmployee_list"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "4-2" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "employee-employees"
                              )
                            },
                          },
                        },
                        [_vm._v("Danh sách nhân sự\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-submenu",
            { attrs: { index: "5" } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "el-icon-user-solid" }),
                _c("span", [_vm._v("Quản lý học sinh")]),
              ]),
              _c(
                "router-link",
                { staticClass: "router-link", attrs: { to: "/student/grade" } },
                [
                  _vm.checkPermission(["kids_grade"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "5-1" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod("student-grade")
                            },
                          },
                        },
                        [_vm._v("Danh sách khối\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                { staticClass: "router-link", attrs: { to: "/student/class" } },
                [
                  _vm.checkPermission(["kids_class"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "5-2" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod("student-class")
                            },
                          },
                        },
                        [_vm._v("Danh sách lớp\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/student/students" },
                },
                [
                  _vm.checkPermission(["kids_list"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "5-3" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "student-students"
                              )
                            },
                          },
                        },
                        [_vm._v("Danh sách học sinh\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/student/students-group" },
                },
                [
                  _vm.checkPermission(["kids_group"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "5-4" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "student-students-group"
                              )
                            },
                          },
                        },
                        [_vm._v("Nhóm học sinh\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.checkPermission(["kids_transfer"])
                ? _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: { to: "/student/students-transfer" },
                    },
                    [
                      _c(
                        "el-menu-item",
                        {
                          attrs: { index: "5-5" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "student-students-transfer"
                              )
                            },
                          },
                        },
                        [_vm._v("Thông tin mở rộng\n        ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
          _c(
            "el-submenu",
            { attrs: { index: "6" } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "el-icon-document-checked" }),
                _c("span", [_vm._v("Chất lượng học sinh")]),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/student-quality/attendance" },
                },
                [
                  _vm.checkPermission(["studentQuality_attendance"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "6-1" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "student-quality-attendance"
                              )
                            },
                          },
                        },
                        [_vm._v("Điểm danh\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/student-quality/evaluate" },
                },
                [
                  _vm.checkPermission(["studentQuality_evaluate"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "6-2" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "student-quality-evaluate"
                              )
                            },
                          },
                        },
                        [_vm._v("Nhận xét\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/student-quality/album" },
                },
                [
                  _vm.checkPermission(["studentQuality_ablum"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "6-3" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "student-quality-album"
                              )
                            },
                          },
                        },
                        [_vm._v("Album ảnh\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/student-quality/height-weight" },
                },
                [
                  _vm.checkPermission(["studentQuality_heightWeight"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "6-4" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "student-quality-height-weight"
                              )
                            },
                          },
                        },
                        [_vm._v("Chiều cao, cân nặng\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/student-quality/schedule" },
                },
                [
                  _vm.checkPermission(["studentQuality_schedule"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "6-5" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "student-quality-schedule"
                              )
                            },
                          },
                        },
                        [_vm._v("Thời khóa biểu\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/student-quality/class-menu" },
                },
                [
                  _vm.checkPermission(["studentQuality_menu"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "6-6" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "student-quality-class-menu"
                              )
                            },
                          },
                        },
                        [_vm._v("Thực đơn\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _vm.checkPermission(["fees"])
            ? _c(
                "el-submenu",
                { attrs: { index: "8" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("i", { staticClass: "el-icon-money" }),
                    _c("span", [_vm._v("Học phí & dịch vụ")]),
                  ]),
                  _vm.checkPermission(["fees_order"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/finace/fees/order" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "8-1" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "finace-fees-order"
                                  )
                                },
                              },
                            },
                            [_vm._v("Thu học phí\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkPermission(["fees_approved_advance"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/finace/fees/approved/advance" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "8-2" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "finace-fees-approved-advance"
                                  )
                                },
                              },
                            },
                            [_vm._v("Duyệt nâng cao\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkPermission(["fees_approved"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/finace/fees/approved" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "8-3" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "finace-fees-approved"
                                  )
                                },
                              },
                            },
                            [_vm._v("Duyệt học phí\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkPermission(["fees_kidsPackage"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/finace/fees/package-kids" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "8-4" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "finace-fees-package-kids"
                                  )
                                },
                              },
                            },
                            [_vm._v("Đăng ký khoản\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkPermission(["fees_package"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/finace/fees/package" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "8-5" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "finace-fees-package"
                                  )
                                },
                              },
                            },
                            [_vm._v("Danh sách khoản\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkPermission(["fees_wallet"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/finace/fees/wallet" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "8-6" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "finace-fees-wallet"
                                  )
                                },
                              },
                            },
                            [_vm._v("Quản lý ví\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkPermission(["fees_2_excel"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/finance/v2" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "8-7" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "finance-fees-order-excel"
                                  )
                                },
                              },
                            },
                            [_vm._v("Thu học phí theo lớp\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkPermission(["fees_2_group"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/finance/tuition-group-by-excel" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "8-8" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "finance-fees-order-excel-group"
                                  )
                                },
                              },
                            },
                            [_vm._v("Thu học phí theo nhóm\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkPermission(["fees_2_finance_tuition_statistics"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/finance/tuition-statistics" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "8-9" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "finance-tuition-statistics"
                                  )
                                },
                              },
                            },
                            [_vm._v("Thống kê học phí\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkPermission(["fees_2_finance_tuition_statistics"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/finance/tuition-statistics/charts" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "8-10" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "finance-tuition-statistics-charts"
                                  )
                                },
                              },
                            },
                            [_vm._v("Biểu đồ thống kê\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _c(
            "el-submenu",
            { attrs: { index: "9" } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "el-icon-coin" }),
                _c("span", [_vm._v(" Công lương ")]),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/finace/salary/paid" },
                },
                [
                  _vm.checkPermission(["salary_order"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "9-1" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "finace-salary-paid"
                              )
                            },
                          },
                        },
                        [_vm._v("Thanh toán lương\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/finace/salary/approved" },
                },
                [
                  _vm.checkPermission(["salary_approved"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "9-2" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "finace-salary-approved"
                              )
                            },
                          },
                        },
                        [_vm._v("Duyệt bảng lương\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/finace/salary/institute" },
                },
                [
                  _vm.checkPermission(["salary_setting"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "9-3" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "finace-salary-institute"
                              )
                            },
                          },
                        },
                        [_vm._v("Thiết lập tiền lương\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/finace/salary/sample" },
                },
                [
                  _vm.checkPermission(["salary_sample"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "9-4" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "finace-salary-sample"
                              )
                            },
                          },
                        },
                        [_vm._v("Mẫu công lương\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/finance/employee/salary/new" },
                },
                [
                  _vm.checkPermission(["salary_new_excel"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "9-5" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "finance-employee-salary-new"
                              )
                            },
                          },
                        },
                        [_vm._v("Bảng lương\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/finance/salary/statistical" },
                },
                [
                  _vm.checkPermission(["salary_new_excel_statistics"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "9-6" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "finance-salary-statistical"
                              )
                            },
                          },
                        },
                        [_vm._v("Thống kê công lương\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/finance/employee/static-salary/new" },
                },
                [
                  _vm.checkPermission(["salary_new_excel_chart_view"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "9-7" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "finance-employee-static-salary-new"
                              )
                            },
                          },
                        },
                        [_vm._v("Biểu đồ công lương\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-submenu",
            { attrs: { index: "10" } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "el-icon-document-checked" }),
                _c("span", [_vm._v("Chấm công")]),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/finace/salary/absent" },
                },
                [
                  _vm.checkPermission(["attendanceEmployee_absentTeacher"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "10-1" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "finace-salary-absent"
                              )
                            },
                          },
                        },
                        [_vm._v("Xin nghỉ nhân sự\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/finace/salary/attendance" },
                },
                [
                  _vm.checkPermission(["attendanceEmployee_attendance"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "10-2" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "finace-salary-attendance"
                              )
                            },
                          },
                        },
                        [_vm._v("Chấm công nhân viên\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/finace/salary/attendance-config" },
                },
                [
                  _vm.checkPermission(["attendanceEmployee_config"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "10-3" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "finace-salary-attendance-config"
                              )
                            },
                          },
                        },
                        [_vm._v("Thiết lập chấm công\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-submenu",
            { attrs: { index: "11" } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "el-icon-suitcase-1" }),
                _c(
                  "span",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "rgb(191, 203, 217)" },
                        attrs: { type: "text" },
                      },
                      [_vm._v("Thu chi nội bộ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/finace/cashinternal/paymoney" },
                },
                [
                  _vm.checkPermission(["cashInternal_out"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "11-1" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "finace-cashinternal-paymoney"
                              )
                            },
                          },
                        },
                        [_vm._v("Quản lý phiếu chi\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/finace/cashinternal/collectmoney" },
                },
                [
                  _vm.checkPermission(["cashInternal_in"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "11-2" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "finace-cashinternal-collectmoney"
                              )
                            },
                          },
                        },
                        [_vm._v("Quản lý phiếu thu\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/finace/cashinternal/cashbook" },
                },
                [
                  _vm.checkPermission(["cashInternal_cashbook"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "11-3" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "finace-cashinternal-cashbook"
                              )
                            },
                          },
                        },
                        [_vm._v("Quản lý quỹ\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/finace/cashinternal/peopletype" },
                },
                [
                  _vm.checkPermission(["cashInternal_people"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "11-4" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "finace-cashinternal-peopletype"
                              )
                            },
                          },
                        },
                        [_vm._v("Danh sách đối tượng\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/finace/cashinternal/bankinfo" },
                },
                [
                  _vm.checkPermission(["cashInternal_bank"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "11-5" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "finace-cashinternal-bankinfo"
                              )
                            },
                          },
                        },
                        [_vm._v("Thông tin thanh toán\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _vm.checkPermission(["statistical"])
            ? _c(
                "el-submenu",
                { attrs: { index: "12" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("i", { staticClass: "el-icon-s-data" }),
                    _c("span", [_vm._v("Thống kê")]),
                  ]),
                  _vm.checkPermission(["statistical_kids"])
                    ? _c(
                        "el-submenu",
                        { attrs: { index: "12-1" } },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v("Học sinh"),
                          ]),
                          _c(
                            "router-link",
                            {
                              staticClass: "router-link",
                              attrs: { to: "/chart/statistical/chart-status" },
                            },
                            [
                              _vm.checkPermission(["statistical_kids_status"])
                                ? _c(
                                    "el-menu-item",
                                    {
                                      attrs: { index: "12-1-1" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeBreadcrumbMethod(
                                            "chart-statistical-status"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Trạng thái\n          ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "router-link",
                              attrs: { to: "/chart/statistical/attendance" },
                            },
                            [
                              _vm.checkPermission([
                                "statistical_kids_attendance",
                              ])
                                ? _c(
                                    "el-menu-item",
                                    {
                                      attrs: { index: "12-1-2" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeBreadcrumbMethod(
                                            "chart-statistical-attendance"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Điểm danh\n          ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "router-link",
                              attrs: { to: "/chart/statistical/evaluate" },
                            },
                            [
                              _vm.checkPermission(["statistical_kids_evaluate"])
                                ? _c(
                                    "el-menu-item",
                                    {
                                      attrs: { index: "12-1-3" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeBreadcrumbMethod(
                                            "chart-statistical-evaluate"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Nhận xét\n          ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "router-link",
                              attrs: { to: "/chart/statistical/album" },
                            },
                            [
                              _vm.checkPermission(["statistical_kids_album"])
                                ? _c(
                                    "el-menu-item",
                                    {
                                      attrs: { index: "12-1-4" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeBreadcrumbMethod(
                                            "chart-statistical-album"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Album ảnh\n          ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "router-link",
                              attrs: { to: "/chart/statistical/fees" },
                            },
                            [
                              _vm.checkPermission(["statistical_kids_fees"])
                                ? _c(
                                    "el-menu-item",
                                    {
                                      attrs: { index: "12-1-5" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeBreadcrumbMethod(
                                            "chart-statistical-fees"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Học phí\n          ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.checkPermission(["statistical_employee"])
                    ? _c(
                        "el-submenu",
                        { attrs: { index: "12-2" } },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v("Nhân sự"),
                          ]),
                          _c(
                            "router-link",
                            {
                              staticClass: "router-link",
                              attrs: {
                                to: "/chart/statistical/chart-status-employee",
                              },
                            },
                            [
                              _vm.checkPermission([
                                "statistical_employee_status",
                              ])
                                ? _c(
                                    "el-menu-item",
                                    {
                                      attrs: { index: "12-2-1" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeBreadcrumbMethod(
                                            "chart-statistical-status-employee"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Trạng thái\n          ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "router-link",
                              attrs: {
                                to: "/chart/statistical/attendance-employee",
                              },
                            },
                            [
                              _vm.checkPermission([
                                "statistical_employee_attendance",
                              ])
                                ? _c(
                                    "el-menu-item",
                                    {
                                      attrs: { index: "12-2-2" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeBreadcrumbMethod(
                                            "chart-statistical-attendance-employee"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Điểm danh\n          ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "router-link",
                              attrs: { to: "/chart/statistical/finace" },
                            },
                            [
                              _vm.checkPermission([
                                "statistical_employee_salary",
                              ])
                                ? _c(
                                    "el-menu-item",
                                    {
                                      attrs: { index: "12-2-3" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeBreadcrumbMethod(
                                            "chart-statistical-finace"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Công lương\n          ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.checkPermission(["statistical_internal"])
                    ? _c(
                        "el-submenu",
                        { attrs: { index: "12-3" } },
                        [
                          _c("template", { slot: "title" }, [_vm._v("Nội bộ")]),
                          _c(
                            "router-link",
                            {
                              staticClass: "router-link",
                              attrs: {
                                to: "/chart/statistical/cashinternal-paymoney",
                              },
                            },
                            [
                              _vm.checkPermission([
                                "statistical_internal_inout",
                              ])
                                ? _c(
                                    "el-menu-item",
                                    {
                                      attrs: { index: "12-3-1" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeBreadcrumbMethod(
                                            "chart-statistical-cashinternal-paymoney"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Thu chi\n          ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "router-link",
                              attrs: {
                                to: "/chart/statistical/cashinternal-cashbook",
                              },
                            },
                            [
                              _vm.checkPermission([
                                "statistical_internal_cashbook",
                              ])
                                ? _c(
                                    "el-menu-item",
                                    {
                                      attrs: { index: "12-3-2" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeBreadcrumbMethod(
                                            "chart-statistical-cashinternal-cashbook"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Quỹ nhà trường\n          ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "router-link",
                              attrs: { to: "/chart/statistical/wallet-parent" },
                            },
                            [
                              _vm.checkPermission([
                                "statistical_internal_wallet",
                              ])
                                ? _c(
                                    "el-menu-item",
                                    {
                                      attrs: { index: "12-3-3" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeBreadcrumbMethod(
                                            "chart-statistical-wallet-parent"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Ví phụ huynh\n          ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.checkPermission(["statisticalFinance"])
                    ? _c(
                        "el-submenu",
                        { attrs: { index: "12-4" } },
                        [
                          _c("template", { slot: "title" }, [
                            _c("span", [_vm._v("Thống kê báo cáo")]),
                          ]),
                          _c(
                            "router-link",
                            {
                              staticClass: "router-link",
                              attrs: { to: "/finace/statistical/money" },
                            },
                            [
                              _vm.checkPermission([
                                "statisticalFinance_statistical",
                              ])
                                ? _c(
                                    "el-menu-item",
                                    {
                                      attrs: { index: "12-4-1" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeBreadcrumbMethod(
                                            "finace-statistical-money"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Thống kê\n          ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "router-link",
                              attrs: { to: "/finace/statistical/finance" },
                            },
                            [
                              _vm.checkPermission(["statisticalFinance_money"])
                                ? _c(
                                    "el-menu-item",
                                    {
                                      attrs: { index: "12-4-2" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeBreadcrumbMethod(
                                            "finace-statistical-finance"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Số liệu báo cáo\n          ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "router-link",
                              attrs: { to: "/finace/statistical/kids/group" },
                            },
                            [
                              _vm.checkPermission([
                                "statisticalFinance_groupFees",
                              ])
                                ? _c(
                                    "el-menu-item",
                                    {
                                      attrs: { index: "12-4-3" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeBreadcrumbMethod(
                                            "finace-statistical-kids-group"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Nhóm học phí\n          ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "router-link",
                              attrs: {
                                to: "/finace/statistical/employee/group",
                              },
                            },
                            [
                              _vm.checkPermission([
                                "statisticalFinance_groupSalary",
                              ])
                                ? _c(
                                    "el-menu-item",
                                    {
                                      attrs: { index: "12-4-4" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeBreadcrumbMethod(
                                            "finace-statistical-employee-group"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Nhóm công lương\n          ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.checkPermission(["birthday"])
            ? _c(
                "el-submenu",
                { attrs: { index: "13" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("i", { staticClass: "el-icon-burger" }),
                    _c("span", [_vm._v("Quản lý sinh nhật")]),
                  ]),
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: { to: "/birthday/kids" },
                    },
                    [
                      _vm.checkPermission(["birthday_kids"])
                        ? _c(
                            "el-menu-item",
                            {
                              attrs: { index: "13-1" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "birthday-kids"
                                  )
                                },
                              },
                            },
                            [_vm._v("Sinh nhật học sinh\n        ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: { to: "/birthday/parent" },
                    },
                    [
                      _vm.checkPermission(["birthday_parent"])
                        ? _c(
                            "el-menu-item",
                            {
                              attrs: { index: "13-2" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "birthday-parent"
                                  )
                                },
                              },
                            },
                            [_vm._v("Sinh nhật phụ huynh\n        ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: { to: "/birthday/employee" },
                    },
                    [
                      _vm.checkPermission(["birthday_teacher"])
                        ? _c(
                            "el-menu-item",
                            {
                              attrs: { index: "13-3" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "birthday-employee"
                                  )
                                },
                              },
                            },
                            [_vm._v("Sinh nhật nhân viên\n        ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          _vm.checkPermission(["notifyHistory"])
            ? _c(
                "el-submenu",
                { attrs: { index: "14" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("i", { staticClass: "el-icon-chat-dot-round" }),
                    _c("span", [_vm._v("Lịch sử thông báo")]),
                  ]),
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: { to: "/notifihis/schedulesms" },
                    },
                    [
                      _vm.checkPermission(["notifyHistory_schedule"])
                        ? _c(
                            "el-menu-item",
                            {
                              attrs: { index: "14-1" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "notifihis-schedulesms"
                                  )
                                },
                              },
                            },
                            [_vm._v("Tin nhắn đặt lịch\n        ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: { to: "/notifihis/custom" },
                    },
                    [
                      _vm.checkPermission(["notifyHistory_custom"])
                        ? _c(
                            "el-menu-item",
                            {
                              attrs: { index: "14-2" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "notifihis-custom"
                                  )
                                },
                              },
                            },
                            [_vm._v("Tin nhắn tùy chỉnh\n        ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: { to: "/notifihis/historysms" },
                    },
                    [
                      _vm.checkPermission(["notifyHistory_historySms"])
                        ? _c(
                            "el-menu-item",
                            {
                              attrs: { index: "14-3" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "notifihis-historysms"
                                  )
                                },
                              },
                            },
                            [_vm._v("Lịch sử nhắn tin SMS\n        ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: { to: "/notifihis/historyapp" },
                    },
                    [
                      _vm.checkPermission(["notifyHistory_historyApp"])
                        ? _c(
                            "el-menu-item",
                            {
                              attrs: { index: "14-4" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "notifihis-historyapp"
                                  )
                                },
                              },
                            },
                            [_vm._v("Lịch sử thông báo App\n        ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          _vm.checkPermission(["outSchool"])
            ? _c(
                "el-submenu",
                { attrs: { index: "15" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("i", { staticClass: "el-icon-s-promotion" }),
                    _c("span", [_vm._v("Quản lý ra trường")]),
                  ]),
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: { to: "/group-out/student" },
                    },
                    [
                      _vm.checkPermission(["outSchool_student"])
                        ? _c(
                            "el-menu-item",
                            {
                              attrs: { index: "15-1" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "group-out-student"
                                  )
                                },
                              },
                            },
                            [_vm._v("Học sinh\n        ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: { to: "/group-out/employee" },
                    },
                    [
                      _vm.checkPermission(["outSchool_employee"])
                        ? _c(
                            "el-menu-item",
                            {
                              attrs: { index: "15-2" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "group-out-employee"
                                  )
                                },
                              },
                            },
                            [_vm._v("Nhân sự\n        ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: { to: "/group-out/category" },
                    },
                    [
                      _vm.checkPermission(["outSchool_folder"])
                        ? _c(
                            "el-menu-item",
                            {
                              attrs: { index: "15-3" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "group-out-category"
                                  )
                                },
                              },
                            },
                            [_vm._v("Thư mục\n        ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          _vm.checkPermission(["schoolConfig"])
            ? _c(
                "el-submenu",
                { attrs: { index: "16" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("i", { staticClass: "el-icon-setting" }),
                    _c("span", [_vm._v("Cấu hình nhà trường")]),
                  ]),
                  _vm.checkPermission(["schoolConfig_school"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/school/infor" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "16-1" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "school-infor"
                                  )
                                },
                              },
                            },
                            [_vm._v("Thông tin trường\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkPermission(["schoolConfig_absentTeacher"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/school/config-class" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "16-2" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "school-config-class"
                                  )
                                },
                              },
                            },
                            [_vm._v("Cấu hình ngày nghỉ\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkPermission(["schoolConfig_attendanceEmployee"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/school/config-employee" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "16-3" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "school-config-employee"
                                  )
                                },
                              },
                            },
                            [_vm._v("Cấu hình nhân sự\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkPermission(["schoolConfig_finance"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/school/config-common" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "16-4" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "school-config-common"
                                  )
                                },
                              },
                            },
                            [_vm._v("Cấu hình tài chính\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkPermission(["schoolConfig_print"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/school/config-print" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "16-5" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "school-config-print"
                                  )
                                },
                              },
                            },
                            [_vm._v("Cấu hình in\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkPermission(["schoolConfig_subject"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/school/subject" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "16-6" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "school-subject"
                                  )
                                },
                              },
                            },
                            [_vm._v("Quản lý môn học\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkPermission(["schoolConfig_attendanceSample"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/school/attendance-sample" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "16-7" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "school-attendance-sample"
                                  )
                                },
                              },
                            },
                            [_vm._v("Mẫu điểm danh\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkPermission(["schoolConfig_evaluateSample"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/school/evaluate-sample" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "16-8" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "school-evaluate-sample"
                                  )
                                },
                              },
                            },
                            [_vm._v("Mẫu nhận xét\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkPermission(["schoolConfig_wishSample"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/school/wish-sample" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "16-9" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "school-wish-sample"
                                  )
                                },
                              },
                            },
                            [_vm._v("Mẫu lời chúc\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkPermission(["schoolConfig_accountType"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/school/account-type" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "16-10" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "school-account-type"
                                  )
                                },
                              },
                            },
                            [_vm._v("Quản lý đối tượng\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkPermission(["schoolConfig_cameraSchool"])
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/school/camera-school" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "16-11" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "school-camera-school"
                                  )
                                },
                              },
                            },
                            [_vm._v("Quản lý camera\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: { to: "/school/icon-app" },
                    },
                    [
                      _c(
                        "el-menu-item",
                        {
                          attrs: { index: "16-12" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "icon-app-school"
                              )
                            },
                          },
                        },
                        [_vm._v("Quản lý Icon App\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          _c(
            "el-submenu",
            { attrs: { index: "17" } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "el-icon-question" }),
                _c("span", [_vm._v("Hỗ trợ & hướng dẫn")]),
              ]),
              _vm._l(_vm.supportList, function (item) {
                return _c(
                  "a",
                  {
                    key: item.name,
                    staticClass: "router-link",
                    staticStyle: { color: "#bfcbd9", display: "block" },
                    attrs: { href: item.link, target: "_blank" },
                  },
                  [
                    _c("el-menu-item", { attrs: { index: item.name } }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }