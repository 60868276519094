import http from "../http-common";

class GradeService {
    getGradeInPrinciple() {
        return http.get("/common-data/grade-in-school");
    }

    getAll(pageNumber, maxPageItem) {
        return http.get(`/grade?pageNumber=${pageNumber}&maxPageItem=${maxPageItem}`);
    }

    get(id) {
        return http.get(`/tutorials/${id}`);
    }

    create(data) {
        return http.post("/grade", data);
    }

    update(id, data) {
        return http.put(`/grade/${id}`, data);
    }

    delete(id) {
        return http.delete(`/grade/${id}`);
    }

    deleteAll() {
        return http.delete(`/tutorials`);
    }

    findByTitle(title) {
        return http.get(`/tutorials?title=${title}`);
    }

    //export excel
    exportExcelGradeNew(status, idGrade) {
        return http.get(`student/list-by-grade-class-new?status=${status}&idGrade=${idGrade}`);
    }

//Lấy biểu đồ hóa đơn học phí
    getAllChartOrder(createdYear, category, kidStatus) {
        return http.get(`/fn/chart/statistic/tuitionChart?createdYear=${createdYear}&category=${category}&kidStatus=${kidStatus}`)
    }

//Biểu đồ doanh thu học phí
    getAllChartTuitionRevenueByClass(body) {
        return http.post(`/fn/fees/statistic/getDataForRevenueChart`, body)
    }

    getAllChartTuitionRevenueByGroup(body) {
        return http.post(`/fn/fees/statistic/getDataForRevenueChartForGroup`, body)
    }

    getAllChartTuitionRevenueAll(body) {
        return http.post(`/fn/fees/statistic/getDataForRevenueChartAllForClassAndGroup`, body)
    }
}

export default new GradeService();