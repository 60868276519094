var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-menu",
    {
      staticClass: "el-menu-vertical-demo",
      attrs: {
        "background-color": "#304156",
        "text-color": "#bfcbd9",
        "active-text-color": "#409EFF",
        "unique-opened": true,
      },
    },
    [
      _c(
        "router-link",
        { staticClass: "router-link", attrs: { to: "/dashboard" } },
        [
          _c("el-menu-item", { attrs: { index: "1" } }, [
            _c("i", { staticClass: "el-icon-s-home" }),
            _c(
              "span",
              {
                staticClass: "onekids-title",
                attrs: { slot: "title" },
                slot: "title",
              },
              [_vm._v("ONEKIDS TEACHER")]
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-submenu",
        { attrs: { index: "2" } },
        [
          _c("template", { slot: "title" }, [
            _c("i", { staticClass: "el-icon-reading" }),
            _c("span", [_vm._v("Nhật ký phụ huynh")]),
          ]),
          _c(
            "router-link",
            {
              staticClass: "router-link",
              attrs: { to: "/parentdiary/message-parent" },
            },
            [
              _vm.checkPermission(["parentDiary_message"])
                ? _c(
                    "el-menu-item",
                    {
                      attrs: { index: "2-1" },
                      on: {
                        click: function ($event) {
                          return _vm.changeBreadcrumbMethod(
                            "parentdiary-message-parent"
                          )
                        },
                      },
                    },
                    [_vm._v("Lời nhắn\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "router-link",
            {
              staticClass: "router-link",
              attrs: { to: "/parentdiary/medicine" },
            },
            [
              _vm.checkPermission(["parentDiary_medicine"])
                ? _c(
                    "el-menu-item",
                    {
                      attrs: { index: "2-2" },
                      on: {
                        click: function ($event) {
                          return _vm.changeBreadcrumbMethod(
                            "parentdiary-medicine"
                          )
                        },
                      },
                    },
                    [_vm._v("Dặn thuốc\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "router-link",
            {
              staticClass: "router-link",
              attrs: { to: "/parentdiary/take-leave" },
            },
            [
              _vm.checkPermission(["parentDiary_absenLetter"])
                ? _c(
                    "el-menu-item",
                    {
                      attrs: { index: "2-3" },
                      on: {
                        click: function ($event) {
                          return _vm.changeBreadcrumbMethod(
                            "parentdiary-take-leave"
                          )
                        },
                      },
                    },
                    [_vm._v("Xin nghỉ\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _vm.checkPermission(["kids"])
        ? _c(
            "el-submenu",
            { attrs: { index: "3" } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "el-icon-user-solid" }),
                _c("span", [_vm._v("Quản lý học sinh")]),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/student/students" },
                },
                [
                  _vm.checkPermission(["kids_list"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "3-1" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "student-students"
                              )
                            },
                          },
                        },
                        [_vm._v("Danh sách học sinh\n      ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.checkPermission(["studentQuality"])
        ? _c(
            "el-submenu",
            { attrs: { index: "4" } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "el-icon-document-checked" }),
                _c("span", [_vm._v("Chất lượng học sinh")]),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/student-quality/attendance" },
                },
                [
                  _vm.checkPermission(["studentQuality_attendance"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "4-1" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "student-quality-attendance"
                              )
                            },
                          },
                        },
                        [_vm._v("Điểm danh\n      ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/student-quality/evaluate" },
                },
                [
                  _vm.checkPermission(["studentQuality_evaluate"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "4-2" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "student-quality-evaluate"
                              )
                            },
                          },
                        },
                        [_vm._v("Nhận xét\n      ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/student-quality/album" },
                },
                [
                  _vm.checkPermission(["studentQuality_ablum"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "4-3" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "student-quality-album"
                              )
                            },
                          },
                        },
                        [_vm._v("Album ảnh\n      ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/student-quality/height-weight" },
                },
                [
                  _vm.checkPermission(["studentQuality_heightWeight"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "4-4" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "student-quality-height-weight"
                              )
                            },
                          },
                        },
                        [_vm._v("Chiều cao, cân nặng\n      ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/student-quality/schedule" },
                },
                [
                  _vm.checkPermission(["studentQuality_schedule"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "4-5" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "student-quality-schedule"
                              )
                            },
                          },
                        },
                        [_vm._v("Thời khóa biểu\n      ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/student-quality/class-menu" },
                },
                [
                  _vm.checkPermission(["studentQuality_menu"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "4-6" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "student-quality-class-menu"
                              )
                            },
                          },
                        },
                        [_vm._v("Thực đơn\n      ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _c(
        "el-submenu",
        { attrs: { index: "5" } },
        [
          _c("template", { slot: "title" }, [
            _c("i", { staticClass: "el-icon-s-data" }),
            _c("span", [_vm._v("Thống kê")]),
          ]),
          _c(
            "router-link",
            {
              staticClass: "router-link",
              attrs: { to: "/chart/statistical/attendance" },
            },
            [
              _c(
                "el-menu-item",
                {
                  attrs: { index: "5-1" },
                  on: {
                    click: function ($event) {
                      return _vm.changeBreadcrumbMethod(
                        "chart-statistical-attendance"
                      )
                    },
                  },
                },
                [_vm._v("Điểm danh\n      ")]
              ),
            ],
            1
          ),
          _c(
            "router-link",
            {
              staticClass: "router-link",
              attrs: { to: "/chart/statistical/evaluate" },
            },
            [
              _c(
                "el-menu-item",
                {
                  attrs: { index: "5-2" },
                  on: {
                    click: function ($event) {
                      return _vm.changeBreadcrumbMethod(
                        "chart-statistical-evaluate"
                      )
                    },
                  },
                },
                [_vm._v("Nhận xét\n      ")]
              ),
            ],
            1
          ),
          _c(
            "router-link",
            {
              staticClass: "router-link",
              attrs: { to: "/chart/statistical/album" },
            },
            [
              _c(
                "el-menu-item",
                {
                  attrs: { index: "5-3" },
                  on: {
                    click: function ($event) {
                      return _vm.changeBreadcrumbMethod(
                        "chart-statistical-album"
                      )
                    },
                  },
                },
                [_vm._v("Album ảnh\n      ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm.checkPermission(["statisticalCommon"])
        ? _c(
            "el-submenu",
            { attrs: { index: "6" } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "el-icon-s-operation" }),
                _c("span", [_vm._v("Thống kê chung")]),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/teacher/statistical" },
                },
                [
                  _vm.checkPermission(["statisticalCommon_attendance"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "6-1" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "teacher-statistical"
                              )
                            },
                          },
                        },
                        [_vm._v("\n        Thống kê điểm danh\n      ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.checkPermission(["birthday"])
        ? _c(
            "el-submenu",
            { attrs: { index: "7" } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "el-icon-burger" }),
                _c("span", [_vm._v("Quản lý sinh nhật")]),
              ]),
              _c(
                "router-link",
                { staticClass: "router-link", attrs: { to: "/birthday/kids" } },
                [
                  _vm.checkPermission(["birthday_kids"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "7-1" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod("birthday-kids")
                            },
                          },
                        },
                        [_vm._v("Sinh nhật học sinh\n      ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/birthday/parent" },
                },
                [
                  _vm.checkPermission(["birthday_parent"])
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: "7-2" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "birthday-parent"
                              )
                            },
                          },
                        },
                        [_vm._v("Sinh nhật phụ huynh\n      ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _c(
        "el-submenu",
        { attrs: { index: "8" } },
        [
          _c("template", { slot: "title" }, [
            _c("i", { staticClass: "el-icon-question" }),
            _c("span", [_vm._v("Hỗ trợ & hướng dẫn")]),
          ]),
          _vm._l(_vm.supportList, function (item) {
            return _c(
              "a",
              {
                key: item.name,
                staticClass: "router-link",
                staticStyle: { color: "#bfcbd9", display: "block" },
                attrs: { href: item.link, target: "_blank" },
              },
              [
                _c("el-menu-item", { attrs: { index: item.name } }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }