var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("input", {
        ref: "excel-upload-input",
        staticStyle: { display: "none" },
        attrs: { type: "file", accept: ".xlsx, .xls" },
        on: { change: _vm.handleClick },
      }),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.handleUpload } },
        [
          _vm._v("\n    Upload excel "),
          _c("i", { staticClass: "el-icon-upload el-icon-right" }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }