import EmployeeDataService from "@/services/EmployeeService/EmployeeDataService";

export const employeeStore = {
    namespaced: true,
    state: {
        employeeList: [],
        pagination: {
            pageNumber: 1,
            maxPageItem: 50,
            loginStatus: '',
            statusEmployeee: 'Đang làm',
            idDepartment: '',
            employeeNameOrPhone: '',
        },
    },
    getters: {
        employeeList: (state) => state.employeeList,
    },
    mutations: {
        SET_EmployeeList(state, employeeList) {
            state.employeeList = employeeList;
        }
    },
    // thực hiện hành động gọi api
    actions: {
            fetchDataEmployeeList({commit, state}) {
            return EmployeeDataService.searchEmployeeInSchool(
                state.pagination.pageNumber,
                state.pagination.maxPageItem,
                state.pagination.loginStatus,
                state.pagination.statusEmployeee,
                state.pagination.idDepartment,
                state.pagination.employeeNameOrPhone)
                .then((response) => {
                    commit('SET_EmployeeList', response.data.data.dataList)
                    // console.log('SET_EmployeeList', response.data.data.dataList)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

};
