import {
    BILL_TABLE_FOR_ONE_KID, CHECK_RESPONSE,
    GET_STATUS_EXCEL,
    INIT_EMPLOYEE_DATA,
    LOADING_DATA,
    NO_DATA_RESPONSE,
    PREVIEW_BILL_DATA,
    PREVIEW_INFO_FOR_ONE_KID,
    RENEW_BILL_PREVIEW, RESPONSE_FILTER_LIST,
    SET_HIDDEN_APP_MESSAGE,
    SET_HIDDEN_FOR_APP,
    SET_HIDDEN_ORDER_KIDS,
    SET_ID_EMPLOYEE_LIST,
    SET_LIST_KT,
    SET_NULL_BILL_KIDS,
    SET_SHOW_APP_MESSAGE,
    SET_SHOW_FOR_APP,
    UPDATE_DATA_SEARCH,
} from "@/constant/actions";
import moment from "moment";
import NewSalaryService from "@/services/EmployeeService/NewSalaryService";
// employee_data_name là 1 object gồm tên các khoản thu
const countNameBillValidate = (employee_data_name) => {
    let totalAvailable = 0;
    if (employee_data_name.nameK01 !== "Khoản 1") {
        totalAvailable++;
    }
    if (employee_data_name.nameK02 !== "Khoản 2") {
        totalAvailable++;
    }
    if (employee_data_name.nameK03 !== "Khoản 3") {
        totalAvailable++;
    }
    if (employee_data_name.nameK04 !== "Khoản 4") {
        totalAvailable++;
    }
    if (employee_data_name.nameK05 !== "Khoản 5") {
        totalAvailable++;
    }
    if (employee_data_name.nameK06 !== "Khoản 6") {
        totalAvailable++;
    }
    if (employee_data_name.nameK07 !== "Khoản 7") {
        totalAvailable++;
    }
    if (employee_data_name.nameK08 !== "Khoản 8") {
        totalAvailable++;
    }
    if (employee_data_name.nameK09 !== "Khoản 9") {
        totalAvailable++;
    }
    if (employee_data_name.nameK10 !== "Khoản 10") {
        totalAvailable++;
    }
    if (employee_data_name.nameK11 !== "Khoản 11") {
        totalAvailable++;
    }
    if (employee_data_name.nameK12 !== "Khoản 12") {
        totalAvailable++;
    }
    if (employee_data_name.nameK13 !== "Khoản 13") {
        totalAvailable++;
    }
    if (employee_data_name.nameK14 !== "Khoản 14") {
        totalAvailable++;
    }
    if (employee_data_name.nameK15 !== "Khoản 15") {
        totalAvailable++;
    }
    if (employee_data_name.nameK16 !== "Khoản 16") {
        totalAvailable++;
    }
    if (employee_data_name.nameK17 !== "Khoản 17") {
        totalAvailable++;
    }
    if (employee_data_name.nameK18 !== "Khoản 18") {
        totalAvailable++;
    }
    if (employee_data_name.nameK19 !== "Khoản 19") {
        totalAvailable++;
    }
    if (employee_data_name.nameK20 !== "Khoản 20") {
        totalAvailable++;
    }
    return totalAvailable;
};
export const employeeSalaryNew = {
    namespaced: true,
    state: {

        listHiddenForApp:[],
        listShowForApp:[],
        messageShowForApp:'',
        messageHiddenForApp:'',
        listNullBillKids: "",
        listHiddenOrderKids: "",
        responseFilterList: [],
        paySlipNameKT: {
            nameK01: "",
            nameK02: "",
            nameK03: "",
            nameK04: "",
            nameK05: "",
            nameK06: "",
            nameK07: "",
            nameK08: "",
            nameK09: "",
            nameK10: "",
            nameK11: "",
            nameK12: "",
            nameK13: "",
            nameK14: "",
            nameK15: "",
            nameK16: "",
            nameK17: "",
            nameK18: "",
            nameK19: "",
            nameK20: "",
        },
        //loading
        loadingOneKid: true,
        loadingBillPreview: true,
        loadingData: true,
        totalPages: "",
        dataSearch: {},
        idInfoList: "",
        dataBillPreview: [],
        exportParamForEmployee: {
            date: '',
            spendTurn: 0,
            idInfoList: '',
            listNameKC: '',
        },
        billTableForOneKidPreview: [],
        totalBillForOneKid: 0,
        isContainAllBillOneKid: false, // các khoản thu chọn đều có
        updateStatus: false,
        checkRevenue: false,
        checkResponse: false,
    },
    mutations: {
        [RESPONSE_FILTER_LIST] (state,payload){
            state.responseFilterList = payload;
        },
        [INIT_EMPLOYEE_DATA](state, payload) {
            state.loadingData = true;
            if (payload) {
                state.responseFilterList = payload.listFilterPaySlip;
                state.totalPages = payload.totalPage;
                state.loadingData = false;
            }
        },
        [UPDATE_DATA_SEARCH](state, payload) {
            state.dataSearch = payload; // dùng lấy dữ liệu data tìm kiếm
        },
        [SET_ID_EMPLOYEE_LIST](state, payload) {
            state.idInfoList = payload.join(`,`); // dùng để set truyền param cho api
        },
        [SET_LIST_KT](state, payload) {
            state.paySlipNameKT = payload;
        },
        [PREVIEW_BILL_DATA](state, payload) {
            const {data, printStyle} = payload
            if (printStyle === 'pdfPreview') {
                window.open('/pdf-salary-preview', '_blank')
            } else {
                window.open('/split-pdf-salary-preview', '_blank')
            }
            state.dataBillPreview = data;
            state.dataBillPreview.forEach((item,index) => {
                item.stt=index++
                item.dateNow = moment(item.dateNow).format(
                    "[Ngày] DD [tháng] MM [năm] YYYY"
                );
            });
            state.loadingBillPreview = false;
            // chuyển trang sẽ xóa state của danh sách id học sinh về ban đầu

        },
        [RENEW_BILL_PREVIEW](state) {
            state.loadingBillPreview = true;
            state.dataBillPreview = [];
            state.idInfoList = "";
            state.billTableForOneKidPreview = [];
            state.totalBillForOneKid = 0;
            state.isContainAllBillOneKid = false;
            state.loadingOneKid = true
        },
        [NO_DATA_RESPONSE](state) {
            (state.paySlipNameKT.nameKt1 = "Khoản 1"),
            (state.paySlipNameKT.nameKt2 = "Khoản 2"),
            (state.paySlipNameKT.nameKt3 = "Khoản 3"),
            (state.paySlipNameKT.nameKt4 = "Khoản 4"),
            (state.paySlipNameKT.nameKt5 = "Khoản 5"),
            (state.paySlipNameKT.nameKt6 = "Khoản 6"),
            (state.paySlipNameKT.nameKt7 = "Khoản 7"),
            (state.paySlipNameKT.nameKt8 = "Khoản 8"),
            (state.paySlipNameKT.nameKt9 = "Khoản 9"),
            (state.paySlipNameKT.nameKt10 = "Khoản 10"),
            (state.paySlipNameKT.nameKt11 = "Khoản 11"),
            (state.paySlipNameKT.nameKt12 = "Khoản 12"),
            (state.paySlipNameKT.nameKt13 = "Khoản 13"),
            (state.paySlipNameKT.nameKt14 = "Khoản 14"),
            (state.paySlipNameKT.nameKt15 = "Khoản 15"),
            (state.paySlipNameKT.nameKt16 = "Khoản 16"),
            (state.paySlipNameKT.nameKt17 = "Khoản 17"),
            (state.paySlipNameKT.nameKt18 = "Khoản 18"),
            (state.paySlipNameKT.nameKt19 = "Khoản 19"),
            (state.paySlipNameKT.nameKt20 = "Khoản 20");
        },
        [SET_SHOW_FOR_APP](state,payload){
            state.listShowForApp=payload
            state.messageShowForApp=''
            state.listShowForApp.forEach((item, index) =>{
                state.messageShowForApp = state.messageShowForApp.concat("<br>", `${index+1} - ${item}`);
            })
        },
        [SET_SHOW_APP_MESSAGE](state,payload){
            state.listShowForApp=[...state.listShowForApp,payload]
            state.listHiddenForApp=state.listHiddenForApp.filter(name=>name!==payload)
            state.messageShowForApp=''
            state.messageHiddenForApp=''
            state.listShowForApp.forEach((item, index) =>{
                state.messageShowForApp = state.messageShowForApp.concat("<br>", `${index+1} - ${item}`);
            })
            state.listHiddenForApp.forEach((item, index) =>{
                state.messageHiddenForApp = state.messageHiddenForApp.concat("<br>", `${index+1} - ${item}`);
            })

        },
        [SET_HIDDEN_FOR_APP](state,payload){
            state.listHiddenForApp=payload
            state.messageHiddenForApp=''
            state.listHiddenForApp.forEach((item, index) =>{
                state.messageHiddenForApp = state.messageHiddenForApp.concat("<br>", `${index+1} - ${item}`);
            })
        },
        [SET_HIDDEN_APP_MESSAGE](state,payload){
            state.listHiddenForApp=[...state.listHiddenForApp,payload]
            state.listShowForApp=state.listShowForApp.filter(name=>name!==payload)
            state.messageHiddenForApp=''
            state.messageShowForApp=''
            state.listShowForApp.forEach((item, index) =>{
                state.messageShowForApp = state.messageShowForApp.concat("<br>", `${index+1} - ${item}`);
            })
            state.listHiddenForApp.forEach((item, index) =>{
                state.messageHiddenForApp = state.messageHiddenForApp.concat("<br>", `${index+1} - ${item}`);
            })
        },
        [SET_NULL_BILL_KIDS](state, payload) {
            state.listNullBillKids = ''
            payload.forEach((item, index) =>{
                state.listNullBillKids = state.listNullBillKids.concat("<br>", `${index+1} - ${item}`);
            })
        },
        [SET_HIDDEN_ORDER_KIDS](state, payload) {
            state.listHiddenOrderKids=''
            payload.forEach((item,index)=>{
                state.listHiddenOrderKids = state.listHiddenOrderKids.concat('<br>',`${index+1} - ${item}`)
            })
        },
        [BILL_TABLE_FOR_ONE_KID](state, list_kt_one_kid) {
            state.totalBillForOneKid = 0;
            state.billTableForOneKidPreview = list_kt_one_kid;
            let listBill = []
            list_kt_one_kid.forEach((item) => {
                state.totalBillForOneKid += item.money;
                listBill = [...listBill, item.name]
            });
            state.exportParamForEmployee.listNameKC = listBill.join(',')

        },
        // xử lí y hệt Preview In hóa đơn, chia luồng ra để so sánh số lượng khoản thu cho 1 nhân viên
        [PREVIEW_INFO_FOR_ONE_KID](state, data) {
            window.open('/one-employee-pdf-preview', '_blank')

            // gán param excel
            state.exportParamForEmployee.idInfoList = data[0].idInfo
            state.exportParamForEmployee.spendTurn = state.dataSearch.spendTurn // đợt thu là đồng bộ
            state.exportParamForEmployee.date = moment(state.dataSearch.year + '-' + state.dataSearch.month + '-01', "YYYY-MM-DD").format("YYYY-MM-DD");
            state.dataBillPreview = data;
            state.dataBillPreview.forEach((item) => {
                item.dateNow = moment(item.dateNow).format(
                    "[Ngày] DD [tháng] MM [năm] YYYY"
                );
            });
            // nếu số lượng bằng nhau, cho hiện dữ liệu thiếu thừa
            if (
                countNameBillValidate(data[0]) ===
                state.billTableForOneKidPreview.length
            ) {
                state.isContainAllBillOneKid = true;
            } else {
                state.isContainAllBillOneKid = false;
            }

            state.loadingOneKid = false;
            // chuyển trang sẽ xóa state của danh sách id học sinh về ban đầu
            state.idInfoList = "";
        },
        [GET_STATUS_EXCEL](state) {
            state.updateStatus = !state.updateStatus
        },
        [LOADING_DATA](state){
            state.loadingData=true
        },
        [CHECK_RESPONSE](state,payload) {
            state.checkResponse = payload
            state.loadingData = false;
            if (state.checkResponse === false){
                state.responseFilterList = []
                state.totalPages = "";
            }
        },
        setCheckRevenue(state){
            state.checkRevenue = false
            for (let i = 1; i < 10 ; i++) {
                if (state.paySlipNameKT[`nameK0${i}`] !== `Khoản ${i}` && state.dataSearch.spendTurn !== null){
                    state.checkRevenue = true
                    break
                }
            }
            for (let i = 10; i <=20 ; i++) {
                if (state.paySlipNameKT[`nameK${i}`] !== `Khoản ${i}` && state.dataSearch.spendTurn !== null){
                    state.checkRevenue = true
                    break
                }
            }
        }
    },
    actions: {
        [INIT_EMPLOYEE_DATA]({commit}, filterParams) {
            commit(RESPONSE_FILTER_LIST, []);
            return NewSalaryService
                .filterEmployeeSalary(filterParams)
                .then((data) => {
                    commit(UPDATE_DATA_SEARCH, filterParams);
                    if (data) {
                        commit(INIT_EMPLOYEE_DATA, data.data);
                        commit(SET_LIST_KT, data.data.paySlipNameKT);
                    }
                    commit('setCheckRevenue')
                })
                .catch(() => {
                    commit(NO_DATA_RESPONSE);
                    commit(CHECK_RESPONSE,true)
                });
        },
        [PREVIEW_BILL_DATA]({commit}, payload) {
            const {param, printStyle} = payload
            return NewSalaryService
                .showListPrintOrderEmployeeExcel(param)
                .then((res) => {
                    if (res) {
                        commit(PREVIEW_BILL_DATA, {data: res.data, printStyle});
                    }
                })
        },

        [PREVIEW_INFO_FOR_ONE_KID]({commit}, preview_data) {
            return NewSalaryService
                .showListPrintOrderEmployeeExcel(preview_data)
                .then((res) => {
                    if (res) {
                        commit(PREVIEW_INFO_FOR_ONE_KID, res.data);
                    }
                })
        },
    },
};
