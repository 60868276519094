var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ElInput", {
    directives: [
      {
        name: "currency",
        rawName: "v-currency",
        value: _vm.options,
        expression: "options",
      },
    ],
    ref: "input",
    attrs: { disabled: _vm.disabled, value: _vm.formattedValue },
    on: { input: _vm.handleInput },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }