<template>
  <div class="app-wrapper">
    <!--    <Menu style="float: left" />-->
    <MenuParent v-if="getAppTypeUserLogin == appType.PARENT" style="float: left"/>
    <MenuSchool v-if="getAppTypeUserLogin == appType.SCHOOL" style="float: left"/>
    <MenuTeacher v-if="getAppTypeUserLogin == appType.TEACHER" style="float: left"/>
    <MenuSuperSchool v-if="getAppTypeUserLogin == appType.SUPPER_SCHOOL" style="float: left"/>
    <MenuAdminManagement v-if="getAppTypeUserLogin == appType.SUPPER_SYSTEM || getAppTypeUserLogin == appType.SYSTEM" style="float: left"/>
    <navbar/>
    <app-main />
  </div>
</template>

<script>
import AppMain from "./components/AppMain.vue";
import Navbar from "./components/Navbar.vue";
import MenuParent from "@/layout/components/MenuParent.vue";
import MenuSchool from "@/layout/components/MenuSchool.vue";
import MenuTeacher from "@/layout/components/MenuTeacher.vue";
import MenuSuperSchool from "@/layout/components/MenuSuperSchool.vue";
import MenuAdminManagement from "@/layout/components/MenuAdminManagement";
import {appType} from "@/constant/listCommon";

export default {
  name: "Layout",
  components: {
    MenuSuperSchool,
    MenuTeacher,
    MenuSchool,
    MenuParent,
    AppMain,
    Navbar,
    MenuAdminManagement,
    // Menu,
  },
  data() {
    return {
      appType,
    };
  },
  computed: {
    getAppTypeUserLogin() {
      return this.$store.state.auth.user.appType;
    },
  },
};
</script>

<style lang="scss" scoped>
MenuParent, MenuSchool,MenuTeacher,MenuSuperSchool,MenuAdminManagement {
  position: fixed;
  top: 0;
  width: 100%;
}
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
