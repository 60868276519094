/*
FINANCE-V2 ACTIONS
*/
export const INIT_KIDS_DATA='init_kids_data'
export const UPDATE_DATA_SEARCH='update_data_search'
export const SET_ID_KID_LIST ='set_id_kid_list'
export const SET_LIST_KT ='set_list_kt'
export const PREVIEW_BILL_DATA='preview_bill_data'
export const NO_DATA_RESPONSE='no_data_response'
export const SET_NULL_BILL_KIDS='set_null_bill_kids'
export const RENEW_BILL_PREVIEW='renew_bill_preview'
export const PREVIEW_INFO_FOR_ONE_KID='preview_info_for_one_kid' // lấy thông tin cho 1 học sinh
export const BILL_TABLE_FOR_ONE_KID='bill_table_for_one_kid'
export  const SET_HIDDEN_ORDER_KIDS='set_hidden_order_kids'
export  const GET_STATUS_EXCEL='get_status_excel'
export  const LOADING_DATA='loading_data'
export  const SET_HIDDEN_FOR_APP='set_hidden_for_app'
export  const SET_SHOW_FOR_APP='set_show_for_app'
export  const SET_SHOW_APP_MESSAGE='set_show_app_message'
export  const SET_HIDDEN_APP_MESSAGE='set_hidden_app_message'

/*
STUDENT GROUP ACTIONS
 */
export const FILTER_STUDENTS_GROUP='filter_students_group'
export const SET_TYPE_INPUT='set_type_input'
export const SET_RESET_FILTER = 'set_reset_filter'
/**
 * DASHBOARD
 */
export const RELOAD_DASHBOARD='reload_dashboard'

//EMPLOYEE SALARY
export const INIT_EMPLOYEE_DATA = 'init_employee_data'

export const SET_ID_EMPLOYEE_LIST = 'set_id_employee_list'

export  const CHECK_RESPONSE='check_response'
export  const RESPONSE_FILTER_LIST='response_filter_list'