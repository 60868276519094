<template>
  <el-menu
      class="el-menu-vertical-demo"
      background-color="#304156"
      text-color="#bfcbd9"
      active-text-color="#409EFF"
      :unique-opened="true"
  >
    <router-link to="/dashboard" class="router-link">
      <el-menu-item index="1">
        <i class="el-icon-s-home"></i>
        <span slot="title" class="onekids-title">ONEKIDS TEACHER</span>
      </el-menu-item>
    </router-link>
    <el-submenu index="2">
      <template slot="title">
        <i class="el-icon-reading"/>
        <span>Nhật ký phụ huynh</span>
      </template>
      <router-link
          to="/parentdiary/message-parent"
          class="router-link"
      >
        <el-menu-item
            index="2-1"
            v-if="checkPermission(['parentDiary_message'])"
            @click="changeBreadcrumbMethod('parentdiary-message-parent')"
        >Lời nhắn
        </el-menu-item>
      </router-link>
      <router-link
          to="/parentdiary/medicine"
          class="router-link"
      >
        <el-menu-item
            index="2-2"
            v-if="checkPermission(['parentDiary_medicine'])"
            @click="changeBreadcrumbMethod('parentdiary-medicine')"
        >Dặn thuốc
        </el-menu-item>
      </router-link>
      <router-link
          to="/parentdiary/take-leave"
          class="router-link"
      >
        <el-menu-item
            index="2-3"
            v-if="checkPermission(['parentDiary_absenLetter'])"
            @click="changeBreadcrumbMethod('parentdiary-take-leave')"
        >Xin nghỉ
        </el-menu-item>
      </router-link>
    </el-submenu>

    <el-submenu index="3"
                v-if="checkPermission(['kids'])"
    >
      <template slot="title">
        <i class="el-icon-user-solid"/>
        <span>Quản lý học sinh</span>
      </template>
      <router-link to="/student/students" class="router-link">
        <el-menu-item
            index="3-1"
            v-if="checkPermission(['kids_list'])"
            @click="changeBreadcrumbMethod('student-students')"
        >Danh sách học sinh
        </el-menu-item>
      </router-link>
    </el-submenu>
    <el-submenu index="4"
                v-if="
          checkPermission(['studentQuality'])
        "
    >
      <template slot="title">
        <i class="el-icon-document-checked"/>
        <span>Chất lượng học sinh</span>
      </template>
      <router-link to="/student-quality/attendance" class="router-link">
        <el-menu-item
            index="4-1"
            v-if="checkPermission(['studentQuality_attendance'])"
            @click="changeBreadcrumbMethod('student-quality-attendance')"
        >Điểm danh
        </el-menu-item>
      </router-link>
      <router-link to="/student-quality/evaluate" class="router-link">
        <el-menu-item
            index="4-2"
            v-if="checkPermission(['studentQuality_evaluate'])"
            @click="changeBreadcrumbMethod('student-quality-evaluate')"
        >Nhận xét
        </el-menu-item>
      </router-link>
      <router-link to="/student-quality/album" class="router-link">
        <el-menu-item
            index="4-3"
            v-if="checkPermission(['studentQuality_ablum'])"
            @click="changeBreadcrumbMethod('student-quality-album')"
        >Album ảnh
        </el-menu-item>
      </router-link>
      <router-link to="/student-quality/height-weight" class="router-link">
        <el-menu-item
            index="4-4"
            v-if="checkPermission(['studentQuality_heightWeight'])"
            @click="changeBreadcrumbMethod('student-quality-height-weight')"
        >Chiều cao, cân nặng
        </el-menu-item>
      </router-link>
      <router-link to="/student-quality/schedule" class="router-link">
        <el-menu-item
            index="4-5"
            v-if="checkPermission(['studentQuality_schedule'])"
            @click="changeBreadcrumbMethod('student-quality-schedule')"
        >Thời khóa biểu
        </el-menu-item>
      </router-link>
      <router-link to="/student-quality/class-menu" class="router-link">
        <el-menu-item
            index="4-6"
            v-if="checkPermission(['studentQuality_menu'])"
            @click="changeBreadcrumbMethod('student-quality-class-menu')"
        >Thực đơn
        </el-menu-item>
      </router-link>
    </el-submenu>
    <el-submenu index="5">
      <template slot="title">
        <i class="el-icon-s-data"/>
        <span>Thống kê</span>
      </template>
      <router-link to="/chart/statistical/attendance" class="router-link">
        <el-menu-item
            index="5-1"
            @click="changeBreadcrumbMethod('chart-statistical-attendance')"
        >Điểm danh
        </el-menu-item>
      </router-link>
      <router-link to="/chart/statistical/evaluate" class="router-link">
        <el-menu-item
            index="5-2"
            @click="changeBreadcrumbMethod('chart-statistical-evaluate')"
        >Nhận xét
        </el-menu-item>
      </router-link>
      <router-link to="/chart/statistical/album" class="router-link">
        <el-menu-item
            index="5-3"
            @click="changeBreadcrumbMethod('chart-statistical-album')"
        >Album ảnh
        </el-menu-item>
      </router-link>
    </el-submenu>
    <el-submenu index="6"
                v-if=" checkPermission(['statisticalCommon'])">
      <template slot="title">
        <i class="el-icon-s-operation"/>
        <span>Thống kê chung</span>
      </template>
      <router-link to="/teacher/statistical" class="router-link">
        <el-menu-item
            index="6-1"
            v-if="checkPermission(['statisticalCommon_attendance'])"
            @click="changeBreadcrumbMethod('teacher-statistical')"
        >
          Thống kê điểm danh
        </el-menu-item>
      </router-link>
    </el-submenu>
    <el-submenu index="7"
                v-if=" checkPermission(['birthday'])   ">
      <template slot="title">
        <i class="el-icon-burger"/>
        <span>Quản lý sinh nhật</span>
      </template>
      <router-link to="/birthday/kids" class="router-link">
        <el-menu-item
            index="7-1"
            v-if="checkPermission(['birthday_kids'])"
            @click="changeBreadcrumbMethod('birthday-kids')"
        >Sinh nhật học sinh
        </el-menu-item>
      </router-link>
      <router-link to="/birthday/parent" class="router-link">
        <el-menu-item
            index="7-2"
            v-if="checkPermission(['birthday_parent'])"
            @click="changeBreadcrumbMethod('birthday-parent')"
        >Sinh nhật phụ huynh
        </el-menu-item>
      </router-link>
    </el-submenu>
    <el-submenu index="8">
      <template slot="title">
        <i class="el-icon-question"/>
        <span>Hỗ trợ &amp; hướng dẫn</span>
      </template>
      <a
          v-for="item in supportList"
          :key="item.name"
          class="router-link"
          :href="item.link"
          target="_blank"
          style="color: #bfcbd9; display: block"
      >
        <el-menu-item :index="item.name">{{ item.name }}</el-menu-item>
      </a>
    </el-submenu>
  </el-menu>
</template>
<script>
import checkPermission from "@/utils/permission";
import {mapGetters} from "vuex";

export default {
  name: 'MenuTeacher',
  data() {
    return {
      breadcrumbDataList: [
        //bản tin
        {
          key: "appsend-notification",
          value: [{url: "/appsend/notification", name: "Thông báo hệ thống"}],
        },
        {
          key: "appsend-parent-feedback",
          value: [{url: "/appsend/parent-feedback", name: "Phụ huynh góp ý"}],
        },
        {
          key: "appsend-teacher-feedback",
          value: [{url: "/appsend/teacher-feedback", name: "Giáo viên góp ý"}],
        },
        //nhật ký phụ huynh
        {
          key: "parentdiary-message-parent",
          value: [{url: "/parentdiary/message-parent", name: "Lời nhắn"}],
        },
        {
          key: "parentdiary-medicine",
          value: [{url: "/parentdiary/medicine", name: "Dặn thuốc"}],
        },
        {
          key: "parentdiary-take-leave",
          value: [{url: "/parentdiary/take-leave", name: "Xin nghỉ"}],
        },
        //quản lý nhân sự
        {
          key: "employee-department",
          value: [{url: "/employee/department", name: "Danh sách phòng ban"}],
        },
        {
          key: "employee-employees",
          value: [{url: "/employee/employees", name: "Danh sách nhân sự"}],
        },
        //danh sách học sinh
        {
          key: "student-students",
          value: [{url: "/student/students", name: "Danh sách học sinh"}],
        },
        //chất lượng học sinh
        {
          key: "student-quality-attendance",
          value: [{url: "/student-quality/attendance", name: "Điểm danh"}],
        },
        {
          key: "student-quality-evaluate",
          value: [{url: "/student-quality/evaluate", name: "Nhận xét"}],
        },
        {
          key: "student-quality-album",
          value: [{url: "/student-quality/album", name: "Album ảnh"}],
        },
        {
          key: "student-quality-height-weight",
          value: [
            {
              url: "/student-quality/height-weight",
              name: "Chiều cao, cân nặng",
            },
          ],
        },
        {
          key: "student-quality-schedule",
          value: [{url: "/student-quality/schedule", name: "Thời khóa biểu"}],
        },
        {
          key: "student-quality-class-menu",
          value: [{url: "/student-quality/class-menu", name: "Thực đơn"}],
        },
        //quản lý sinh nhật
        {
          key: "birthday-kids",
          value: [{url: "/birthday/kids", name: "Sinh nhật học sinh"}],
        },
        {
          key: "birthday-parent",
          value: [{url: "/birthday/parent", name: "Sinh nhật phụ huynh"}],
        },
        //thống kê biểu đồ
        {
          key: "chart-statistical-attendance",
          value: [{url: "/chart/statistical/attendance", name: "Điểm danh"}],
        },
        {
          key: "chart-statistical-evaluate",
          value: [{url: "/chart/statistical/evaluate", name: "Nhận xét"}],
        },
        {
          key: "chart-statistical-album",
          value: [{url: "/chart/statistical/album", name: "Album ảnh"}],
        }
      ],
    }
  },
  created() {
    this.$store.dispatch('commonDataStore/fetchDataSupportList');
  },
  computed: {
    getIsOpenCloseManu() {
      return this.$store.getters["layout/getIsOpenClose"];
    },
    getAppTypeUserLogin() {
      return this.$store.state.auth.user.appType;
    },
    ...mapGetters('commonDataStore',['supportList']),
  },
  methods: {
    checkPermission,
    changeBreadcrumbMethod(val) {
      let selectUrl = this.breadcrumbDataList.filter((x) => x.key == val);
      if (selectUrl.length == 1) {
        let dataList = selectUrl[0].value;
        sessionStorage.setItem("breadcrumbList", JSON.stringify(dataList));
        this.$store.dispatch("layout/setBreadcrumbActions", dataList);
      }
    },

  },

}

</script>
<style lang="scss" scoped>
.menu-main {
  min-height: 950px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 210px;
  min-height: 700px;
  font-family: Arial;
}

.router-link {
  text-decoration: none;
}

.el-menu-item {
  background-color: #1f2d3d !important;
}

.el-menu-item:hover {
  background-color: #49575f !important;
}

/deep/ .el-message-box {
  display: inline-block;
  vertical-align: top !important;
  width: 420px;
  padding-bottom: 10px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  font-size: 18px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/deep/ .el-message-box__wrapper::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: top !important;
}

.el-menu {
  margin-bottom: 20px;
}

.onekids-title {
  font-weight: bold;
}
</style>



