var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VueFixedHeader",
    {
      attrs: {
        threshold: _vm.propsData.threshold,
        headerClass: _vm.propsData.headerClass,
        fixedClass: _vm.propsData.fixedClass,
        hideScrollUp: _vm.propsData.hideScrollUp,
      },
      on: { change: _vm.updateFixedStatus },
    },
    [
      _c(
        "div",
        { staticClass: "navbar" },
        [
          _c("div", { staticClass: "left-menu" }, [
            _c("span", { staticStyle: { float: "left" } }, [
              _vm.getIsOpenCloseManu == false
                ? _c("i", {
                    staticClass: "el-icon-s-fold",
                    on: {
                      click: function ($event) {
                        return _vm.changeIsCollapse(true)
                      },
                    },
                  })
                : _c("i", {
                    staticClass: "el-icon-s-unfold",
                    on: {
                      click: function ($event) {
                        return _vm.changeIsCollapse(false)
                      },
                    },
                  }),
            ]),
            _c(
              "span",
              [
                _c(
                  "el-breadcrumb",
                  { attrs: { "separator-class": "el-icon-arrow-right" } },
                  [
                    _vm.getAppTypeUserLogin == _vm.appType.SUPPER_SCHOOL ||
                    _vm.getAppTypeUserLogin == _vm.appType.SCHOOL ||
                    _vm.getAppTypeUserLogin == _vm.appType.TEACHER ||
                    _vm.getAppTypeUserLogin == _vm.appType.PARENT
                      ? _c(
                          "el-breadcrumb-item",
                          { attrs: { to: { path: "/" } } },
                          [_vm._v("Trang chủ")]
                        )
                      : _c(
                          "el-breadcrumb-item",
                          { attrs: { to: { path: "/dashboard/admin" } } },
                          [_vm._v("Trang chủ")]
                        ),
                    _vm._l(_vm.getBreadcrumbDataList, function (item, index) {
                      return _c(
                        "el-breadcrumb-item",
                        { key: index, attrs: { to: { path: item.url } } },
                        [_vm._v("\n            " + _vm._s(item.name))]
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "right-menu" },
            [
              _vm.getAppTypeUserLogin == _vm.appType.SUPPER_SCHOOL ||
              _vm.getAppTypeUserLogin == _vm.appType.SCHOOL ||
              _vm.getAppTypeUserLogin == _vm.appType.TEACHER ||
              _vm.getAppTypeUserLogin == _vm.appType.PARENT
                ? _c("span", { staticClass: "school-name" }, [
                    _vm._v(_vm._s(_vm.getDataSchool.schoolName)),
                  ])
                : _vm._e(),
              _c("span", { staticClass: "fullname-login" }, [
                _vm._v(_vm._s(_vm.getUserLogin)),
              ]),
              _c(
                "el-dropdown",
                {
                  staticClass: "avatar-container",
                  attrs: { trigger: "click" },
                  on: { command: _vm.handleDropdow },
                },
                [
                  _c(
                    "div",
                    { staticClass: "avatar-wrapper" },
                    [
                      _c("el-image", {
                        staticClass: "user-avatar",
                        staticStyle: {
                          width: "40px",
                          height: "40px",
                          "line-height": "50px",
                        },
                        attrs: { src: _vm.getAvatar },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "profileCommand" } },
                        [
                          _vm._v(
                            "\n            Cập nhật trang cá nhân\n          "
                          ),
                        ]
                      ),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "changePasswordCommand" } },
                        [_vm._v("\n            Đổi mật khẩu\n          ")]
                      ),
                      (_vm.getDataSchool != null &&
                        _vm.getDataSchool.manyStatus &&
                        _vm.getAppTypeUserLogin == _vm.appType.SUPPER_SCHOOL) ||
                      _vm.getAppTypeUserLogin == _vm.appType.SCHOOL ||
                      _vm.getAppTypeUserLogin == _vm.appType.TEACHER ||
                      _vm.getAppTypeUserLogin == _vm.appType.PARENT
                        ? _c(
                            "el-dropdown-item",
                            { attrs: { command: "changeSchoolCommand" } },
                            [
                              _vm.getAppTypeUserLogin == _vm.appType.PARENT
                                ? _c("span", [_vm._v("Chọn con")])
                                : _c("span", [_vm._v("Đổi trường")]),
                            ]
                          )
                        : _vm._e(),
                      _c("el-dropdown-item", [
                        _c(
                          "span",
                          {
                            staticStyle: { display: "block" },
                            on: { click: _vm.logoutDeviceMethod },
                          },
                          [_vm._v("Đăng xuất")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("ChangePassword", {
            attrs: { dialogVisible: _vm.showCreateDialog },
            on: {
              "dialog-close": function ($event) {
                return _vm.dialogCloseCreateMethod()
              },
            },
          }),
          _c("Profile", {
            ref: "Profile",
            attrs: { dialogVisible: _vm.showProfileDialog },
            on: {
              "dialog-close": function ($event) {
                return _vm.dialogCloseProfileMethod()
              },
            },
          }),
          _c("ChangeSchool", {
            ref: "ChangeSchool",
            attrs: { dialogVisible: _vm.showChangeSchoolDialog },
            on: {
              "dialog-close": function ($event) {
                return _vm.dialogCloseChangeSchoolMethod()
              },
            },
          }),
          _c("ChangeKidsDialog", {
            ref: "ChangeKidsDialog",
            attrs: { dialogVisible: _vm.showChangeKidsDialog },
            on: {
              "dialog-close": function ($event) {
                return _vm.dialogCloseChangeKidsMethod()
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }