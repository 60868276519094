<template>
  <div class="menu-main">
    <el-menu
        class="el-menu-vertical-demo"
        :collapse="getIsOpenCloseManu"
        background-color="#304156"
        text-color="#bfcbd9"
        active-text-color="#409EFF"
        :unique-opened="true"
    >
      <router-link to="/dashboard" class="router-link">
        <el-menu-item index="1">
          <i class="el-icon-s-home"></i>
          <span slot="title" class="onekids-title">SUPPER PLUS</span>
        </el-menu-item>
      </router-link>
      <el-submenu index="2">
        <template slot="title">
          <i class="el-icon-user"/>
          <span>Quản lý nhân sự</span>
        </template>
        <router-link to="/employee/employees-plus" class="router-link">
          <el-menu-item
              index="2-1"
              @click="changeBreadcrumbMethod('employee-employees-plus')"
          >Quản lý Plus
          </el-menu-item>
        </router-link>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title">
          <i class="el-icon-setting"/>
          <span>Cấu hình nhà trường</span>
        </template>
        <router-link to="/school/config" class="router-link">
          <el-menu-item
              index="3-1"
              @click="changeBreadcrumbMethod('school-config')"
          >Cấu hình chung
          </el-menu-item>
        </router-link>
        <router-link to="/school/camera" class="router-link">
          <el-menu-item
              index="3-2"
              @click="changeBreadcrumbMethod('school-camera')"
          >Cấu hình Camera
          </el-menu-item>
        </router-link>
        <router-link to="/school/media" class="router-link">
          <el-menu-item
              index="3-3"
              @click="changeBreadcrumbMethod('school-media')"
          >Cấu hình Media
          </el-menu-item>
        </router-link>

        <router-link to="/school/birthday-sample" class="router-link">
          <el-menu-item
              index="3-4"
              @click="changeBreadcrumbMethod('school-birthday-sample')"
          >Cấu hình gửi tin
          </el-menu-item>
        </router-link>
        <router-link to="/school/notification-manage" class="router-link">
          <el-menu-item
              index="3-5"
              @click="changeBreadcrumbMethod('school-notification-manage')"
          >Tự động gửi tin
          </el-menu-item>
        </router-link>
        <router-link to="/school/config-fuction" class="router-link">
          <el-menu-item
              index="3-6"
              @click="changeBreadcrumbMethod('school-config-fuction')"
          >Ẩn hiển chức năng
          </el-menu-item>
        </router-link>
        <router-link to="/school/permission-manage" class="router-link">
          <el-menu-item
              index="3-7"
              @click="changeBreadcrumbMethod('school-permission-manage')"
          >Quản lý quyền
          </el-menu-item>
        </router-link>
<!--          Thêm cấu hình đểm danh chủ nhật-->
        <router-link to="/school/permission-manage-for-supperPlus" class="router-link">
          <el-menu-item
              index="3-8"
              @click="changeBreadcrumbMethod('school-permission-manage-for-supperPlus')"
          >Tạo dữ liệu chung
          </el-menu-item>
        </router-link>


<!--        Thêm cấu hình Template App cho trường-->
        <router-link to="/school/config-template-app" class="router-link">
          <el-menu-item
              index="3-9"
              @click="changeBreadcrumbMethod('config-template-app')"
          >Cấu hình Template App
          </el-menu-item>
        </router-link>
      </el-submenu>
    </el-menu>
  </div>
</template>
<script>

export default {
  name: "MenuSuperSchool",
  data() {
    return {
      supportList: [],
      breadcrumbDataList: [
        //quản lý nhân sự plus
        {
          key: "employee-employees-plus",
          value: [{url: "/employee/employees-plus", name: "Quản lý Plus"}],
        },
        {
          key: "school-config",
          value: [{url: "/school/config", name: "Cấu hình chung"}],
        },
        {
          key: "school-camera",
          value: [{url: "/school/camera", name: "Cấu hình Camera"}],
        },
        {
          key: "school-media",
          value: [{url: "/school/media", name: "Cấu hình Media"}],
        },
        {
          key: "school-birthday-sample",
          value: [{url: "/school/birthday-sample", name: "Cấu hình gửi tin"}],
        },
        {
          key: "school-notification-manage",
          value: [{url: "/school/notification-manage", name: "Tự động gửi tin"}],
        },
        {
          key: "school-config-fuction",
          value: [{url: "/school/config-fuction", name: "Ẩn hiển chức năng"}],
        },
        {
          key: "school-permission-manage",
          value: [{url: "/school/permission-manage", name: "Quản lý quyền"}],
        },
        {
          key: "school-permission-manage-for-supperPlus",
          value: [{url: "/school/permission-manage-for-supperPlus", name: "Tạo dữ liệu chung"}],
        },

        {
          key: "config-template-app",
          value: [{url: "/school/config-template-app", name: "Cấu hình Template App"}],
        },
      ]

    };
  },
  computed: {
    getIsOpenCloseManu() {
      return this.$store.getters["layout/getIsOpenClose"];
    },
    getAppTypeUserLogin() {
      return this.$store.state.auth.user.appType;
    },
  },
  methods: {
    changeBreadcrumbMethod(val) {

      let selectUrl = this.breadcrumbDataList.filter((x) => x.key == val);
      if (selectUrl.length == 1) {
        let dataList = selectUrl[0].value;
        sessionStorage.setItem("breadcrumbList", JSON.stringify(dataList));
        this.$store.dispatch("layout/setBreadcrumbActions", dataList);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.menu-main {
  min-height: 950px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 210px;
  min-height: 700px;
  font-family: Arial;
}

.router-link {
  text-decoration: none;
}

.el-menu-item {
  background-color: #1f2d3d !important;
}

.el-menu-item:hover {
  background-color: #49575f !important;
}

/deep/ .el-message-box {
  display: inline-block;
  vertical-align: top !important;
  width: 420px;
  padding-bottom: 10px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  font-size: 18px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/deep/ .el-message-box__wrapper::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: top !important;
}

.el-menu {
  margin-bottom: 20px;
}

.onekids-title {
  font-weight: bold;
}
</style>