export const financeFee=[
    {
        path: 'finace/fees/package',
        component: () => import('@/views/finance/Package/Package.vue')
    },
    {
        path: 'finace/fees/package-kids',
        component: () => import('@/views/finance/Package/PackageDefaultKids.vue')
    },
    {
        path: 'finace/fees/approved',
        component: () => import('@/views/finance/Package/PackageKidsApproved.vue')
    },
    {
        path: 'finace/fees/approved/advance',
        component: () => import('@/views/finance/Package/PackageKidsApprovedAdvance.vue')
    },
    {
        path: 'finace/fees/order',
        component: () => import('@/views/finance/OderKids/OrderKids.vue')
    },
    {
        path: 'finace/fees/wallet',
        component: () => import('@/views/finance/Wallet/WalletParent.vue')
    }
]