export const financeV2Route = [
    {
        path: '/finance/v2',
        component: () => import('@/views/finance/OrderKidsV2/OrderKidsV2.vue'),

    },
    {
        path: '/finance/tuition-group-by-excel',
        component: () => import('@/components/FinanceVer2/ByGroup/TuitionServiceByExcel2.vue')
    },
    {
        path: '/finance/tuition-statistics',
        component: () => import('@/views/finance/TuitionStatistics/layout.vue')
    },

    {
        path: '/finance/tuition-statistics/charts',
        component: () => import('@/components/FinanceVer2/TuitionStatistics/StatisticsChart.vue')
    },

    {
        path: '/finance/employee/salary/new',
        component: () => import('@/views/finance/OrderKidsV2/EmployeeSalaryV2.vue')
    },

    {
        path: '/finance/employee/static-salary/new',
        component: () => import('@/components/FinanceVer2/employeeStatistics/StatisticsChartEmployeeSalary.vue')
    }
]