import MenuSupportService from "@/services/AdminService/MenuSupportService";
import SchoolService from "@/services/SchoolService/SchoolService";

export const dashBoardStore = {
    namespaced: true,
    state: {
        imgUrls: [],
        imgForPlus: '',
        imgForTeacher: '',
        imgForParent: '',
        getAllSchoolDataList: []
    },
    mutations: {
        SET_ImgUrls(state, imgUrls) {
            state.imgUrls = imgUrls;
        },
        SET_ImgForPlus(state, imgForPlus) {
            state.imgForPlus = imgForPlus;
        },
        SET_ImgForTeacher(state, imgForTeacher) {
            state.imgForTeacher = imgForTeacher;
        },
        SET_ImgForParent(state, imgForParent) {
            state.imgForParent = imgForParent;
        },
        SET_GetAllSchoolDataList(state, getAllSchoolDataList) {
            state.getAllSchoolDataList = getAllSchoolDataList;
        }
    },
    actions: {
        // lấy link ảnh cho dashboard
        fetchDataImgUrls({commit}) {
            return MenuSupportService.getSupportSearchDashboard()
                .then((resp) => {

                    let imgDefault = resp.data.data.filter((x) => x.linkType === "linkDefault");
                    if (imgDefault.length > 0) {
                        commit('SET_ImgUrls', imgDefault)
                    }

                    let imgForPlus = resp.data.data.filter((x) => x.plusStatus && x.linkType === "dashboard");
                    if (imgForPlus.length > 0) {
                        commit('SET_ImgForPlus', imgForPlus[imgForPlus.length - 1].link)
                    }

                    let imgForTeacher = resp.data.data.filter((x) => x.teacherStatus && x.linkType === "dashboard");
                    if (imgForTeacher.length > 0) {
                        commit('SET_ImgForTeacher', imgForTeacher[imgForTeacher.length - 1].link)
                    }

                    let imgForParent = resp.data.data.filter((x) => x.parentStatus && x.linkType === "dashboard");
                    if (imgForParent.length > 0) {
                        commit('SET_ImgForParent', imgForParent[imgForParent.length - 1].link)
                    }

                })
                .catch((err) => {
                    console.log(err)
                })

        },
        //Lấy toàn bộ danh sách trường
        fetchDataAllSchool({commit}) {
            return SchoolService.getAllSchool()
                .then((res) => {
                    commit('SET_GetAllSchoolDataList', res.data.data)
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    },
};