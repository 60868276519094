var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Cập nhật trang cá nhân",
        visible: _vm.dialogVisible,
        width: "800px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "10vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "userData",
          attrs: {
            "label-width": "130px",
            model: _vm.userData,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _vm.userData.avatar != null && _vm.showPicutreInitial
                    ? _c("el-image", {
                        staticStyle: {
                          width: "150px",
                          height: "150px",
                          float: "left",
                          "margin-bottom": "20px",
                        },
                        attrs: { src: _vm.userData.avatar, fit: "fill" },
                      })
                    : _vm._e(),
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      staticClass: "avatar-uploader",
                      class: { hideUpload: !_vm.showUpload },
                      attrs: {
                        "show-file-list": true,
                        "list-type": "picture-card",
                        "on-change": _vm.handleChangeUpload,
                        "on-remove": _vm.handleRemove,
                        "auto-upload": false,
                        limit: "1",
                      },
                    },
                    [
                      _vm.userData.avatar != null
                        ? _c("span", [
                            _c(
                              "i",
                              {
                                staticClass: "el-icon-plus",
                                staticStyle: { "font-size": "20px" },
                              },
                              [_vm._v("Cập nhật ảnh")]
                            ),
                          ])
                        : _c("span", [
                            _c(
                              "i",
                              {
                                staticClass: "el-icon-plus",
                                staticStyle: { "font-size": "20px" },
                              },
                              [_vm._v("Thêm ảnh")]
                            ),
                          ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 17 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tài khoản", prop: "username" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.userData.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.userData, "username", $$v)
                          },
                          expression: "userData.username",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Nickname/Họ tên", prop: "fullName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Nhập Nickname/Họ tên" },
                        model: {
                          value: _vm.userData.fullName,
                          callback: function ($$v) {
                            _vm.$set(_vm.userData, "fullName", $$v)
                          },
                          expression: "userData.fullName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Số điện thoại", prop: "phone" } },
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: "Số điện thoại nhận SMS",
                            placement: "top",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              placeholder: "Nhập số điện thoại",
                            },
                            model: {
                              value: _vm.userData.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.userData, "phone", $$v)
                              },
                              expression: "userData.phone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Giới tính", prop: "gender" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.userData.gender,
                            callback: function ($$v) {
                              _vm.$set(_vm.userData, "gender", $$v)
                            },
                            expression: "userData.gender",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Nam" } }, [
                            _vm._v("Nam"),
                          ]),
                          _c("el-radio", { attrs: { label: "Nữ" } }, [
                            _vm._v("Nữ"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.userData.appType == "parent"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Ngày sinh" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              format: "dd-MM-yyyy",
                              type: "date",
                              "picker-options": _vm.pickerOptions,
                              "value-format": "yyyy-MM-dd",
                              placeholder: "Chọn ngày sinh",
                            },
                            model: {
                              value: _vm.userData.birthday,
                              callback: function ($$v) {
                                _vm.$set(_vm.userData, "birthday", $$v)
                              },
                              expression: "userData.birthday",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "el-form-item",
                        { attrs: { label: "Ngày sinh", prop: "birthday" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              format: "dd-MM-yyyy",
                              type: "date",
                              "picker-options": _vm.pickerOptions,
                              "value-format": "yyyy-MM-dd",
                              placeholder: "Chọn ngày sinh",
                            },
                            model: {
                              value: _vm.userData.birthday,
                              callback: function ($$v) {
                                _vm.$set(_vm.userData, "birthday", $$v)
                              },
                              expression: "userData.birthday",
                            },
                          }),
                        ],
                        1
                      ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Email", prop: "email" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Nhập email" },
                        model: {
                          value: _vm.userData.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.userData, "email", $$v)
                          },
                          expression: "userData.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("userData")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm("userData")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }