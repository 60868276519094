import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { auth } from "./authmodule.js";
import { layout } from "./layoutmodule.js";
import { classStore } from "@/store/classStore";
import { employeeStore } from "@/store/employeeStore";
import { departmentStore } from "@/store/departmentStore";
import { studentStore } from "@/store/studentStore";
import { kidsStore } from "@/store/kidsStore";
import { finance2Module } from "@/store/finance2.module";
import {gradeModule} from "@/store/grade.module";
import {studentGroupModule} from "@/store/studentGroup.module";
import {groupExcelModule} from "@/store/GroupExcel.module";
import {dashBoardStore} from "@/store/dashBoardStore";
import {schoolStore} from "@/store/schoolStore";
import {employeeSalaryNew} from "@/store/employeeSalaryNew";
import {staticSalaryNew} from "@/store/staticSalaryNew";
import {commonDataStore} from "@/store/commonDataStore";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    layout,
    gradeModule,
    classStore,
    employeeStore,
    departmentStore,
    studentStore,
    kidsStore,
    finance2Module,
    studentGroupModule,
    groupExcelModule,
    dashBoardStore,
    schoolStore,
    employeeSalaryNew,
    staticSalaryNew,
    commonDataStore
  },
  plugins: [
    createPersistedState({
      paths: [
        "finance2Module.dataBillPreview",
        "finance2Module.billTableForOneKidPreview",
        "finance2Module.totalBillForOneKid",
        "finance2Module.isContainAllBillOneKid",
        "finance2Module.loadingOneKid",
        "finance2Module.loadingBillPreview",
        "finance2Module.dataSearch",
        "finance2Module.exportParamForOneKid",
        "finance2Module.idKidList",
        "groupExcelModule.dataBillPreview",
        "groupExcelModule.billTableForOneKidPreview",
        "groupExcelModule.totalBillForOneKid",
        "groupExcelModule.isContainAllBillOneKid",
        "groupExcelModule.loadingOneKid",
        "groupExcelModule.loadingBillPreview",
        "groupExcelModule.dataSearch",
        "groupExcelModule.exportParamForOneKid",
        "groupExcelModule.idKidList",
        "employeeSalaryNew.dataBillPreview",
        "employeeSalaryNew.billTableForOneKidPreview",
        "employeeSalaryNew.totalBillForOneKid",
        "employeeSalaryNew.isContainAllBillOneKid",
        "employeeSalaryNew.loadingOneKid",
        "employeeSalaryNew.loadingBillPreview",
        "employeeSalaryNew.dataSearch",
        "employeeSalaryNew.exportParamForEmployee",
        "employeeSalaryNew.idInfoList",
      ], // lấy state pdf trong vuex modules finance
    }),
  ],
});


