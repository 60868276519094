import http from "../../http-common";
import http_multipart from "@/http-multipart";

class NewSalaryService {

    // Cập nhật điểm danh
    updateAttendanceEmployee(date) {
        return http.get(`/newSalary/attendanceEmployee`, {params: {date}})
    }

    // Download File excel
    downloadExcelFile(guiId) {
        return http.get("/newSalary/downloadFileTemplateEmployee", {
            responseType: "blob",
            params: {guiId},
        });
    }

    // Lấy danh sách template
    getAllTemplateList() {
        return http.get(`/newSalary/listTemplateForEmployee`)
    }

    // Xóa template
    deleteTemplateEmployeeSalary(listTemplate) {
        return http.get(`/newSalary/deleteTemplateEmployee`, {params: listTemplate})
    }

    // Upload template mẫu
    uploadTemplateSample(formData) {
        return http_multipart.post(`/newSalary/uploadTemplateForEmployee`, formData)
    }

    // Hủy phiếu lương
    cancelsSalarySlip(body) {
        return http.post(`/newSalary/hiddenOrderEmployeeExcel`, body)
    }

    // Khôi phục phiếu lương
    restoreSalarySlip(body) {
        return http.post(`/newSalary/openOrderEmployeeExcel`, body)
    }

    // Xóa phiếu lương
    destroySalarySlip(body) {
        return http.post(`/newSalary/destroyOrderEmployeeExcel`, body)
    }

    // Xuất phiếu lương tổng hợp
    exportGeneralSalarySlips(data) {
        return http.get(`/newSalary/exportToExcelForEmployee`, {params: data})
    }

    // Hiển thị 1 list phiếu lương trên APP công lương nhân viên
    showEmployeeSalarySlips(data) {
        return http.post(`/newSalary/showListOrderForAppEmployee`, data)
    }

    // Bỏ Hiển thị 1 list phiếu lương trên APP công lương nhân viên
    hiddenEmployeeSalarySlips(data) {
        return http.post(`/newSalary/hiddenListOrderForAppEmployee`, data)
    }

    // Hiển thị / Bỏ hiển thị 1 hóa đơn trên APP công lương nhân viên checkbox
    showOrHiddenEmployeeSalary(data) {
        return http.post(`/newSalary/showHiddenOrderEmployee`, data)
    }

    // Tạo ghi chú 1 danh sách phiếu lương công lương nhân viên
    createNoteEmployeeSalarySlips(data) {
        return http.post(`/newSalary/createNoteForListEmployees`, data)
    }

    // Hiển thị số tiền đã chi của 1 phiếu lương công lương nhân viên
    displaysTheAmountSpent(param) {
        return http.get(`/newSalary/displaySpentMoneyEmployee`, {params: param})
    }

    // Sửa số tiền đã chi của 1 hóa đơn công lương nhân viên
    updateEmployeeSalarySlips(request) {
        return http.post(`/newSalary/modifySpendMoneyEmployee`, request)
    }

    // Hiển thị list danh sách các thuộc tính cần filter
    filterEmployeeSalary(request) {
        return http.post(`/newSalary/filterPaySlip`, request)
    }

    // Lấy danh sách các phòng ban trong trường
    getListDepartment() {
        return http.get(`/newSalary/listDepartment`)
    }

    // Lấy trạng thái chi tiền
    getPaymentStatus(param) {
        return http.get(`/newSalary/listStatusSalary`, {params: param})
    }

    // Lấy đợt chi tiền
    getSpendTurn(param) {
        return http.get(`/newSalary/spendTurn`, {params: param})
    }

    // Hiển thị thông tin bổ sung của nhân viên
    displaysAdditionalEmployeeInformation(param) {
        return http.get(`/newSalary/displayOrderAndAttendanceEmployee`, {params: param})
    }

    // Sửa thông tin bổ sung của nhân viên
    updateAdditionalEmployeeInformation(request) {
        return http.post(`/newSalary/modifyOrderAndAttendanceEmployee`, request)
    }

    // Lấy thông tin màn tồn phiếu lương nhân viên
    getInformationAboutEmployeeSalaryChecks(request) {
        return http.post(`/newSalary/listBillPaySlip`, request)
    }

    // Thay đổi trạng thái kết chuyển màn tồn phiếu lương
    changeStatusTransactionEntry(request) {
        return http.post(`/newSalary/updateTransactionEntryPaySlip`, request)
    }

    // Xuất file excel công lương theo đợt thu
    exportExcelBySpendTurn(param) {
        return http.get(`/newSalary/exportExcelForSpendTurnEmployee`, {params: param})
    }

    // Xuất file excel công lương theo tháng (tất cả các đợt) chi theo tháng cho nhân viên nhà trường
    downloadFileExcelAllByMonth(param) {
        return http.get(`/newSalary/exportExcelForManySpendTurnsEmployee`, {params: param})
    }

    // Khởi tạo phiếu lương
    createSalarySlip(param) {
        return http.get(`/newSalary/createOrderForEmployeeNotHaveOrder`, {params: param})
    }

    // Xem xuất trước hóa đơn
    showListPrintOrderEmployeeExcel(param) {
        return http.get(`/newSalary/showPrintOrderEmployee`, {params: param})
    }

    /**
     * Đọc dữ liệu từ file Excel hiển thị thông báo lên màn hình
     */
    readAndCheckDataExcelSalary(formExcelData) {
        return http_multipart.post("/newSalary/readAndCheckDataExcelSalary", formExcelData);
    }

    /**
     * Hoành đánh dấu: Lưu dữ liệu đọc được từ file Excel vào DB
     */
    saveDataExcelSalary(listObject) {
        return http.post("/newSalary/saveDataExcelSalary", listObject);
    }


    // Biểu đồ thống kê phiếu lương của nhân viên
    statisticalChartOfEmployeeSalarySlips(param) {
        return http.get(`/newSalary/paySlipChart`, {params: param})
    }

    // Lấy thông tin màn lịch sử thanh toán tác vụ
    toRetrievePaymentHistoryInformation(param) {
        return http.get(`/newSalary/showHistoryOrderEmployee`, {params: param})
    }

    // Xuất file excel in 1,2 liên
    exportBillToExcel(param) {
        return http.get(`/newSalary/printOrder1And2PageEmployee`, {params: param})
    }

    // Xuất excel in hóa đơn
    downloadBillToExcel(guiId) {
        return http.get('/fn/fees/downloadFileOrder',
            {params: {guiId}, responseType: "blob",})
    }

    // Xuất phiếu lương cho một nhân viên
    issueAPaySlipToAnEmployee(param) {
        return http.get(`/newSalary/printOrderForOneEmployee`, {params: param})
    }

    // Xuất phiếu lương theo tháng
    issueMonthlySalarySlips(param) {
        return http.get(`/newSalary/printOrderManySpendTurns`, {params: param})
    }

    // Thay đổi tên khoản chi
    changeNameKC(param) {
        return http.get(`/newSalary/modifyName20KC`, {params: param})
    }

    // Hiển thị chi tiết khoản chi
    showDetailPayment(param) {
        return http.get(`/newSalary/showDetailPaymentKCEmployee`, {params: param})
    }

    // Sửa chi tiết tên khoản
    saveDetailKC(request) {
        return http.post(`/newSalary/modifyDetailPaymentKCEmployee`, request)
    }

    /**
     * Hoành đánh dấu: Hiển thị ngày bắt đầu và ngày kết thúc (Công lương)
     */

    showStartAndEndDateSalary(param) {
        return http.post(`/newSalary/showStartAndEndDateSalary`, param)
    }

    /**
     * Hoành đánh dấu: Hiển thị danh sách khoản cho màn hình lưu lịch sử thanh toán (Công lương)
     */

    showAllExpenseForSaveHistory(param) {
        return http.post(`/newSalary/showAllExpenseForSaveHistory`, param)
    }

    // Thống kê công lương nhân viên
    filterStaticEmployeeSalary(request) {
        return http.post(`/newSalary/filterPaySlipChart`, request)
    }

    // Lấy danh sách đợt chi thống kê công lương nhân viên
    getStaticSpendTurn(param) {
        return http.get(`/newSalary/spendTurnChart`, {params: param})
    }

    // xuất excel thống kê công lương cho những nhân viên chưa hoặc đã hoàn thành phiếu lương
    exportExcelStatistic(param) {
        return http.get(`/newSalary/exportExcelStatisticForListEmployee`, {params: param})
    }

    // xuất excel thống kê công lương cho những nhân viên chưa hoặc đã hoàn thành phiếu lương
    exportExcelStatisticHaveNotOrder(param) {
        return http.get(`/newSalary/exportStatisticForEmployeeHaveNotOrder`, {params: param})
    }

    // Thanh toán phiếu lương cho nhân viên
    saveEmployeePaymentForTurn(body) {
        return http.post(`/newSalary/saveSalaryPaymentHistory`, body)
    }

    // Gửi SMS cho nhân viên
    sendSMS(body) {
        return http.post(`/newSalary/createNotifySalaryForTurnForSms`, body)
    }

    // Gửi thông báo qua app
    sendNotifyFinanceForApp(formData) {
        return http_multipart.post(`/newSalary/createNotifySalaryForTurnForApp`, formData)
    }

    // Thanh toán hàng loạt cho nhân viên
    paymentAll(body) {
        return http.post(`/newSalary/paymentFullForManyEmployee`, body)
    }

    // Biểu đồ thống kê lương nhân viên
    getAllChartTuitionRevenueAll(body) {
        return http.post(`/newSalary/getDataForEmployeePaySlipChart`, body)
    }

    /**
     * Hoành đánh dấu: Thêm khoản
     */
    addOneExpense(param) {
        return http.post(`/newSalary/addOneExpense`, param)
    }

    // Xóa khoản
    deleteLastExpense(param) {
        return http.post(`/newSalary/deleteLastExpense`, param)
    }
}

export default new NewSalaryService()