// lấy danh sách các phòng ban

import DepartmentDataService from "@/services/EmployeeService/DepartmentDataService";

export const departmentStore = {
    namespaced: true,
    state: {
        departmentList: [],
        departmentListForPlus: [],
    },

    getters: {
        departmentList: (state) => state.departmentList,
        departmentListForPlus: (state) => state.departmentListForPlus,
    },

    mutations: {
        SET_DepartmentList(state, departmentList) {
            state.departmentList = departmentList;
        },
        SET_DepartmentListForPlus(state, departmentListForPlus) {
            state.departmentListForPlus = departmentListForPlus;
        },
    },

    // thực hiện hành động gọi api
    actions: {
        fetchDataDepartmentList({commit}) {
            return DepartmentDataService.getDepartment(1,50)
                .then((response) => {
                    commit('SET_DepartmentList', response.data.data);
                    commit('SET_DepartmentListForPlus', response.data.data);
                })
                .catch((err) => {
                    console.log(err)
                })

        },

        fetchDataDepartmentListForPlus({commit}) {
            return DepartmentDataService.getAllCommon()
                .then((response) => {
                    commit('SET_DepartmentListForPlus', response.data.data);
                    commit('SET_DepartmentList', response.data.data);
                })
                .catch((err) => {
                    console.log(err)
                })

        },
    }
};
