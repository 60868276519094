import http from "@/http-common";

class StudentGroupService {
    // màn filter
    filterListKidsForGroup(filterParams) {
        return http.get('/fn/fees/filterListKidsForGroup', {params: filterParams})
    }

    // lấy danh sách nhóm
    getListKidsGroup() {
        return http.get('/fn/fees/listNameKidsGroup')
    }

    // lấy danh sách nhóm cho 1 học sinh
    getListKidsGroupByStudentId(idKid) {
        return http.get('/fn/fees/listKidsGroupOfHS', {params: {idKid:idKid}})
    }

    // đổi nhóm cho 1 học sinh
    changeGroupForAStudent(studentParams) {
        return http.get('/fn/fees/replaceKidGroupForOneHS', {params: studentParams})
    }

    // đổi nhóm cho danh sách học sinh
    changeGroupForListStudents(listStudentParams) {
        return http.get('/fn/fees/replaceKidGroupForListHS', {params: listStudentParams})
    }

    // xóa 1 nhóm cho danh sách học sinh
    removeOneGroupForListStudents(listStudentParams) {
        return http.get('/fn/fees/deleteOneKidGroupForListHS', {params: listStudentParams})
    }

    // xóa tất cả nhóm của 1 danh sách học sinh
    removeAllGroupsForListStudents(listStudentParams) {
        return http.get('/fn/fees/deleteAllKidGroupForListHS', {params: listStudentParams})
    }
    // xóa nhóm cho 1 cháu
    removeOneGroupForOneKid(params){
        return http.get('/fn/fees/deleteOneKidGroupForAHS',{params:params})
    }

    // cập nhật điểm danh nhóm
    updateTotalKidsArriveForGroup(groupParams) {
        return http.get('/fn/fees/totalKidsArriveForGroup', {params: groupParams})
    }

    // thêm 1 học sinh vào nhóm
    addOneStudentInGroup(kidParams) {
        return http.post('/fn/fees/orderKidsExcel/addOneStudentForGroup', kidParams)
    }

    // thêm nhiều học sinh vào nhóm
    addMultipleStudentInGroup(studentsParam) {
        return http.post('/fn/fees/orderKidsExcel/addManyStudentForGroup', studentsParam)
    }

}

export default new StudentGroupService();
