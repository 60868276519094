import {
    BILL_TABLE_FOR_ONE_KID, CHECK_RESPONSE,
    GET_STATUS_EXCEL,
    INIT_KIDS_DATA, LOADING_DATA,
    NO_DATA_RESPONSE,
    PREVIEW_BILL_DATA,
    PREVIEW_INFO_FOR_ONE_KID,
    RENEW_BILL_PREVIEW, RESPONSE_FILTER_LIST, SET_HIDDEN_APP_MESSAGE, SET_HIDDEN_FOR_APP,
    SET_HIDDEN_ORDER_KIDS,
    SET_ID_KID_LIST,
    SET_LIST_KT,
    SET_NULL_BILL_KIDS, SET_SHOW_APP_MESSAGE, SET_SHOW_FOR_APP,
    UPDATE_DATA_SEARCH,
} from "@/constant/actions";
import moment from "moment";
import groupExcelService from "@/services/Finance_Kids_V2/GroupExcelService";
// kid_data_name là 1 object gồm tên các khoản thu
const countNameBillValidate = (kid_data_name) => {
    let totalAvailable = 0;
    if (kid_data_name.nameKt1 !== "Khoản thu 1") {
        totalAvailable++;
    }
    if (kid_data_name.nameKt2 !== "Khoản thu 2") {
        totalAvailable++;
    }
    if (kid_data_name.nameKt3 !== "Khoản thu 3") {
        totalAvailable++;
    }
    if (kid_data_name.nameKt4 !== "Khoản thu 4") {
        totalAvailable++;
    }
    if (kid_data_name.nameKt5 !== "Khoản thu 5") {
        totalAvailable++;
    }
    if (kid_data_name.nameKt6 !== "Khoản thu 6") {
        totalAvailable++;
    }
    if (kid_data_name.nameKt7 !== "Khoản thu 7") {
        totalAvailable++;
    }
    if (kid_data_name.nameKt8 !== "Khoản thu 8") {
        totalAvailable++;
    }
    if (kid_data_name.nameKt9 !== "Khoản thu 9") {
        totalAvailable++;
    }
    if (kid_data_name.nameKt10 !== "Khoản thu 10") {
        totalAvailable++;
    }
    if (kid_data_name.nameKt11 !== "Khoản thu 11") {
        totalAvailable++;
    }
    if (kid_data_name.nameKt12 !== "Khoản thu 12") {
        totalAvailable++;
    }
    if (kid_data_name.nameKt13 !== "Khoản thu 13") {
        totalAvailable++;
    }
    if (kid_data_name.nameKt14 !== "Khoản thu 14") {
        totalAvailable++;
    }
    if (kid_data_name.nameKt15 !== "Khoản thu 15") {
        totalAvailable++;
    }
    if (kid_data_name.nameKt16 !== "Khoản thu 16") {
        totalAvailable++;
    }
    if (kid_data_name.nameKt17 !== "Khoản thu 17") {
        totalAvailable++;
    }
    if (kid_data_name.nameKt18 !== "Khoản thu 18") {
        totalAvailable++;
    }
    if (kid_data_name.nameKt19 !== "Khoản thu 19") {
        totalAvailable++;
    }
    if (kid_data_name.nameKt20 !== "Khoản thu 20") {
        totalAvailable++;
    }
    return totalAvailable;
};
export const groupExcelModule = {
    namespaced: true,
    state: {

        listHiddenForApp: [],
        listShowForApp: [],
        messageShowForApp: '',
        messageHiddenForApp: '',
        listNullBillKids: "",
        listHiddenOrderKids: "",
        responseFilterList: [],
        listKT: {
            nameKt1: "",
            nameKt2: "",
            nameKt3: "",
            nameKt4: "",
            nameKt5: "",
            nameKt6: "",
            nameKt7: "",
            nameKt8: "",
            nameKt9: "",
            nameKt10: "",
            nameKt11: "",
            nameKt12: "",
            nameKt13: "",
            nameKt14: "",
            nameKt15: "",
            nameKt16: "",
            nameKt17: "",
            nameKt18: "",
            nameKt19: "",
            nameKt20: "",
        },
        //loading
        loadingOneKid: true,
        loadingBillPreview: true,
        loadingData: true,
        totalPages: "",
        dataSearch: {},
        idKidList: "",
        dataBillPreview: [],
        exportParamForOneKid: {
            date: '',
            collectionTurn: 0,
            idKidList: '',
            listNameKT: '',
            idGroup: ''
        },
        billTableForOneKidPreview: [],
        totalBillForOneKid: 0,
        isContainAllBillOneKid: false, // các khoản thu chọn đều có
        updateStatus: false,
        checkRevenue: false,
        checkResponse: false,
    },
    mutations: {
        [RESPONSE_FILTER_LIST](state, payload) {
            state.responseFilterList = payload;
        },
        [INIT_KIDS_DATA](state, payload) {
            state.loadingData = true;
            if (payload) {
                state.responseFilterList = payload.listFilterDTO;
                state.totalPages = payload.totalPage;
                state.loadingData = false;
            }
        },
        [UPDATE_DATA_SEARCH](state, payload) {
            state.dataSearch = payload; // dùng lấy dữ liệu data tìm kiếm
        },
        [SET_ID_KID_LIST](state, payload) {
            state.idKidList = payload.join(`,`); // dùng để set truyền param cho api
        },
        [SET_LIST_KT](state, payload) {
            state.listKT = payload;
        },
        [PREVIEW_BILL_DATA](state, payload) {
            const {data, printStyle} = payload
            if (printStyle === 'pdfPreview') {
                window.open('/pdf-group-preview', '_blank')
            } else {
                window.open('/split-pdf-group-preview', '_blank')
            }
            state.dataBillPreview = data;
            state.dataBillPreview.forEach((item, index) => {
                item.stt = index++
                item.dateNow = moment(item.dateNow).format(
                    "[Ngày] DD [tháng] MM [năm] YYYY"
                );
            });
            state.loadingBillPreview = false;
            // chuyển trang sẽ xóa state của danh sách id học sinh về ban đầu

        },
        [RENEW_BILL_PREVIEW](state) {
            state.loadingBillPreview = true;
            state.dataBillPreview = [];
            state.idKidList = "";
            state.billTableForOneKidPreview = [];
            state.totalBillForOneKid = 0;
            state.isContainAllBillOneKid = false;
            state.loadingOneKid = true
        },
        [NO_DATA_RESPONSE](state) {
            (state.listKT.nameKt1 = "Khoản thu 1"),
                (state.listKT.nameKt2 = "Khoản thu 2"),
                (state.listKT.nameKt3 = "Khoản thu 3"),
                (state.listKT.nameKt4 = "Khoản thu 4"),
                (state.listKT.nameKt5 = "Khoản thu 5"),
                (state.listKT.nameKt6 = "Khoản thu 6"),
                (state.listKT.nameKt7 = "Khoản thu 7"),
                (state.listKT.nameKt8 = "Khoản thu 8"),
                (state.listKT.nameKt9 = "Khoản thu 9"),
                (state.listKT.nameKt10 = "Khoản thu 10"),
                (state.listKT.nameKt11 = "Khoản thu 11"),
                (state.listKT.nameKt12 = "Khoản thu 12"),
                (state.listKT.nameKt13 = "Khoản thu 13"),
                (state.listKT.nameKt14 = "Khoản thu 14"),
                (state.listKT.nameKt15 = "Khoản thu 15"),
                (state.listKT.nameKt16 = "Khoản thu 16"),
                (state.listKT.nameKt17 = "Khoản thu 17"),
                (state.listKT.nameKt18 = "Khoản thu 18"),
                (state.listKT.nameKt19 = "Khoản thu 19"),
                (state.listKT.nameKt20 = "Khoản thu 20");
        },
        [SET_SHOW_FOR_APP](state, payload) {
            state.listShowForApp = payload
            state.messageShowForApp = ''
            state.listShowForApp.forEach((item, index) => {
                state.messageShowForApp = state.messageShowForApp.concat("<br>", `${index + 1} - ${item}`);
            })
        },
        [SET_SHOW_APP_MESSAGE](state, payload) {
            state.listShowForApp = [...state.listShowForApp, payload]
            state.listHiddenForApp = state.listHiddenForApp.filter(name => name !== payload)
            state.messageShowForApp = ''
            state.messageHiddenForApp = ''
            state.listShowForApp.forEach((item, index) => {
                state.messageShowForApp = state.messageShowForApp.concat("<br>", `${index + 1} - ${item}`);
            })
            state.listHiddenForApp.forEach((item, index) => {
                state.messageHiddenForApp = state.messageHiddenForApp.concat("<br>", `${index + 1} - ${item}`);
            })

        },
        [SET_HIDDEN_FOR_APP](state, payload) {
            state.listHiddenForApp = payload
            state.messageHiddenForApp = ''
            state.listHiddenForApp.forEach((item, index) => {
                state.messageHiddenForApp = state.messageHiddenForApp.concat("<br>", `${index + 1} - ${item}`);
            })
        },
        [SET_HIDDEN_APP_MESSAGE](state, payload) {
            state.listHiddenForApp = [...state.listHiddenForApp, payload]
            state.listShowForApp = state.listShowForApp.filter(name => name !== payload)
            state.messageHiddenForApp = ''
            state.messageShowForApp = ''
            state.listShowForApp.forEach((item, index) => {
                state.messageShowForApp = state.messageShowForApp.concat("<br>", `${index + 1} - ${item}`);
            })
            state.listHiddenForApp.forEach((item, index) => {
                state.messageHiddenForApp = state.messageHiddenForApp.concat("<br>", `${index + 1} - ${item}`);
            })
        },
        [SET_NULL_BILL_KIDS](state, payload) {
            state.listNullBillKids = ''
            payload.forEach((item, index) => {
                state.listNullBillKids = state.listNullBillKids.concat("<br>", `${index + 1} - ${item}`);
            })
        },
        [SET_HIDDEN_ORDER_KIDS](state, payload) {
            state.listHiddenOrderKids = ''
            payload.forEach((item, index) => {
                state.listHiddenOrderKids = state.listHiddenOrderKids.concat('<br>', `${index + 1} - ${item}`)
            })
        },
        [BILL_TABLE_FOR_ONE_KID](state, list_kt_one_kid) {
            state.totalBillForOneKid = 0;
            state.billTableForOneKidPreview = list_kt_one_kid;
            let listBill = []
            list_kt_one_kid.forEach((item) => {
                state.totalBillForOneKid += item.moneyKt;
                listBill = [...listBill, item.nameKt]
            });
            state.exportParamForOneKid.listNameKT = listBill.join(',')

        },
        // xử lí y hệt Preview In hóa đơn, chia luồng ra để so sánh số lượng khoản thu cho 1 học sinh
        [PREVIEW_INFO_FOR_ONE_KID](state, data) {
            window.open('/one-kid-group-pdf-preview', '_blank')

            // gán param excel
            state.exportParamForOneKid.idKidList = data[0].idKid
            state.exportParamForOneKid.collectionTurn = state.dataSearch.collectionTurn // đợt thu là đồng bộ
            state.exportParamForOneKid.date = moment(state.dataSearch.createdYear + '-' + state.dataSearch.month + '-01', "YYYY-MM-DD").format("YYYY-MM-DD");
            state.exportParamForOneKid.idGroup = state.dataSearch.idGroup
            state.dataBillPreview = data;
            state.dataBillPreview.forEach((item) => {
                item.dateNow = moment(item.dateNow).format(
                    "[Ngày] DD [tháng] MM [năm] YYYY"
                );
            });
            // nếu số lượng bằng nhau, cho hiện dữ liệu thiếu thừa
            if (
                countNameBillValidate(data[0]) ===
                state.billTableForOneKidPreview.length
            ) {
                state.isContainAllBillOneKid = true;
            } else {
                state.isContainAllBillOneKid = false;
            }

            state.loadingOneKid = false;
            // chuyển trang sẽ xóa state của danh sách id học sinh về ban đầu
            state.idKidList = "";
        },
        [GET_STATUS_EXCEL](state) {
            state.updateStatus = !state.updateStatus
        },
        [LOADING_DATA](state) {
            state.loadingData = true
        },
        [CHECK_RESPONSE](state, payload) {
            state.checkResponse = payload
            state.loadingData = false;
            if (state.checkResponse === false) {
                state.responseFilterList = []
                state.totalPages = "";
            }
        },
        setCheckRevenue(state) {
            state.checkRevenue = false
            for (let i = 1; i <= 20; i++) {
                if (state.listKT[`nameKt${i}`] !== `Khoản thu ${i}` && state.dataSearch.collectionTurn !== null) {
                    state.checkRevenue = true
                    break
                }
            }
        }
    },
    actions: {
        [INIT_KIDS_DATA]({commit}, filterParams) {
            commit(RESPONSE_FILTER_LIST, []);
            return groupExcelService
                .filterListKids(filterParams)
                .then((data) => {
                    commit(UPDATE_DATA_SEARCH, filterParams);
                    if (data) {
                        commit(INIT_KIDS_DATA, data.data);
                        commit(SET_LIST_KT, data.data.listNameKT);
                        commit(CHECK_RESPONSE, true)
                    }
                    commit('setCheckRevenue')
                })
                .catch(() => {
                    commit(CHECK_RESPONSE, false)
                    commit(NO_DATA_RESPONSE);
                });
        },
        [PREVIEW_BILL_DATA]({commit}, payload) {
            const {param, printStyle} = payload
            return groupExcelService
                .previewTemplateForGroup(param)
                .then((res) => {
                    if (res) {
                        commit(PREVIEW_BILL_DATA, {data: res.data, printStyle});
                    }
                })
        },

        [PREVIEW_INFO_FOR_ONE_KID]({commit}, preview_data) {
            return groupExcelService
                .previewTemplateForGroup(preview_data)
                .then((res) => {
                    if (res) {
                        commit(PREVIEW_INFO_FOR_ONE_KID, res.data);
                    }
                })
        },
    },
};
