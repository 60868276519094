// classStore.js
// Lấy danh sách lớp từ server lưu vào state để sử dụng trong các thành phần component
import MaClassService from "@/services/MaClassService";


export const kidsStore = {
    namespaced: true,
    state: {
        searchAllClassInSchool: [],
        pagination: {
            pageNumber: 1,
            maxPageItem: 50,
            classNameSearch: ''
        },
    },
    getters: {
        searchAllClassInSchool: (state) => state.searchAllClassInSchool,
    },
    mutations: {
        SET_SearchAllClassInSchool(state, searchAllClassInSchool) {
            state.searchAllClassInSchool = searchAllClassInSchool;
        },
        SET_Pagination(state, pagination) {
            state.pagination.pageNumber = pagination.pageNumber;
            state.pagination.maxPageItem = pagination.maxPageItem;
            state.pagination.classNameSearch = pagination.classNameSearch;
        }
    },
    actions: {
        // lấy dữ liệu lớp của tất cả lớp trong trường tạo ra 'searchAllClassInSchool'
        fetchDataSearchAllClassInSchool({commit}, payload) {
            return MaClassService.searchClassNew(
                payload.pageNumber,
                payload.maxPageItem,
                payload.classNameSearch
            )
                .then(response => {
                    commit('SET_SearchAllClassInSchool', response.data.data.responseList);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
};
