var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-main" },
    [
      _c(
        "el-menu",
        {
          staticClass: "el-menu-vertical-demo",
          attrs: {
            collapse: _vm.getIsOpenCloseManu,
            "background-color": "#304156",
            "text-color": "#bfcbd9",
            "active-text-color": "#409EFF",
            "unique-opened": true,
          },
        },
        [
          _c(
            "router-link",
            { staticClass: "router-link", attrs: { to: "/dashboard" } },
            [
              _c("el-menu-item", { attrs: { index: "1" } }, [
                _c("i", { staticClass: "el-icon-s-home" }),
                _c(
                  "span",
                  {
                    staticClass: "onekids-title",
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [_vm._v("SUPPER PLUS")]
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-submenu",
            { attrs: { index: "2" } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "el-icon-user" }),
                _c("span", [_vm._v("Quản lý nhân sự")]),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/employee/employees-plus" },
                },
                [
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "2-1" },
                      on: {
                        click: function ($event) {
                          return _vm.changeBreadcrumbMethod(
                            "employee-employees-plus"
                          )
                        },
                      },
                    },
                    [_vm._v("Quản lý Plus\n          ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-submenu",
            { attrs: { index: "3" } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "el-icon-setting" }),
                _c("span", [_vm._v("Cấu hình nhà trường")]),
              ]),
              _c(
                "router-link",
                { staticClass: "router-link", attrs: { to: "/school/config" } },
                [
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "3-1" },
                      on: {
                        click: function ($event) {
                          return _vm.changeBreadcrumbMethod("school-config")
                        },
                      },
                    },
                    [_vm._v("Cấu hình chung\n          ")]
                  ),
                ],
                1
              ),
              _c(
                "router-link",
                { staticClass: "router-link", attrs: { to: "/school/camera" } },
                [
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "3-2" },
                      on: {
                        click: function ($event) {
                          return _vm.changeBreadcrumbMethod("school-camera")
                        },
                      },
                    },
                    [_vm._v("Cấu hình Camera\n          ")]
                  ),
                ],
                1
              ),
              _c(
                "router-link",
                { staticClass: "router-link", attrs: { to: "/school/media" } },
                [
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "3-3" },
                      on: {
                        click: function ($event) {
                          return _vm.changeBreadcrumbMethod("school-media")
                        },
                      },
                    },
                    [_vm._v("Cấu hình Media\n          ")]
                  ),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/school/birthday-sample" },
                },
                [
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "3-4" },
                      on: {
                        click: function ($event) {
                          return _vm.changeBreadcrumbMethod(
                            "school-birthday-sample"
                          )
                        },
                      },
                    },
                    [_vm._v("Cấu hình gửi tin\n          ")]
                  ),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/school/notification-manage" },
                },
                [
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "3-5" },
                      on: {
                        click: function ($event) {
                          return _vm.changeBreadcrumbMethod(
                            "school-notification-manage"
                          )
                        },
                      },
                    },
                    [_vm._v("Tự động gửi tin\n          ")]
                  ),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/school/config-fuction" },
                },
                [
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "3-6" },
                      on: {
                        click: function ($event) {
                          return _vm.changeBreadcrumbMethod(
                            "school-config-fuction"
                          )
                        },
                      },
                    },
                    [_vm._v("Ẩn hiển chức năng\n          ")]
                  ),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/school/permission-manage" },
                },
                [
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "3-7" },
                      on: {
                        click: function ($event) {
                          return _vm.changeBreadcrumbMethod(
                            "school-permission-manage"
                          )
                        },
                      },
                    },
                    [_vm._v("Quản lý quyền\n          ")]
                  ),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/school/permission-manage-for-supperPlus" },
                },
                [
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "3-8" },
                      on: {
                        click: function ($event) {
                          return _vm.changeBreadcrumbMethod(
                            "school-permission-manage-for-supperPlus"
                          )
                        },
                      },
                    },
                    [_vm._v("Tạo dữ liệu chung\n          ")]
                  ),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/school/config-template-app" },
                },
                [
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "3-9" },
                      on: {
                        click: function ($event) {
                          return _vm.changeBreadcrumbMethod(
                            "config-template-app"
                          )
                        },
                      },
                    },
                    [_vm._v("Cấu hình Template App\n          ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }