import {RELOAD_DASHBOARD} from "@/constant/actions";

export const layout = {
    namespaced: true,
    state: {
        updateDashBoard: false,
        isOpenClose: false,
        breadcrumbList: [],
    },
    mutations: {
        SET_IS_OPEN_MENU(state, isOpenClose) {
            state.isOpenClose = isOpenClose
        },
        SET_BREADCRUMB_MUTATIONS(state, bcList) {
            state.breadcrumbList = bcList
        },
        [RELOAD_DASHBOARD](state) {
            state.updateDashBoard = !state.updateDashBoard
        }
    },

    actions: {
        setIsOpenCloseMenu({commit}, isOpenClose) {
            setTimeout(() => {
                commit('SET_IS_OPEN_MENU', isOpenClose)
            }, 10)
        },
        setBreadcrumbActions({commit}, bcList) {
            setTimeout(() => {
                commit('SET_BREADCRUMB_MUTATIONS', bcList)
            }, 10);
        }

    },
    getters: {
        getIsOpenClose: state => {
            return state.isOpenClose
        },
        getBreadcrumbList: state => {
            return state.breadcrumbList
        }
    }

}