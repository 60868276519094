<template>
  <div  class="menu-main">
    <el-menu
        class="el-menu-vertical-demo"
        background-color="#304156"
        text-color="#bfcbd9"
        active-text-color="#409EFF"
        :unique-opened="true"
    >
      <router-link to="/" class="router-link">
        <el-menu-item index="1">
          <i class="el-icon-s-home"></i>
          <span slot="title" class="onekids-title">ONEKIDS ADMIN</span>
        </el-menu-item>
      </router-link>
      <span>
      <el-submenu index="2" v-if="getAppTypeUserLogin == appType.SUPPER_SYSTEM ">
        <template slot="title">
          <i class="el-icon-notebook-2" />
          <span>Quản lý nhân viên</span>
        </template>
        <router-link to="/master/admin/list" class="router-link">
          <el-menu-item
              index="2-1"
              @click="changeBreadcrumbMethod('master-admin-list')"
          >Danh sách nhân viên</el-menu-item
          >
        </router-link>
        <router-link to="/master/admin/school" class="router-link">
          <el-menu-item
              index="2-2"
              @click="changeBreadcrumbMethod('master-admin-school')"
          >Phân quản lý trường</el-menu-item
          >
        </router-link>
      </el-submenu>
      <el-submenu index="3" v-if="getAppTypeUserLogin == appType.SUPPER_SYSTEM || getAppTypeUserLogin == appType.SYSTEM">
        <template slot="title">
          <i class="el-icon-notebook-2" />
          <span>Quản lý đại lý</span>
        </template>
        <router-link to="/master/agent/list" class="router-link">
          <el-menu-item
              index="3-1"
              @click="changeBreadcrumbMethod('master-agent-list')"
          >Danh sách đại lý</el-menu-item
          >
        </router-link>
        <router-link to="/master/agent/account" class="router-link">
          <el-menu-item
              index="3-2"
              @click="changeBreadcrumbMethod('master-agent-account')"
          >Tài khoản đại lý</el-menu-item
          >
        </router-link>
      </el-submenu>
      <el-submenu index="4">
        <template slot="title">
          <i class="el-icon-reading" />
          <span>Quản lý trường</span>
        </template>
        <router-link to="/master/school-agent/list" class="router-link">
          <el-menu-item
              index="4-1"
              @click="changeBreadcrumbMethod('master-school-agent-list')"
          >Danh sách trường</el-menu-item
          >
        </router-link>
        <router-link to="/master/school-agent/account-school" class="router-link">
          <el-menu-item
              index="4-2"
              @click="changeBreadcrumbMethod('master-school-agent-account-school')"
          >Tài khoản nhà trường</el-menu-item
          >
        </router-link>
        <router-link to="/master/school-agent/employee-in-school" class="router-link">
          <el-menu-item
              index="4-3"
              @click="changeBreadcrumbMethod('master-school-agent-employee-in-school')"
          >Danh sách nhân sự</el-menu-item
          >
        </router-link>
                <router-link to="/master/school-agent/student-in-school" class="router-link">
          <el-menu-item
              index="4-4"
              @click="changeBreadcrumbMethod('master-school-agent-student-in-school')"
          >Danh sách học sinh</el-menu-item
          >
        </router-link>
      </el-submenu>
      <el-submenu index="5" v-if="getAppTypeUserLogin == appType.SUPPER_SYSTEM || getAppTypeUserLogin == appType.SYSTEM">
        <template slot="title">
          <i class="el-icon-user" />
          <span>Quản lý BrandName</span>
        </template>
        <router-link to="/brandname/brand" class="router-link">
          <el-menu-item
              index="5-1"
              @click="changeBreadcrumbMethod('brandname-brand')"
          >Cấu hình kết nối</el-menu-item
          >
        </router-link>
        <router-link to="/brandname/agent" class="router-link">
          <el-menu-item
              index="5-2"
              @click="changeBreadcrumbMethod('brandname-agent')"
          >Thiết lập Brand đại lý</el-menu-item
          >
        </router-link>
        <router-link to="/brandname/school-brand" class="router-link">
          <el-menu-item
              index="5-3"
              @click="changeBreadcrumbMethod('brandname-school-brand')"
          >Thiết lập Brand Trường</el-menu-item
          >
        </router-link>
      </el-submenu>
      <el-submenu index="6" v-if="getAppTypeUserLogin == appType.SUPPER_SYSTEM || getAppTypeUserLogin == appType.SYSTEM">
        <template slot="title">
          <i class="el-icon-user-solid" />
          <span>Quản lý thông báo</span>
        </template>
        <router-link to="/master/notify/news-onekids" class="router-link">
          <el-menu-item
              index="6-1"
              @click="changeBreadcrumbMethod('master-notify-news-onekids')"
          >Tin tức Onekids</el-menu-item
          >
        </router-link>
        <router-link to="/master/notify/notify-onekids" class="router-link">
          <el-menu-item
              index="6-2"
              @click="changeBreadcrumbMethod('master-notify-notify-onekids')"
          >Thông báo Onekids</el-menu-item
          >
        </router-link>
        <router-link to="/master/notify/history-sms" class="router-link">
          <el-menu-item
              index="6-3"
              @click="changeBreadcrumbMethod('master-notify-history-sms')"
          >Lịch sử nhắn SMS</el-menu-item
          >
        </router-link>
          <router-link to="/master/notify/feedback-onekids" class="router-link">
          <el-menu-item
              index="6-4"
              @click="changeBreadcrumbMethod('master-notify-feedback-onekids')"
          >Góp ý Onekids</el-menu-item
          >
        </router-link>
      </el-submenu>
      <el-submenu index="7" v-if="getAppTypeUserLogin == appType.SUPPER_SYSTEM">
        <template slot="title">
          <i class="el-icon-s-custom" />
          <span>Quản lý tài khoản</span>
        </template>
        <router-link to="/master/user/account" class="router-link">
          <el-menu-item
              index="7-1"
              @click="changeBreadcrumbMethod('master-user-account')"
          >Tài khoản người dùng</el-menu-item
          >
        </router-link>
      </el-submenu>
      <el-submenu index="8" v-if="getAppTypeUserLogin == appType.SUPPER_SYSTEM">
        <template slot="title">
          <i class="el-icon-setting" />
          <span>Cấu hình hệ thống</span>
        </template>
        <router-link to="/master/system-config/attendance-sample" class="router-link">
          <el-menu-item
              index="8-1"
              @click="changeBreadcrumbMethod('master-system-config-attendance-sample')"
          >Mẫu điểm danh, lời chúc</el-menu-item
          >
        </router-link>
        <router-link to="/master/system-config/evaluate-sample" class="router-link">
          <el-menu-item
              index="8-2"
              @click="changeBreadcrumbMethod('master-system-config-evaluate-sample')"
          >Mẫu nhận xét</el-menu-item
          >
        </router-link>
        <router-link to="/master/system-config/school" class="router-link">
          <el-menu-item
              index="8-3"
              @click="changeBreadcrumbMethod('master-system-config-school')"
          >Cấu hình nhà trường</el-menu-item
          >
        </router-link>
         <router-link to="/master/system-config/system-setting" class="router-link">
          <el-menu-item
              index="8-4"
              @click="changeBreadcrumbMethod('master-system-config-system-setting')"
          >Thiết lập hệ thống</el-menu-item
          >
        </router-link>
          <router-link to="/master/system-config/web-system-title" class="router-link">
          <el-menu-item
              index="8-5"
              @click="changeBreadcrumbMethod('master-system-config-web-system-title')"
          >Thiết lập tiêu đề nội dung</el-menu-item
          >
        </router-link>
          <router-link to="/master/system-config/api" class="router-link">
          <el-menu-item
              index="8-6"
              @click="changeBreadcrumbMethod('master-system-config-api')"
          >Cập nhật quyền</el-menu-item
          >
        </router-link>
          <router-link to="/master/system-config/support" class="router-link">
          <el-menu-item
              index="8-7"
              @click="changeBreadcrumbMethod('master-system-config-support')"
          >Thiết lập hỗ trợ</el-menu-item
          >
        </router-link>
                <router-link to="/master/system-config/template" class="router-link">
          <el-menu-item
              index="8-8"
              @click="changeBreadcrumbMethod('master-system-config-template')"
          >Thiết lập giao diện</el-menu-item
          >
        </router-link>
          <router-link to="/master/system-config/orther" class="router-link">
          <el-menu-item
              index="8-9"
              @click="changeBreadcrumbMethod('master-system-config-orther')"
          >Thiết lập khác</el-menu-item
          >
        </router-link>
      </el-submenu>
      <el-submenu index="9" v-if="getAppTypeUserLogin == appType.SUPPER_SYSTEM">
        <template slot="title">
          <i class="el-icon-s-shop" />
          <span>Dữ liệu hệ thống</span>
        </template>
        <router-link to="/master/other/create-data-system" class="router-link">
          <el-menu-item
              index="9-1"
              @click="changeBreadcrumbMethod('master-other-create-data-system')"
          >Tạo dữ liệu chung</el-menu-item
          ></router-link>

          <router-link to="/master/other/manage-request-system" class="router-link">
          <el-menu-item
              index="9-2"
              @click="changeBreadcrumbMethod('master-other-manage-request-system')"
          >Quản lý truy cập</el-menu-item
          ></router-link>

         <router-link to="/master/other/manage-data-system" class="router-link">
          <el-menu-item
              index="9-3"
              @click="changeBreadcrumbMethod('master-other-manage-data-system')"
          >Quản lý dữ liệu</el-menu-item
          ></router-link>
        <router-link to="/master/other/manage-operation-history" class="router-link">
          <el-menu-item
              index="9-4"
              @click="changeBreadcrumbMethod('master-other-manage-operation-history')"
          >Lịch sử thao tác</el-menu-item
          ></router-link>
      </el-submenu>

    </span>

    </el-menu>
  </div>
</template>
<script>
import {appType} from "@/constant/listCommon";

export default {
  data(){
    return{
      appType,
      breadcrumbDataList: [
        /**
         * menu dành cho nhân viên công ty, admin
         */
        //quản lý nhân viên
        {
          key: "master-admin-list",
          value: [{ url: "/master/admin/list", name: "Danh sách nhân viên" }],
        },
        {
          key: "master-admin-school",
          value: [{ url: "/master/admin/school", name: "Phân quản lý trường" }],
        },
        //quản lý đại lý
        {
          key: "master-agent-list",
          value: [{ url: "/master/agent/list", name: "Danh sách đại lý" }],
        },
        {
          key: "master-agent-account",
          value: [{ url: "/master/agent/account", name: "Tài khoản đại lý" }],
        },
        //quản lý trường
        {
          key: "master-school-agent-list",
          value: [{ url: "/master/school-agent/list", name: "Danh sách trường" }],
        },
        {
          key: "master-school-agent-account-school",
          value: [
            {
              url: "/master/school-agent/account-school",
              name: "Tài khoản nhà trường",
            },
          ],
        },
        {
          key: "master-school-agent-employee-in-school",
          value: [
            {
              url: "/master/school-agent/employee-in-school",
              name: "Danh sách nhân sự",
            },
          ],
        },
        {
          key: "master-school-agent-student-in-school",
          value: [
            {
              url: "/master/school-agent/student-in-school",
              name: "Danh sách học sinh",
            },
          ],
        },
        //quản lý brand name
        {
          key: "brandname-brand",
          value: [{ url: "/brandname/brand", name: "Cấu hình kết nối" }],
        },
        {
          key: "brandname-agent",
          value: [{ url: "/brandname/agent", name: "Thiết lập Brand đại lý" }],
        },
        {
          key: "brandname-school-brand",
          value: [{ url: "/brandname/school-brand", name: "Thiết lập Brand Trường" }],
        },
        //quản lý thông báo
        {
          key: "master-notify-news-onekids",
          value: [{ url: "/master/notify/news-onekids", name: "Tin tức Onekids" }],
        },
        {
          key: "master-notify-notify-onekids",
          value: [{ url: "/master/notify/notify-onekids", name: "Thông báo Onekids" }],
        },
        {
          key: "master-notify-history-sms",
          value: [{ url: "/master/notify/history-sms", name: "Lịch sử nhắn SMS" }],
        },
        {
          key: "master-notify-feedback-onekids",
          value: [{ url: "/master/notify/feedback-onekids", name: "Góp ý Onekids" }],
        },
        //thông báo nội bộ
        {
          key: "master-notification-notify",
          value: [{ url: "/master/notification/notify", name: "Cấu hình gửi tin" }],
        },
        {
          key: "master_internal_notification_plus",
          value: [
            {
              url: "/master/notification/internal-notification",
              name: "Thông báo nội bộ",
            },
          ],
        },
        //quản lý tài khoản
        {
          key: "master-user-account",
          value: [{ url: "/master/user/account", name: "Tài khoản người dùng" }],
        },
        //cấu hình hệ thống
        {
          key: "master-system-config-attendance-sample",
          value: [
            {
              url: "/master/system-config/attendance-sample",
              name: "Mẫu điểm danh, lời chúc",
            },
          ],
        },
        {
          key: "master-system-config-evaluate-sample",
          value: [
            {
              url: "/master/system-config/evaluate-sample",
              name: "Mẫu nhận xét",
            },
          ],
        },
        {
          key: "master-system-config-school",
          value: [
            {
              url: "/master/system-config/school",
              name: "Cấu hình nhà trường",
            },
          ],
        },
        {
          key: "master-system-config-system-setting",
          value: [
            {
              url: "/master/system-config/system-setting",
              name: "Thiết lập hệ thống",
            },
          ],
        },
        {
          key: "master-system-config-web-system-title",
          value: [
            {
              url: "/master/system-config/web-system-title",
              name: "Thiết lập tiêu đề, nội dung",
            },
          ],
        },
        {
          key: "master-system-config-api",
          value: [{ url: "/master/system-config/api", name: "Cập nhật quyền" }],
        },
        {
          key: "master-system-config-support",
          value: [{ url: "/master/system-config/support", name: "Thiết lập hỗ trợ" }],
        },
        {
          key: "master-system-config-orther",
          value: [{ url: "/master/system-config/other", name: "Thiết lập khác" }],
        },
        {
          key: "master-system-config-template",
          value: [{ url: "/master/system-config/template", name: "Thiết lập giao diện" }],
        },
        //tạo dữ liệu hệ thống
        {
          key: "master-other-create-data-system",
          value: [{url: "/master/other/create-data-system", name: "Tạo dữ liệu chung"}],
        },
          // Xem lượng truy cập BE
        {
          key: "master-other-manage-request-system",
          value: [{url: "/master/other/manage-request-system", name: "Lượng truy cập hệ thống "}],
        },
          // Xóa dữ liệu lớn
        {
          key: "master-other-manage-data-system",
          value: [{url: "/master/other/manage-data-system", name: "Quản lý các bảng dung lượng lớn"}],
        },
        //Lịch sử thao tác
        {
          key: "master-other-manage-operation-history",
          value: [{url: "/master/other/manage-operation-history", name: "Lịch sử thao tác"}],
        },
      ]
    }
  },

  computed: {
    getIsOpenCloseMenu() {
      return this.$store.getters["layout/getIsOpenClose"];
    },
    getAppTypeUserLogin() {
      return this.$store.state.auth.user.appType;
    },
  },
  methods:{
    changeBreadcrumbMethod(val) {
      let selectUrl = this.breadcrumbDataList.filter((x) => x.key === val);
      if (selectUrl.length > 0) {
        let dataList = selectUrl[0].value;
        sessionStorage.setItem("breadcrumbList", JSON.stringify(dataList));
        this.$store.dispatch("layout/setBreadcrumbActions", dataList);
      }
    },

  },

}
</script>
<style lang="scss" scoped>
.menu-main {
  min-height: 950px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 210px;
  min-height: 700px;
  font-family: Arial,serif;
}
.router-link {
  text-decoration: none;
}
.el-menu-item {
  background-color: #1f2d3d !important;
}
.el-menu-item:hover {
  background-color: #49575f !important;
}
/deep/.el-message-box {
  display: inline-block;
  vertical-align: top !important;
  width: 420px;
  padding-bottom: 10px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  font-size: 18px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
/deep/.el-message-box__wrapper::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: top !important;
}
.el-menu {
  margin-bottom: 20px;
}
.onekids-title {
  font-weight: bold;
}
</style>