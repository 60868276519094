// classStore.js
// Lấy danh sách lớp từ server lưu vào state để sử dụng trong các thành phần component
import MaClassService from "@/services/MaClassService";
import TeacherService from "@/services/TeacherService/TeacherService";
import FnFeesService from "@/services/FinanceKids/FnFeesService";

export const classStore = {
    namespaced: true,
    state: {
        classOfGradeList: [],
        classListCommon: [],
        classInTeacher: [],
        classSearchPackage: [],
    },
    getters: {
        classOfGradeList: (state) => state.classOfGradeList,
        classListCommon: (state) => state.classListCommon,
        classInTeacher: (state) => state.classInTeacher,
        classSearchPackage: (state) => state.classSearchPackage,
    },
    mutations: {
        SET_ClassOfGradeList(state, classOfGradeList) {
            state.classOfGradeList = classOfGradeList;
        },
        SET_ClassListCommon(state, classListCommon) {
            state.classListCommon = classListCommon;
        },
        SET_ClassInTeacher(state, classInTeacher) {
            state.classInTeacher = classInTeacher;
        },
        SET_ClassSearchPackage(state, classSearchPackage) {
            state.classSearchPackage = classSearchPackage;
        },
    },
    actions: {
        // lấy dữ liệu class trong khối từ serve tạo ra 'classOfGradeList'
        fetchDataClassOfGradeList({commit}, idGrade) {
            return MaClassService.getClassInGrade(idGrade)
                .then(response => {
                    commit('SET_ClassOfGradeList', response.data.data);

                })
                .catch(error => {
                    console.error(error);
                });
        },
        // lấy tất cả các lớp tạo ra 'classListCommon'
        fetchDataClassListCommon({commit}) {
            return MaClassService.getAllClassCommon()
                .then(response => {
                    commit('SET_ClassListCommon', response.data.data);
                })
                .catch(error => {
                    console.error(error);
                });
        },
        // lấy dữ liệu lớp của từng giáo viên tạo ra 'classInTeacher'
        fetchDataClassInTeacher({commit}) {
            return TeacherService.getClassInTeacher()
                .then(response => {
                    commit('SET_ClassInTeacher', response.data.data);
                })
                .catch(error => {
                    console.error(error);
                });
        },
        // Lấy dữ liệu tất cả class danh sách khoản tạo ra 'classSearchPackage'
        fetchDataClassSearchPackage({commit}, className) {
            return FnFeesService.searchClassPackage(className)
                .then(response => {
                    commit('SET_ClassSearchPackage', response.data.data);
                }).catch(error => {
                    console.log(error);
                });
        }

    }
};
