var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Thay đổi trường",
            visible: _vm.dialogVisible,
            width: "900px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingData,
                  expression: "loadingData",
                },
              ],
              ref: "multipleTable",
              attrs: {
                "empty-text": _vm.textTable,
                "element-loading-text": _vm.$tableLoading,
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255,255,255, 0)",
                data: _vm.responseList,
                "highlight-current-row": "",
                "header-cell-style": _vm.tableHeaderColor,
                "max-height": _vm.$tableMaxHeightSmallMini,
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "STT",
                  width: "50",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "fullName", label: "Họ tên", width: "170" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "phone",
                  align: "center",
                  label: "Số điện thoại",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "school.id",
                  align: "center",
                  label: "Code",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "school.schoolName", label: "Tên trường" },
              }),
              _c("el-table-column", {
                attrs: { width: "140", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: scope.row.school.status },
                            on: {
                              change: function ($event) {
                                return _vm.changeSchoolMethod(scope.row)
                              },
                            },
                            model: {
                              value: _vm.checkSchool,
                              callback: function ($$v) {
                                _vm.checkSchool = $$v
                              },
                              expression: "checkSchool",
                            },
                          },
                          [_vm._v("Chọn")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialogByButton()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    size: "medium",
                    loading: _vm.loadingButton,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.save")))]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }