var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "dynamicValidateForm",
      staticClass: "demo-dynamic",
      attrs: { model: _vm.dynamicValidateForm, "label-width": "120px" },
    },
    [
      _c(
        "el-form-item",
        {
          attrs: {
            prop: "email",
            label: "Email",
            rules: [
              {
                required: true,
                message: "Please input email address",
                trigger: "blur",
              },
              {
                type: "email",
                message: "Please input correct email address",
                trigger: ["blur", "change"],
              },
            ],
          },
        },
        [
          _c("el-input", {
            model: {
              value: _vm.dynamicValidateForm.email,
              callback: function ($$v) {
                _vm.$set(_vm.dynamicValidateForm, "email", $$v)
              },
              expression: "dynamicValidateForm.email",
            },
          }),
        ],
        1
      ),
      _vm._l(_vm.dynamicValidateForm.domains, function (domain, index) {
        return _c(
          "el-form-item",
          {
            key: domain.key,
            attrs: {
              label: "Domain" + index,
              prop: "domains." + index + ".value",
              rules: {
                required: true,
                message: "domain can not be null",
                trigger: "blur",
              },
            },
          },
          [
            _c("el-input", {
              model: {
                value: domain.value,
                callback: function ($$v) {
                  _vm.$set(domain, "value", $$v)
                },
                expression: "domain.value",
              },
            }),
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.removeDomain(domain)
                  },
                },
              },
              [_vm._v("Delete")]
            ),
          ],
          1
        )
      }),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("dynamicValidateForm")
                },
              },
            },
            [_vm._v("Submit")]
          ),
          _c("el-button", { on: { click: _vm.addDomain } }, [
            _vm._v("New domain"),
          ]),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resetForm("dynamicValidateForm")
                },
              },
            },
            [_vm._v("Reset")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }