var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-main" },
    [
      _c(
        "el-menu",
        {
          staticClass: "el-menu-vertical-demo",
          attrs: {
            "background-color": "#304156",
            "text-color": "#bfcbd9",
            "active-text-color": "#409EFF",
            "unique-opened": true,
          },
        },
        [
          _c(
            "router-link",
            { staticClass: "router-link", attrs: { to: "/" } },
            [
              _c("el-menu-item", { attrs: { index: "1" } }, [
                _c("i", { staticClass: "el-icon-s-home" }),
                _c(
                  "span",
                  {
                    staticClass: "onekids-title",
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [_vm._v("ONEKIDS ADMIN")]
                ),
              ]),
            ],
            1
          ),
          _c(
            "span",
            [
              _vm.getAppTypeUserLogin == _vm.appType.SUPPER_SYSTEM
                ? _c(
                    "el-submenu",
                    { attrs: { index: "2" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("i", { staticClass: "el-icon-notebook-2" }),
                        _c("span", [_vm._v("Quản lý nhân viên")]),
                      ]),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/master/admin/list" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "2-1" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-admin-list"
                                  )
                                },
                              },
                            },
                            [_vm._v("Danh sách nhân viên")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/master/admin/school" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "2-2" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-admin-school"
                                  )
                                },
                              },
                            },
                            [_vm._v("Phân quản lý trường")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.getAppTypeUserLogin == _vm.appType.SUPPER_SYSTEM ||
              _vm.getAppTypeUserLogin == _vm.appType.SYSTEM
                ? _c(
                    "el-submenu",
                    { attrs: { index: "3" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("i", { staticClass: "el-icon-notebook-2" }),
                        _c("span", [_vm._v("Quản lý đại lý")]),
                      ]),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/master/agent/list" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "3-1" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-agent-list"
                                  )
                                },
                              },
                            },
                            [_vm._v("Danh sách đại lý")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/master/agent/account" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "3-2" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-agent-account"
                                  )
                                },
                              },
                            },
                            [_vm._v("Tài khoản đại lý")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "el-submenu",
                { attrs: { index: "4" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("i", { staticClass: "el-icon-reading" }),
                    _c("span", [_vm._v("Quản lý trường")]),
                  ]),
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: { to: "/master/school-agent/list" },
                    },
                    [
                      _c(
                        "el-menu-item",
                        {
                          attrs: { index: "4-1" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "master-school-agent-list"
                              )
                            },
                          },
                        },
                        [_vm._v("Danh sách trường")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: { to: "/master/school-agent/account-school" },
                    },
                    [
                      _c(
                        "el-menu-item",
                        {
                          attrs: { index: "4-2" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "master-school-agent-account-school"
                              )
                            },
                          },
                        },
                        [_vm._v("Tài khoản nhà trường")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: { to: "/master/school-agent/employee-in-school" },
                    },
                    [
                      _c(
                        "el-menu-item",
                        {
                          attrs: { index: "4-3" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "master-school-agent-employee-in-school"
                              )
                            },
                          },
                        },
                        [_vm._v("Danh sách nhân sự")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: { to: "/master/school-agent/student-in-school" },
                    },
                    [
                      _c(
                        "el-menu-item",
                        {
                          attrs: { index: "4-4" },
                          on: {
                            click: function ($event) {
                              return _vm.changeBreadcrumbMethod(
                                "master-school-agent-student-in-school"
                              )
                            },
                          },
                        },
                        [_vm._v("Danh sách học sinh")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm.getAppTypeUserLogin == _vm.appType.SUPPER_SYSTEM ||
              _vm.getAppTypeUserLogin == _vm.appType.SYSTEM
                ? _c(
                    "el-submenu",
                    { attrs: { index: "5" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("i", { staticClass: "el-icon-user" }),
                        _c("span", [_vm._v("Quản lý BrandName")]),
                      ]),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/brandname/brand" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "5-1" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "brandname-brand"
                                  )
                                },
                              },
                            },
                            [_vm._v("Cấu hình kết nối")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/brandname/agent" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "5-2" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "brandname-agent"
                                  )
                                },
                              },
                            },
                            [_vm._v("Thiết lập Brand đại lý")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/brandname/school-brand" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "5-3" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "brandname-school-brand"
                                  )
                                },
                              },
                            },
                            [_vm._v("Thiết lập Brand Trường")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.getAppTypeUserLogin == _vm.appType.SUPPER_SYSTEM ||
              _vm.getAppTypeUserLogin == _vm.appType.SYSTEM
                ? _c(
                    "el-submenu",
                    { attrs: { index: "6" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("i", { staticClass: "el-icon-user-solid" }),
                        _c("span", [_vm._v("Quản lý thông báo")]),
                      ]),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/master/notify/news-onekids" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "6-1" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-notify-news-onekids"
                                  )
                                },
                              },
                            },
                            [_vm._v("Tin tức Onekids")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/master/notify/notify-onekids" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "6-2" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-notify-notify-onekids"
                                  )
                                },
                              },
                            },
                            [_vm._v("Thông báo Onekids")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/master/notify/history-sms" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "6-3" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-notify-history-sms"
                                  )
                                },
                              },
                            },
                            [_vm._v("Lịch sử nhắn SMS")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/master/notify/feedback-onekids" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "6-4" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-notify-feedback-onekids"
                                  )
                                },
                              },
                            },
                            [_vm._v("Góp ý Onekids")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.getAppTypeUserLogin == _vm.appType.SUPPER_SYSTEM
                ? _c(
                    "el-submenu",
                    { attrs: { index: "7" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("i", { staticClass: "el-icon-s-custom" }),
                        _c("span", [_vm._v("Quản lý tài khoản")]),
                      ]),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/master/user/account" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "7-1" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-user-account"
                                  )
                                },
                              },
                            },
                            [_vm._v("Tài khoản người dùng")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.getAppTypeUserLogin == _vm.appType.SUPPER_SYSTEM
                ? _c(
                    "el-submenu",
                    { attrs: { index: "8" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("i", { staticClass: "el-icon-setting" }),
                        _c("span", [_vm._v("Cấu hình hệ thống")]),
                      ]),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: {
                            to: "/master/system-config/attendance-sample",
                          },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "8-1" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-system-config-attendance-sample"
                                  )
                                },
                              },
                            },
                            [_vm._v("Mẫu điểm danh, lời chúc")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: {
                            to: "/master/system-config/evaluate-sample",
                          },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "8-2" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-system-config-evaluate-sample"
                                  )
                                },
                              },
                            },
                            [_vm._v("Mẫu nhận xét")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/master/system-config/school" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "8-3" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-system-config-school"
                                  )
                                },
                              },
                            },
                            [_vm._v("Cấu hình nhà trường")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/master/system-config/system-setting" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "8-4" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-system-config-system-setting"
                                  )
                                },
                              },
                            },
                            [_vm._v("Thiết lập hệ thống")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: {
                            to: "/master/system-config/web-system-title",
                          },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "8-5" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-system-config-web-system-title"
                                  )
                                },
                              },
                            },
                            [_vm._v("Thiết lập tiêu đề nội dung")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/master/system-config/api" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "8-6" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-system-config-api"
                                  )
                                },
                              },
                            },
                            [_vm._v("Cập nhật quyền")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/master/system-config/support" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "8-7" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-system-config-support"
                                  )
                                },
                              },
                            },
                            [_vm._v("Thiết lập hỗ trợ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/master/system-config/template" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "8-8" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-system-config-template"
                                  )
                                },
                              },
                            },
                            [_vm._v("Thiết lập giao diện")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/master/system-config/orther" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "8-9" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-system-config-orther"
                                  )
                                },
                              },
                            },
                            [_vm._v("Thiết lập khác")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.getAppTypeUserLogin == _vm.appType.SUPPER_SYSTEM
                ? _c(
                    "el-submenu",
                    { attrs: { index: "9" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("i", { staticClass: "el-icon-s-shop" }),
                        _c("span", [_vm._v("Dữ liệu hệ thống")]),
                      ]),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/master/other/create-data-system" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "9-1" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-other-create-data-system"
                                  )
                                },
                              },
                            },
                            [_vm._v("Tạo dữ liệu chung")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/master/other/manage-request-system" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "9-2" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-other-manage-request-system"
                                  )
                                },
                              },
                            },
                            [_vm._v("Quản lý truy cập")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: "/master/other/manage-data-system" },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "9-3" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-other-manage-data-system"
                                  )
                                },
                              },
                            },
                            [_vm._v("Quản lý dữ liệu")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: {
                            to: "/master/other/manage-operation-history",
                          },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "9-4" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBreadcrumbMethod(
                                    "master-other-manage-operation-history"
                                  )
                                },
                              },
                            },
                            [_vm._v("Lịch sử thao tác")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }