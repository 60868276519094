// gradeModule.js
//luu cac khối trong truờng
// Lấy danh sách khối từ server lưu vào state để sử dụng trong các thành phần component
import GradeService from "@/services/GradeService";

export const gradeModule = {
    namespaced: true,
    state: {
        gradeOfSchoolList: [],
        getAllGradeInSchoolList: [],
        pageNumber: 1,
        maxPageItem: 25,
    },
    getters: {
        gradeOfSchoolList: (state) => state.gradeOfSchoolList,
        getAllGradeInSchoolList: (state) => state.getAllGradeInSchoolList,
    },
    mutations: {
        SET_GradeOfSchoolList(state, gradeOfSchoolList) {
            state.gradeOfSchoolList = gradeOfSchoolList;
        },
        SET_GetAllGradeInSchoolList(state, getAllGradeInSchoolList) {
            state.getAllGradeInSchoolList = getAllGradeInSchoolList;
        },
        SET_PageNumber(state, pageNumber) {
            state.pageNumber = pageNumber;
        },
        SET_MaxPageItem(state, maxPageItem) {
            state.maxPageItem = maxPageItem;
        },
    },
    actions: {
        async fetchDataGradeOfSchoolList({commit}) {
            return await GradeService.getGradeInPrinciple()
                .then(response => {
                    commit('SET_GradeOfSchoolList', response.data.data);
                })
                .catch(error => {
                    console.error(error);
                });
        },

        async fetchDataGetAllGradeInSchoolList({commit,state}) {
            return await GradeService.getAll(state.pageNumber, state.maxPageItem)
                .then(response => {
                    commit('SET_GetAllGradeInSchoolList', response.data.data.gradeList);
                })
                .catch(error => {
                    console.error(error);
                });
        }

    },

};
