export const  kidStatusList = [
    {key: "STUDYING", value: "Đang học", label: "Đang học"},
    {key: "STUDY_WAIT", value: "Chờ học",label: "Chờ học"},
    {key: "RESERVE", value: "Bảo lưu",label: "Bảo lưu"},
    {key: "LEAVE_SCHOOL", value: "Nghỉ học",label: "Nghỉ học"},
]

export const  kidStatusListExcel = [
    {key: "STUDYING", value: "Đang học", label: "Đang học"},
    {key: "STUDY_WAIT", value: "Chờ học",label: "Chờ học"},
    {key: "RESERVE", value: "Bảo lưu",label: "Bảo lưu"},
    {key: "LEAVE_SCHOOL", value: "Nghỉ học",label: "Nghỉ học"},
    {key: "OUT_SCHOOL", value: "Ra trường",label: "Ra trường"},
]
export const  loginStatusList = [
    {key: "loginYet", value: "Chưa từng đăng nhập"},
    {key: "loginNo", value: "Không đăng nhập"},
    {key: "loginYes", value: "Đã đăng nhập"},
]
export const statusEmployeeList = ["Đang làm", "Tạm nghỉ", "Nghỉ làm"]
export const appTypeList = [
    { key: "plus", value: "Nhà trường" },
    { key: "teacher", value: "Giáo viên" },
    { key: "parent", value: "Phụ huynh" },
]

export const  appType = {
    SUPPER_SYSTEM: "supperSys",
    SYSTEM: "sys",
    AGENT: "agent",
    SUPPER_SCHOOL: "supperPlus",
    SCHOOL: "plus",
    TEACHER: "teacher",
    PARENT: "parent",
}

export const companyInfo = [
    "Công ty Cp Ứng dụng trường Mầm non OneKids",
    "Địa chỉ 62 Trương Công Giai, phường Dịch Vọng, quận Cầu Giấy, tp Hà Nội"
]

export const statusEmployeeListNew = ["Đang làm", "Tạm nghỉ", "Nghỉ làm","Nghỉ hẳn"]

export const  optionSerialNumberShowNewsHomeMap = [
    {key: "1", value: "Tin tức"},
    {key: "2", value: "Facebook"},
    {key: "3", value: "Video"},
]

export const  kidStatusListOneAdmin = [
    {key: "STUDYING", value: "Đang học", label: "Đang học"},
    {key: "STUDY_WAIT", value: "Chờ học",label: "Chờ học"},
    {key: "RESERVE", value: "Bảo lưu",label: "Bảo lưu"},
    {key: "LEAVE_SCHOOL", value: "Nghỉ học",label: "Nghỉ học"},
    {key: "OUT_SCHOOL", value: "Ra trường",label: "Ra trường"},
]
// export const statusEmployeeListOneAdmin = ["Đang làm", "Tạm nghỉ", "Nghỉ làm, Nghỉ hẳn"]
export const statusEmployeeListOneAdmin = [
    {value: "Đang làm", label: "Đang làm"},
    {value: "Tạm nghỉ",label: "Tạm nghỉ"},
    {value: "Nghỉ làm",label: "Nghỉ làm"},
    {value: "Nghỉ hẳn",label: "Nghỉ hẳn"},
]
// export const optionSerialNumberShowNewsHomeList = ["Tin tức", "Facebook", "Video"]
