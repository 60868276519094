import http from "@/http-common";
import httpMultipart from "@/http-multipart";

class GroupExcelServices {
    // màn filter
    filterListKids(filterParams) {
        return http.post('/fn/fees/filterExcelForGroup',  filterParams)
    }

    // lấy danh sách nhóm
    getListKidsGroup() {
        return http.get('/fn/fees/listNameKidsGroup')
    }

    // lấy danh sách nhóm cho 1 học sinh
    getListKidsGroupByStudentId(idKid) {
        return http.get('/fn/fees/listKidsGroupOfHS', {params: {idKid:idKid}})
    }

    // đổi nhóm cho 1 học sinh
    changeGroupForAStudent(studentParams) {
        return http.get('/fn/fees/replaceKidGroupForOneHS', {params: studentParams})
    }

    // đổi nhóm cho danh sách học sinh
    changeGroupForListStudents(listStudentParams) {
        return http.get('/fn/fees/replaceKidGroupForListHS', {params: listStudentParams})
    }

    // xóa 1 nhóm cho danh sách học sinh
    removeOneGroupForListStudents(listStudentParams) {
        return http.get('/fn/fees/deleteOneKidGroupForListHS', {params: listStudentParams})
    }

    // xóa tất cả nhóm của 1 danh sách học sinh
    removeAllGroupsForListStudents(listStudentParams) {
        return http.get('/fn/fees/deleteAllKidGroupForListHS', {params: listStudentParams})
    }
    // xóa nhóm cho 1 cháu
    removeOneGroupForOneKid(params){
        return http.get('/fn/fees/deleteOneKidGroupForAHS',{params:params})
    }

    // cập nhật điểm danh nhóm
    updateTotalKidsArriveForGroup(groupParams) {
        return http.get('/fn/fees/totalKidsArriveForGroup', {params: groupParams})
    }

    // upload template excel cho nhóm
    uploadFileTemplateForGroup(formData) {
        return httpMultipart.post('/fn/fees/uploadTemplateForGroup', formData)
    }

    // lấy danh sách template excel nhóm
    getTemplateForGroupList(groupParams) {
        return http.get('/fn/fees/templateGroupList', {params: groupParams})
    }

    // xóa danh sách template nhóm
    deleteTemplateForGroup(groupTemplateParams) {
        return http.get('/fn/fees/deleteTemplateForGroup', {params: groupTemplateParams})
    }

    // thêm 1 học sinh vào nhóm
    addOneStudentInGroup(kidParams) {
        return http.post('/fn/fees/orderKidsExcel/addOneStudentForGroup', kidParams)
    }

    // thêm nhiều học sinh vào nhóm
    addMultipleStudentInGroup(studentsParam) {
        return http.post('/fn/fees/orderKidsExcel/addManyStudentForGroup', studentsParam)
    }

    // hiển thị chi tiết khoản thu
    showDetailPaymentForGroup(nameKTParam) {
        return http.post('/fn/fees/orderKidsExcel/showDetailPaymentForGroup', nameKTParam)
    }

    // lưu chi tiết khoản thu
    saveDetailKTForGroup(nameKTParam) {
        return http.post("/fn/fees/orderKidsExcel/modifyDetailPaymentForGroup", nameKTParam);
    }

    // hiển thị lịch sử thanh toán
    getHistoryOrderKidsCodeForGroup(idKid, month, collectionTurn, year,idGroup) {
        return http.get(`/fn/fees/orderKidsExcel/showAllHistoryOrderForGroup/${idKid}/${month}/${collectionTurn}/${year}/${idGroup}`);
    }

    // thanh toán hóa đơn theo đợt
    saveKidPaymentForTurnForGroup(kidParam) {
        return http.post("/fn/fees/order/paymentForTurnForGroup", kidParam);
    }

    // hiển thị thông tin bổ sung
    getKidExcelInfoByMonthAndTurnForGroup(kidParam) {
        return http.get('/fn/fees/orderKidsExcel/forMonthAndTurnForGroup', {params: kidParam});
    }

    // lưu thông tin bổ sung
    saveInfoAttendanceKidForGroup(param) {
        return http.post("/fn/fees/orderKidsExcel/saveInfoOderKidsExcelForGroup", param);
    }

    // tạo ghi chú cho  nhiều hs
    createNoteForListKidsForGroup(noteRequestParams) {
        return http.post("/fn/fees/orderKidsExcel/createNoteForListKidsForGroup", noteRequestParams);
    }

    //hủy hóa đơn theo đợt
    deleteOrderKidsExcelForGroup(deleteTurnParams) {
        return http.post("/fn/fees/orderKidsExcel/deleteOrderKidsExcelForGroup", deleteTurnParams);
    }

    //khôi phục hóa đơn theo đợt
    openOrderKidsExcelForGroup(openTurnParams) {
        return http.post("/fn/fees/orderKidsExcel/openOrderKidsExcelForGroup", openTurnParams);
    }

    //xóa hóa đơn theo đợt
    destroyOrderKidsExcelForGroup(date_idKid_turnParams) {
        return http.post("/fn/fees/orderKidsExcel/destroyOrderKidsExcelForGroup", date_idKid_turnParams);
    }

    // hiển thị tiền đã thu của HS theo đợt
    showCollectedMoneyForGroup(month, idKid, collectionTurn, year,idGroup) {
        return http.get('/fn/fees/orderKidsExcel/showCollectedMoneyForGroup', {
            params: {
                month,
                idKid,
                collectionTurn,
                year,
                idGroup
            }
        });
    }

    // sửa tiền đã thu của HS theo đợt
    updateExcelKidMoneyForGroup(kidParam) {
        return http.post("/fn/fees/orderKidsExcel/modifyMoneyOderKidsExcelForGroup", kidParam);
    }

    // Hển thị/ bỏ hiện thị hóa đơn cho 1 học sinh
    showHiddenOrderKidsForGroup(kidParam) {
        return http.post('/fn/fees/orderKidsExcel/showOrderHiddenForGroup', kidParam);
    }

    // Hển thị hóa đơn cho nhiều học sinh
    showListOrderAppForGroup(showParam) {
        return http.post("/fn/fees/orderKidsExcel/showListOrderForAppForGroup", showParam);
    }

    //bỏ hiện thị hóa đơn cho nhiều học sinh
    hiddenListOrderForGroupApp(hiddenParam) {
        return http.post("/fn/fees/orderKidsExcel/hiddenListOrderForAppForGroup", hiddenParam);
    }


    // Đọc dữ liệu từ file Excel hiển thị thông báo lên màn hình
    importExcelFileForGroup(formExcelData) {
        return httpMultipart.post("/fn/fees/callReadDataExcelForGroup", formExcelData);
    }

    // Lưu dữ liệu đọc được từ file Excel vào DB
    saveDataFromExcelToDBForGroup(listObjectKid) {
        return http.post("/fn/fees/saveDataFromExcelToDBForGroup", listObjectKid);
    }

    // Gửi SMS
    sendSMSForGroup(request) {
        return http.post(`/fn/fees/orderKidExcel/notifyForGroup`, request)
    }

    // gửi APP
    sendNotifyFinanceForAppForGroup(formData) {
        return httpMultipart.post(`/fn/fees/orderKidExcel/notify/appNewForGroup`, formData);
    }
    // Xuất excel theo đợt thu
    exportExcelByCollectionTurn(monthGroupParams){
        return http.get('/fn/fees/tuition/exportExcelForMonthGroup',{params:monthGroupParams})
    }
    // xuất excel học phí theo tháng cho nhiều đợt
    exportExcelForMonth(collectParams){
        return http.get('/fn/fees/exportExcelCollectionTurnsForGroup',{params:collectParams})
    }
    // xuất template học phí theo nhóm
    exportTemplateOrderGroup(excelParams){
        return http.get('/fn/fees/exportExcelTemplateGroup',{params:excelParams})
    }
    // hiển thị preview cho nhóm
    previewTemplateForGroup(listOrderPrint){
        return http.get('/fn/fees/showPrintOrderKidsExcelGroup',{params:listOrderPrint})
    }
    // xuất hóa đơn cho 1,2 liên
    exportPreviewTemplateData(dataParam){
        return http.get('/fn/fees/printOrderForGroup',{params:dataParam})
    }
    // xuất hóa đơn cho 1 cháu
    exportPreviewForOneKid(kidParams){
        return http.get('/fn/fees/fillDataForOneKidGroup',{params:kidParams})
    }
    // xuất hóa đơn cho toàn bộ hs trong tháng
    exportExcelForAllKidsInMonth(params){
        return http.get('/fn/fees/printOrderCollectionTurnsForGroup',{params:params})
    }
    // lây trạng thái học sinh
    listKidStatusForGroup(idSchool){
        return http.get('/fn/fees/listKidStatusForGroup',{params: {idSchool}})
    }
    // lấy trạng thái tiền
    listStatusMoney(statusPars){
        return http.get('/fn/fees/listStatusExcelForGroup',{params: statusPars})
    }
    // lấy đợt thu
    getCollectionTurn(month,idGroup,createdYear){
        return http.get('/fn/fees/collectionTurnForGroup',{params: {month,idGroup,createdYear}})
    }
    // lấy khối
    getGrade() {
        return http.get("/common-data/grade-in-school");
    }
    // lấy ds lớp
    getClassInGrade(idGrade) {
        return http.get(`/common-data/class-in-grade`,{params:{idGrade}});
    }
    // lấy tồn hóa đơn
    listRemainingBill(billParams){
        return http.post(`/fn/fees/listRemainingBillForGroup`,billParams);

    }
    // sửa kết chuyển
    updateTransactionEntryForGroup(transactParam){
        return http.post(`/fn/fees/updateTransactionEntryForGroup`,transactParam);

    }
    /**
     * Lấy danh sách khoản thu hiển thị trên màn hình lưu lịch sử thanh toán
     */

    showAllPaymentForKidForGroup(kidCode, collectionTurn, month, year,idGroup) {
        return http.get(
            `/fn/fees/orderKidsExcel/showAllPaymentForKidForGroup`, {params: {kidCode, collectionTurn, month, year,idGroup}}
        );
    }
    // Ngày bắt đầu va kết thúc của đợt thu
    showStartAndEndDate(param) {
        return http.post(`/fn/fees/orderKidsExcel/showStartAndEndDateForGroup`, param)
    }

    // Thay đổi tên khoản thu
    changeNameKTForGroup(formData) {
        return http.post(`/fn/fees/orderKidsExcel/modifyNameKtForGroup`, formData)
    }

    //    Thanh toán hàng loạt
    paymentAll(dataSubmit) {
        return http.post(`/fn/fees/orderKidExcel/notify/appNewForStudentsForGroup`, dataSubmit)
    }

    // KHởi tạo hóa đơn cho học sinh chưa có hóa đơn trong đợt thu
    createInvoice(data){
        return http.get(`/fn/fees/orderKidsExcel/createNotHaveOrderGroup`,{params: data})
    }

    // Thêm khoản thu
    addOneExpense(body){
        return http.post(`/fn/fees/orderKidsExcel/addOneKtForGroup`,body)
    }
    // Xóa khoản thu
    deleteLastExpense(body) {
        return http.post(`/fn/fees/orderKidsExcel/deleteLastKtForGroup`, body)
    }
}

export default new GroupExcelServices();