<template>
  <div class="menu-main">
    <el-menu
        class="el-menu-vertical-demo"
        :collapse="getIsOpenCloseManu"
        background-color="#304156"
        text-color="#bfcbd9"
        active-text-color="#409EFF"
        :unique-opened="true"
    >
      <router-link to="/dashboard" class="router-link">
        <el-menu-item index="1">
          <i class="el-icon-s-home"></i>
          <span slot="title" class="onekids-title">ONEKIDS PLUS</span>
        </el-menu-item>
      </router-link>
      <el-submenu index="2"
                  v-if="checkPermission(['news'])"
      >
        <template slot="title">
          <i class="el-icon-notebook-2"/>
          <span>Bảng tin</span>
        </template>
        <router-link to="/appsend/notification" class="router-link">
          <el-menu-item
              v-if="checkPermission(['news_notifySystem'])"
              index="2-1"
              @click="changeBreadcrumbMethod('appsend-notification')"
          >Thông báo hệ thống
          </el-menu-item
          >
        </router-link>
        <router-link to="/notifihis/notify-school" class="router-link">
          <el-menu-item
              index="2-2"
              @click="changeBreadcrumbMethod('notifihis-notify-school')"
          >Tin tức nhà trường
          </el-menu-item
          >
        </router-link>
        <router-link to="/appsend/parent-feedback" class="router-link">
          <el-menu-item
              v-if="checkPermission(['news_feedbackParent'])"
              index="2-3"
              @click="changeBreadcrumbMethod('appsend-parent-feedback')"
          >Phụ huynh góp ý
          </el-menu-item
          >
        </router-link>
        <router-link to="/appsend/teacher-feedback" class="router-link">
          <el-menu-item
              index="2-4"
              v-if="checkPermission(['news_feedbackTeacher'])"
              @click="changeBreadcrumbMethod('appsend-teacher-feedback')"
          >Giáo viên góp ý
          </el-menu-item
          >
        </router-link>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title">
          <i class="el-icon-reading"/>
          <span>Nhật ký phụ huynh</span>
        </template>
        <router-link to="/parentdiary/message-parent" class="router-link">
          <el-menu-item
              index="3-1"
              v-if="checkPermission(['parentDiary_message'])"
              @click="changeBreadcrumbMethod('parentdiary-message-parent')"
          >Lời nhắn
          </el-menu-item
          >
        </router-link>
        <router-link to="/parentdiary/medicine" class="router-link">
          <el-menu-item
              index="3-2"
              v-if="checkPermission(['parentDiary_medicine'])"
              @click="changeBreadcrumbMethod('parentdiary-medicine')"
          >Dặn thuốc
          </el-menu-item
          >
        </router-link>
        <router-link to="/parentdiary/take-leave" class="router-link">
          <el-menu-item
              index="3-3"
              v-if="checkPermission(['parentDiary_absenLetter'])"
              @click="changeBreadcrumbMethod('parentdiary-take-leave')"
          >Xin nghỉ
          </el-menu-item
          >
        </router-link>
      </el-submenu>
      <el-submenu index="4">
        <template slot="title">
          <i class="el-icon-user"/>
          <span>Quản lý nhân sự</span>
        </template>
        <router-link to="/employee/department" class="router-link">
          <el-menu-item
              index="4-1"
              v-if="checkPermission(['infoEmployee_department'])  "
              @click="changeBreadcrumbMethod('employee-department')"
          >Danh sách phòng ban
          </el-menu-item
          >
        </router-link>
        <router-link to="/employee/employees" class="router-link">
          <el-menu-item
              v-if="      checkPermission(['infoEmployee_list'])   "
              index="4-2"
              @click="changeBreadcrumbMethod('employee-employees')"
          >Danh sách nhân sự
          </el-menu-item
          >
        </router-link>
      </el-submenu>

      <el-submenu index="5">
        <template slot="title">
          <i class="el-icon-user-solid"/>
          <span>Quản lý học sinh</span>
        </template>
        <router-link to="/student/grade" class="router-link">
          <el-menu-item
              index="5-1"
              v-if="checkPermission(['kids_grade'])"
              @click="changeBreadcrumbMethod('student-grade')"
          >Danh sách khối
          </el-menu-item
          >
        </router-link>
        <router-link to="/student/class" class="router-link">
          <el-menu-item
              v-if="checkPermission(['kids_class'])"
              index="5-2"
              @click="changeBreadcrumbMethod('student-class')"
          >Danh sách lớp
          </el-menu-item
          >
        </router-link>
        <router-link to="/student/students" class="router-link">
          <el-menu-item
              v-if="checkPermission(['kids_list'])"
              index="5-3"
              @click="changeBreadcrumbMethod('student-students')"
          >Danh sách học sinh
          </el-menu-item
          >
        </router-link>
        <router-link to="/student/students-group" class="router-link">
          <el-menu-item
              index="5-4"
              v-if="checkPermission(['kids_group'])"
              @click="changeBreadcrumbMethod('student-students-group')"
          >Nhóm học sinh
          </el-menu-item
          >
        </router-link>
        <router-link
            v-if="checkPermission(['kids_transfer'])"
            to="/student/students-transfer"
            class="router-link"
        >
          <el-menu-item index="5-5" @click="changeBreadcrumbMethod('student-students-transfer')"
          >Thông tin mở rộng
          </el-menu-item
          >
        </router-link>
      </el-submenu>
      <el-submenu index="6">
        <template slot="title">
          <i class="el-icon-document-checked"/>
          <span>Chất lượng học sinh</span>
        </template>
        <router-link to="/student-quality/attendance" class="router-link">
          <el-menu-item
              v-if="checkPermission(['studentQuality_attendance'])"
              index="6-1"
              @click="changeBreadcrumbMethod('student-quality-attendance')"
          >Điểm danh
          </el-menu-item
          >
        </router-link>
        <router-link to="/student-quality/evaluate" class="router-link">
          <el-menu-item
              v-if="checkPermission(['studentQuality_evaluate'])"
              index="6-2"
              @click="changeBreadcrumbMethod('student-quality-evaluate')"
          >Nhận xét
          </el-menu-item
          >
        </router-link>
        <router-link to="/student-quality/album" class="router-link">
          <el-menu-item
              index="6-3"
              v-if="checkPermission(['studentQuality_ablum'])"
              @click="changeBreadcrumbMethod('student-quality-album')"
          >Album ảnh
          </el-menu-item
          >
        </router-link>
        <router-link to="/student-quality/height-weight" class="router-link">
          <el-menu-item
              index="6-4"
              v-if="checkPermission(['studentQuality_heightWeight'])"
              @click="changeBreadcrumbMethod('student-quality-height-weight')"
          >Chiều cao, cân nặng
          </el-menu-item
          >
        </router-link>
        <router-link to="/student-quality/schedule" class="router-link">
          <el-menu-item
              index="6-5"
              v-if="checkPermission(['studentQuality_schedule'])"
              @click="changeBreadcrumbMethod('student-quality-schedule')"
          >Thời khóa biểu
          </el-menu-item
          >
        </router-link>
        <router-link to="/student-quality/class-menu" class="router-link">
          <el-menu-item
              index="6-6"
              v-if="checkPermission(['studentQuality_menu'])"
              @click="changeBreadcrumbMethod('student-quality-class-menu')"
          >Thực đơn
          </el-menu-item
          >
        </router-link>
      </el-submenu>
      <el-submenu index="8" v-if="checkPermission(['fees'])">
        <template slot="title">
          <i class="el-icon-money"/>
          <span>Học phí &amp; dịch vụ</span>
        </template>

        <router-link
            to="/finace/fees/order"
            v-if="checkPermission(['fees_order'])"
            class="router-link">
          <el-menu-item
              index="8-1"
              @click="changeBreadcrumbMethod('finace-fees-order')"
          >Thu học phí
          </el-menu-item
          >
        </router-link>

        <router-link
            to="/finace/fees/approved/advance"
            v-if="checkPermission(['fees_approved_advance'])"
            class="router-link">
          <el-menu-item
              index="8-2"
              @click="changeBreadcrumbMethod('finace-fees-approved-advance')"
          >Duyệt nâng cao
          </el-menu-item>
        </router-link>

        <router-link
            to="/finace/fees/approved"
            v-if="checkPermission(['fees_approved'])"
            class="router-link">
          <el-menu-item
              index="8-3"
              @click="changeBreadcrumbMethod('finace-fees-approved')"
          >Duyệt học phí
          </el-menu-item>
        </router-link>

        <router-link
            to="/finace/fees/package-kids"
            v-if="checkPermission(['fees_kidsPackage'])"
            class="router-link">
          <el-menu-item
              index="8-4"
              @click="changeBreadcrumbMethod('finace-fees-package-kids')"
          >Đăng ký khoản
          </el-menu-item>
        </router-link>

        <router-link
            to="/finace/fees/package"
            v-if="checkPermission(['fees_package'])"
            class="router-link">
          <el-menu-item
              index="8-5"
              @click="changeBreadcrumbMethod('finace-fees-package')"
          >Danh sách khoản
          </el-menu-item>
        </router-link>

        <router-link
            to="/finace/fees/wallet"
            v-if="checkPermission(['fees_wallet'])"
            class="router-link">
          <el-menu-item
              index="8-6"
              @click="changeBreadcrumbMethod('finace-fees-wallet')"
          >Quản lý ví
          </el-menu-item>
        </router-link>

        <router-link
            class="router-link"
            v-if="checkPermission(['fees_2_excel'])"
            to="/finance/v2">
          <el-menu-item
              index="8-7"
              @click="changeBreadcrumbMethod('finance-fees-order-excel')"
          >Thu học phí theo lớp
          </el-menu-item
          >
        </router-link>
        <router-link
            class="router-link"
            to="/finance/tuition-group-by-excel"
            v-if="checkPermission(['fees_2_group'])">
          <el-menu-item
              index="8-8"
              @click="changeBreadcrumbMethod('finance-fees-order-excel-group')"
          >Thu học phí theo nhóm
          </el-menu-item
          >
        </router-link>
        <router-link
            class="router-link"
            v-if="checkPermission(['fees_2_finance_tuition_statistics'])"
            to="/finance/tuition-statistics">
          <el-menu-item
              index="8-9"
              @click="changeBreadcrumbMethod('finance-tuition-statistics')"
          >Thống kê học phí
          </el-menu-item
          >
        </router-link>
        <router-link
            v-if="checkPermission(['fees_2_finance_tuition_statistics'])"
            class="router-link"
            to="/finance/tuition-statistics/charts">
          <el-menu-item
              index="8-10"
              @click="changeBreadcrumbMethod('finance-tuition-statistics-charts')"
          >Biểu đồ thống kê
          </el-menu-item
          >
        </router-link>
      </el-submenu>
      <el-submenu index="9">
        <template slot="title">
          <i class="el-icon-coin"/>
          <span> Công lương </span>
        </template>

        <router-link to="/finace/salary/paid" class="router-link">
          <el-menu-item
              index="9-1"
              v-if="checkPermission(['salary_order'])"
              @click="changeBreadcrumbMethod('finace-salary-paid')"
          >Thanh toán lương
          </el-menu-item
          >
        </router-link>
        <router-link to="/finace/salary/approved" class="router-link">
          <el-menu-item
              index="9-2"
              v-if="checkPermission(['salary_approved'])"
              @click="changeBreadcrumbMethod('finace-salary-approved')"
          >Duyệt bảng lương
          </el-menu-item
          >
        </router-link>
        <router-link to="/finace/salary/institute" class="router-link">
          <el-menu-item
              index="9-3"
              v-if="checkPermission(['salary_setting'])"
              @click="changeBreadcrumbMethod('finace-salary-institute')"
          >Thiết lập tiền lương
          </el-menu-item
          >
        </router-link>
        <router-link to="/finace/salary/sample" class="router-link">
          <el-menu-item
              index="9-4"
              v-if="checkPermission(['salary_sample'])"
              @click="changeBreadcrumbMethod('finace-salary-sample')"
          >Mẫu công lương
          </el-menu-item
          >
        </router-link>
        <router-link to="/finance/employee/salary/new" class="router-link">
          <el-menu-item
              index="9-5"
              v-if="checkPermission(['salary_new_excel'])"
              @click="changeBreadcrumbMethod('finance-employee-salary-new')"
          >Bảng lương
          </el-menu-item>
        </router-link>

        <router-link to="/finance/salary/statistical" class="router-link">
          <el-menu-item
              index="9-6"
              v-if="checkPermission(['salary_new_excel_statistics'])"
              @click="changeBreadcrumbMethod('finance-salary-statistical')"
          >Thống kê công lương
          </el-menu-item>
        </router-link>
        <router-link to="/finance/employee/static-salary/new" class="router-link">
          <el-menu-item
              index="9-7"
              v-if="checkPermission(['salary_new_excel_chart_view'])"
              @click="changeBreadcrumbMethod('finance-employee-static-salary-new')"
          >Biểu đồ công lương
          </el-menu-item>
        </router-link>
      </el-submenu>
      <el-submenu index="10">
        <template slot="title">
          <i class="el-icon-document-checked"/>
          <span>Chấm công</span>
        </template>
        <router-link to="/finace/salary/absent" class="router-link">
          <el-menu-item
              index="10-1"
              v-if="checkPermission(['attendanceEmployee_absentTeacher'])"
              @click="changeBreadcrumbMethod('finace-salary-absent')"
          >Xin nghỉ nhân sự
          </el-menu-item
          >
        </router-link>
        <router-link to="/finace/salary/attendance" class="router-link">
          <el-menu-item
              index="10-2"
              v-if="checkPermission(['attendanceEmployee_attendance'])"
              @click="changeBreadcrumbMethod('finace-salary-attendance')"
          >Chấm công nhân viên
          </el-menu-item
          >
        </router-link>
        <router-link to="/finace/salary/attendance-config" class="router-link">
          <el-menu-item
              index="10-3"
              v-if="checkPermission(['attendanceEmployee_config'])"
              @click="changeBreadcrumbMethod('finace-salary-attendance-config')"
          >Thiết lập chấm công
          </el-menu-item>
        </router-link>

      </el-submenu>
      <el-submenu index="11">
        <template slot="title">
          <i class="el-icon-suitcase-1"/>
          <span
          ><el-button type="text" style="color: rgb(191, 203, 217)"
          >Thu chi nội bộ</el-button
          ></span
          >
        </template>

        <router-link to="/finace/cashinternal/paymoney" class="router-link">
          <el-menu-item
              index="11-1"
              v-if="checkPermission(['cashInternal_out'])"
              @click="changeBreadcrumbMethod('finace-cashinternal-paymoney')"
          >Quản lý phiếu chi
          </el-menu-item
          >
        </router-link>
        <router-link to="/finace/cashinternal/collectmoney" class="router-link">
          <el-menu-item
              index="11-2"
              v-if="checkPermission(['cashInternal_in'])"
              @click="changeBreadcrumbMethod('finace-cashinternal-collectmoney')"
          >Quản lý phiếu thu
          </el-menu-item
          >
        </router-link>
        <router-link to="/finace/cashinternal/cashbook" class="router-link">
          <el-menu-item
              index="11-3"
              v-if="checkPermission(['cashInternal_cashbook'])"
              @click="changeBreadcrumbMethod('finace-cashinternal-cashbook')"
          >Quản lý quỹ
          </el-menu-item
          >
        </router-link>
        <router-link to="/finace/cashinternal/peopletype" class="router-link">
          <el-menu-item
              v-if="checkPermission(['cashInternal_people'])"
              index="11-4"
              @click="changeBreadcrumbMethod('finace-cashinternal-peopletype')"
          >Danh sách đối tượng
          </el-menu-item
          >
        </router-link>
        <router-link to="/finace/cashinternal/bankinfo" class="router-link">
          <el-menu-item
              v-if="checkPermission(['cashInternal_bank'])"
              index="11-5"
              @click="changeBreadcrumbMethod('finace-cashinternal-bankinfo')"
          >Thông tin thanh toán
          </el-menu-item
          >
        </router-link>
      </el-submenu>
      <el-submenu index="12"
                  v-if="checkPermission(['statistical'])">
        <template slot="title">
          <i class="el-icon-s-data"/>
          <span>Thống kê</span>
        </template>
        <el-submenu index="12-1"
                    v-if="checkPermission(['statistical_kids'])">
          <template slot="title">Học sinh</template>
          <router-link to="/chart/statistical/chart-status" class="router-link">
            <el-menu-item
                v-if="checkPermission(['statistical_kids_status'])"
                index="12-1-1"
                @click="changeBreadcrumbMethod('chart-statistical-status')"
            >Trạng thái
            </el-menu-item
            >
          </router-link>
          <router-link to="/chart/statistical/attendance" class="router-link">
            <el-menu-item
                index="12-1-2"
                v-if="checkPermission(['statistical_kids_attendance'])"
                @click="changeBreadcrumbMethod('chart-statistical-attendance')"
            >Điểm danh
            </el-menu-item
            >
          </router-link>
          <router-link to="/chart/statistical/evaluate" class="router-link">
            <el-menu-item
                index="12-1-3"
                v-if="checkPermission(['statistical_kids_evaluate'])"
                @click="changeBreadcrumbMethod('chart-statistical-evaluate')"
            >Nhận xét
            </el-menu-item
            >
          </router-link>
          <router-link to="/chart/statistical/album" class="router-link">
            <el-menu-item
                index="12-1-4"
                v-if="checkPermission(['statistical_kids_album'])"
                @click="changeBreadcrumbMethod('chart-statistical-album')"
            >Album ảnh
            </el-menu-item
            >
          </router-link>
          <router-link to="/chart/statistical/fees" class="router-link">
            <el-menu-item
                index="12-1-5"
                v-if="checkPermission(['statistical_kids_fees'])"
                @click="changeBreadcrumbMethod('chart-statistical-fees')"
            >Học phí
            </el-menu-item
            >
          </router-link>
        </el-submenu>
        <el-submenu index="12-2"
                    v-if="checkPermission(['statistical_employee'])">
          <template slot="title">Nhân sự</template>
          <router-link
              to="/chart/statistical/chart-status-employee"
              class="router-link"
          >
            <el-menu-item
                v-if="checkPermission(['statistical_employee_status'])"
                index="12-2-1"
                @click="changeBreadcrumbMethod('chart-statistical-status-employee')    "
            >Trạng thái
            </el-menu-item
            >
          </router-link>
          <router-link
              to="/chart/statistical/attendance-employee"
              class="router-link"
          >
            <el-menu-item
                v-if="checkPermission(['statistical_employee_attendance'])"
                index="12-2-2"
                @click="
                changeBreadcrumbMethod('chart-statistical-attendance-employee')
              "
            >Điểm danh
            </el-menu-item
            >
          </router-link>
          <router-link to="/chart/statistical/finace" class="router-link">
            <el-menu-item
                v-if="checkPermission(['statistical_employee_salary'])"
                index="12-2-3"
                @click="changeBreadcrumbMethod('chart-statistical-finace')"
            >Công lương
            </el-menu-item
            >
          </router-link>
        </el-submenu>
        <el-submenu index="12-3"
                    v-if="checkPermission(['statistical_internal'])">
          <template slot="title">Nội bộ</template>
          <router-link
              to="/chart/statistical/cashinternal-paymoney"
              class="router-link"
          >
            <el-menu-item
                index="12-3-1"
                v-if="checkPermission(['statistical_internal_inout'])"
                @click="changeBreadcrumbMethod('chart-statistical-cashinternal-paymoney') "
            >Thu chi
            </el-menu-item
            >
          </router-link>
          <router-link
              to="/chart/statistical/cashinternal-cashbook"
              class="router-link"
          >
            <el-menu-item
                index="12-3-2"
                v-if="checkPermission(['statistical_internal_cashbook'])"
                @click="
                changeBreadcrumbMethod(
                  'chart-statistical-cashinternal-cashbook'
                )
              "
            >Quỹ nhà trường
            </el-menu-item
            >
          </router-link>
          <router-link
              to="/chart/statistical/wallet-parent"
              class="router-link"
          >
            <el-menu-item
                index="12-3-3"
                v-if="checkPermission(['statistical_internal_wallet'])"
                @click="changeBreadcrumbMethod('chart-statistical-wallet-parent')"
            >Ví phụ huynh
            </el-menu-item
            >
          </router-link>
        </el-submenu>
        <el-submenu index="12-4"
                    v-if="checkPermission(['statisticalFinance'])"
        >
          <template slot="title">
            <span>Thống kê báo cáo</span>
          </template>
          <router-link to="/finace/statistical/money" class="router-link">
            <el-menu-item
                index="12-4-1"
                v-if="checkPermission(['statisticalFinance_statistical'])"
                @click="changeBreadcrumbMethod('finace-statistical-money')"
            >Thống kê
            </el-menu-item
            >
          </router-link>
          <router-link to="/finace/statistical/finance" class="router-link">
            <el-menu-item
                index="12-4-2"
                v-if="checkPermission(['statisticalFinance_money'])"
                @click="changeBreadcrumbMethod('finace-statistical-finance')"
            >Số liệu báo cáo
            </el-menu-item
            >
          </router-link>
          <router-link to="/finace/statistical/kids/group" class="router-link">
            <el-menu-item
                index="12-4-3"
                v-if="checkPermission(['statisticalFinance_groupFees'])"
                @click="changeBreadcrumbMethod('finace-statistical-kids-group')"
            >Nhóm học phí
            </el-menu-item
            >
          </router-link>
          <router-link
              to="/finace/statistical/employee/group"
              class="router-link"
          >
            <el-menu-item
                index="12-4-4"
                v-if="checkPermission(['statisticalFinance_groupSalary'])"
                @click="
                changeBreadcrumbMethod('finace-statistical-employee-group')
              "
            >Nhóm công lương
            </el-menu-item
            >
          </router-link>
        </el-submenu>

      </el-submenu>
      <el-submenu index="13"
                  v-if=" checkPermission(['birthday'])">
        <template slot="title">
          <i class="el-icon-burger"/>
          <span>Quản lý sinh nhật</span>
        </template>
        <router-link to="/birthday/kids" class="router-link">
          <el-menu-item
              index="13-1"
              v-if="checkPermission(['birthday_kids'])"
              @click="changeBreadcrumbMethod('birthday-kids')"
          >Sinh nhật học sinh
          </el-menu-item
          >
        </router-link>
        <router-link to="/birthday/parent" class="router-link">
          <el-menu-item
              index="13-2"
              v-if="checkPermission(['birthday_parent'])"
              @click="changeBreadcrumbMethod('birthday-parent')"
          >Sinh nhật phụ huynh
          </el-menu-item
          >
        </router-link>
        <router-link to="/birthday/employee" class="router-link">
          <el-menu-item
              index="13-3"
              v-if="checkPermission(['birthday_teacher'])"
              @click="changeBreadcrumbMethod('birthday-employee')"
          >Sinh nhật nhân viên
          </el-menu-item
          >
        </router-link>
      </el-submenu>
      <el-submenu index="14"
                  v-if="checkPermission(['notifyHistory'])"
      >
        <template slot="title">
          <i class="el-icon-chat-dot-round"/>
          <span>Lịch sử thông báo</span>
        </template>
        <router-link to="/notifihis/schedulesms" class="router-link">
          <el-menu-item
              index="14-1"
              v-if="checkPermission(['notifyHistory_schedule'])"
              @click="changeBreadcrumbMethod('notifihis-schedulesms')"
          >Tin nhắn đặt lịch
          </el-menu-item
          >
        </router-link>

        <router-link to="/notifihis/custom" class="router-link">
          <el-menu-item
              index="14-2"
              v-if="checkPermission(['notifyHistory_custom'])"
              @click="changeBreadcrumbMethod('notifihis-custom')"
          >Tin nhắn tùy chỉnh
          </el-menu-item
          >
        </router-link>
        <router-link to="/notifihis/historysms" class="router-link">
          <el-menu-item
              index="14-3"
              v-if="checkPermission(['notifyHistory_historySms'])"
              @click="changeBreadcrumbMethod('notifihis-historysms')"
          >Lịch sử nhắn tin SMS
          </el-menu-item
          >
        </router-link>
        <router-link to="/notifihis/historyapp" class="router-link">
          <el-menu-item
              v-if="checkPermission(['notifyHistory_historyApp'])"
              index="14-4"
              @click="changeBreadcrumbMethod('notifihis-historyapp')"
          >Lịch sử thông báo App
          </el-menu-item
          >
        </router-link>
      </el-submenu>
      <el-submenu index="15"
                  v-if="checkPermission(['outSchool'])"
      >
        <template slot="title">
          <i class="el-icon-s-promotion"/>
          <span>Quản lý ra trường</span>
        </template>
        <router-link to="/group-out/student" class="router-link">
          <el-menu-item
              index="15-1"
              v-if="checkPermission(['outSchool_student'])"
              @click="changeBreadcrumbMethod('group-out-student')"
          >Học sinh
          </el-menu-item
          >
        </router-link>
        <router-link to="/group-out/employee" class="router-link">
          <el-menu-item
              index="15-2"
              v-if="checkPermission(['outSchool_employee'])"
              @click="changeBreadcrumbMethod('group-out-employee')"
          >Nhân sự
          </el-menu-item
          >
        </router-link>
        <router-link to="/group-out/category" class="router-link">
          <el-menu-item
              index="15-3"
              v-if="checkPermission(['outSchool_folder'])"
              @click="changeBreadcrumbMethod('group-out-category')"
          >Thư mục
          </el-menu-item
          >
        </router-link>
      </el-submenu>
      <el-submenu
          index="16"
          v-if="
          checkPermission(['schoolConfig'])
        "
      >
        <template slot="title">
          <i class="el-icon-setting"/>
          <span>Cấu hình nhà trường</span>
        </template>
        <router-link
            v-if="checkPermission(['schoolConfig_school'])"
            to="/school/infor"
            class="router-link"
        >
          <el-menu-item index="16-1" @click="changeBreadcrumbMethod('school-infor')"
          >Thông tin trường
          </el-menu-item
          >
        </router-link>
        <router-link
            v-if="checkPermission(['schoolConfig_absentTeacher'])"
            to="/school/config-class"
            class="router-link"
        >
          <el-menu-item
              index="16-2"
              @click="changeBreadcrumbMethod('school-config-class')"
          >Cấu hình ngày nghỉ
          </el-menu-item
          >
        </router-link>
        <router-link
            v-if="checkPermission(['schoolConfig_attendanceEmployee'])"
            to="/school/config-employee"
            class="router-link"
        >
          <el-menu-item
              index="16-3"
              @click="changeBreadcrumbMethod('school-config-employee')"
          >Cấu hình nhân sự
          </el-menu-item
          >
        </router-link>
        <router-link
            v-if="checkPermission(['schoolConfig_finance'])"
            to="/school/config-common"
            class="router-link"
        >
          <el-menu-item
              index="16-4"
              @click="changeBreadcrumbMethod('school-config-common')"
          >Cấu hình tài chính
          </el-menu-item
          >
        </router-link>
        <router-link
            v-if="checkPermission(['schoolConfig_print'])"
            to="/school/config-print"
            class="router-link"
        >
          <el-menu-item
              index="16-5"
              @click="changeBreadcrumbMethod('school-config-print')"
          >Cấu hình in
          </el-menu-item
          >
        </router-link>
        <router-link
            v-if="checkPermission(['schoolConfig_subject'])"
            to="/school/subject"
            class="router-link"
        >
          <el-menu-item index="16-6" @click="changeBreadcrumbMethod('school-subject')"
          >Quản lý môn học
          </el-menu-item
          >
        </router-link>
        <router-link
            v-if="checkPermission(['schoolConfig_attendanceSample'])"
            to="/school/attendance-sample"
            class="router-link"
        >
          <el-menu-item
              index="16-7"
              @click="changeBreadcrumbMethod('school-attendance-sample')"
          >Mẫu điểm danh
          </el-menu-item
          >
        </router-link>
        <router-link
            v-if="checkPermission(['schoolConfig_evaluateSample'])"
            to="/school/evaluate-sample"
            class="router-link"
        >
          <el-menu-item
              index="16-8"
              @click="changeBreadcrumbMethod('school-evaluate-sample')"
          >Mẫu nhận xét
          </el-menu-item
          >
        </router-link>

        <router-link
            v-if="checkPermission(['schoolConfig_wishSample'])"
            to="/school/wish-sample"
            class="router-link"
        >
          <el-menu-item index="16-9" @click="changeBreadcrumbMethod('school-wish-sample')"
          >Mẫu lời chúc
          </el-menu-item
          >
        </router-link>
        <router-link
            v-if="checkPermission(['schoolConfig_accountType'])"
            to="/school/account-type"
            class="router-link"
        >
          <el-menu-item
              index="16-10"
              @click="changeBreadcrumbMethod('school-account-type')"
          >Quản lý đối tượng
          </el-menu-item
          >
        </router-link>
        <router-link
            v-if="checkPermission(['schoolConfig_cameraSchool'])"
            to="/school/camera-school"
            class="router-link"
        >
          <el-menu-item
              index="16-11"
              @click="changeBreadcrumbMethod('school-camera-school')"
          >Quản lý camera
          </el-menu-item
          >
        </router-link>


        <router-link
            to="/school/icon-app"
            class="router-link"
        >
          <el-menu-item
              index="16-12"
              @click="changeBreadcrumbMethod('icon-app-school')"
          >Quản lý Icon App
          </el-menu-item
          >
        </router-link>
      </el-submenu>

      <el-submenu index="17">
        <template slot="title">
          <i class="el-icon-question"/>
          <span>Hỗ trợ &amp; hướng dẫn</span>
        </template>
        <a
            v-for="item in supportList"
            :key="item.name"
            class="router-link"
            :href="item.link"
            target="_blank"
            style="color: #bfcbd9; display: block"
        >
          <el-menu-item :index="item.name">{{ item.name }}</el-menu-item>
        </a>
      </el-submenu>
    </el-menu>
  </div>
</template>
<script>
import checkPermission from "@/utils/permission";
import {mapGetters} from "vuex";

export default {
  name: 'MenuSchool',
  data() {
    return {
      breadcrumbDataList: [
        //bản tin
        {
          key: "appsend-notification",
          value: [{url: "/appsend/notification", name: "Thông báo hệ thống"}],
        },
        {
          key: "appsend-parent-feedback",
          value: [{url: "/appsend/parent-feedback", name: "Phụ huynh góp ý"}],
        },
        {
          key: "appsend-teacher-feedback",
          value: [{url: "/appsend/teacher-feedback", name: "Giáo viên góp ý"}],
        },
        //nhật ký phụ huynh
        {
          key: "parentdiary-message-parent",
          value: [{url: "/parentdiary/message-parent", name: "Lời nhắn"}],
        },
        {
          key: "parentdiary-medicine",
          value: [{url: "/parentdiary/medicine", name: "Dặn thuốc"}],
        },
        {
          key: "parentdiary-take-leave",
          value: [{url: "/parentdiary/take-leave", name: "Xin nghỉ"}],
        },
        //quản lý nhân sự
        {
          key: "employee-department",
          value: [{url: "/employee/department", name: "Danh sách phòng ban"}],
        },
        {
          key: "employee-employees",
          value: [{url: "/employee/employees", name: "Danh sách nhân sự"}],
        },
        //danh sách học sinh
        {
          key: "student-grade",
          value: [{url: "/student/grade", name: "Danh sách khối"}],
        },
        {
          key: "student-class",
          value: [{url: "/student/class", name: "Danh sách lớp"}],
        },
        {
          key: "student-students",
          value: [{url: "/student/students", name: "Danh sách học sinh"}],
        },
        {
          key: "student-students-group",
          value: [{url: "/student/students-group", name: "Nhóm học sinh"}],
        },
        //chất lượng học sinh
        {
          key: "student-quality-attendance",
          value: [{url: "/student-quality/attendance", name: "Điểm danh"}],
        },
        {
          key: "student-quality-evaluate",
          value: [{url: "/student-quality/evaluate", name: "Nhận xét"}],
        },
        {
          key: "student-quality-album",
          value: [{url: "/student-quality/album", name: "Album ảnh"}],
        },
        {
          key: "student-quality-height-weight",
          value: [
            {
              url: "/student-quality/height-weight",
              name: "Chiều cao, cân nặng",
            },
          ],
        },
        {
          key: "student-quality-schedule",
          value: [{url: "/student-quality/schedule", name: "Thời khóa biểu"}],
        },
        {
          key: "student-quality-class-menu",
          value: [{url: "/student-quality/class-menu", name: "Thực đơn"}],
        },
        //Học phí dịch vụ I
        {
          key: "finance-fees-order-excel",
          value: [{url: "/finance/v2", name: "Thu học phí theo lớp"}],
        },
        {
          key: "finance-fees-order-excel-group",
          value: [{url: "/finance/tuition-group-by-excel", name: "Thu học phí theo nhóm"}],
        },
        {
          key: "finance-tuition-statistics",
          value: [{url: " /finance/tuition-statistics", name: "Thống kê học phí"}],
        },
        {
          key: "finance-tuition-statistics-charts",
          value: [{url: "/finance/tuition-statistics/charts", name: "Biểu đồ thống kê"}],
        },
        //học phí & dịch vụ II
        {
          key: "finace-fees-order",
          value: [{url: "/finace/fees/order", name: "Thu học phí"}],
        },
        {
          key: "finace-fees-approved",
          value: [{url: "/finace/fees/approved", name: "Duyệt học phí"}],
        },
        {
          key: "finace-fees-package-kids",
          value: [{url: "/finace/fees/package-kids", name: "Đăng ký khoản"}],
        },
        {
          key: "finace-fees-package",
          value: [{url: "/finace/fees/package", name: "Danh sách khoản"}],
        },
        {
          key: "finace-fees-wallet",
          value: [{url: "/finace/fees/wallet", name: "Quản lý ví"}],
        },
        //Công lương
        {
          key: "finace-salary-attendance",
          value: [{url: "/finace/salary/attendance", name: "Chấm công nhân viên"}],
        },
        {
          key: "finace-salary-absent",
          value: [{url: "/finace/salary/absent", name: "Quản lý xin nghỉ"}],
        },
        {
          key: "finace-salary-paid",
          value: [{url: "/finace/salary/paid", name: "Thanh toán lương"}],
        },
        {
          key: "finace-salary-approved",
          value: [{url: "/finace/salary/approved", name: "Duyệt bảng lương"}],
        },
        {
          key: "finace-salary-institute",
          value: [{url: "/finace/salary/institute", name: "Thiết lập tiền lương"}],
        },
        {
          key: "finace-salary-attendance-config",
          value: [
            {
              url: "/finace/salary/attendance-config",
              name: "Thiết lập chấm công",
            },
          ],
        },
        {
          key: "finace-salary-sample",
          value: [{url: "/finace/salary/sample", name: "Mẫu công lương"}],
        },
        {
          key: "finance-salary-statistical",
          value: [{url: "/finance/salary/statistical", name: "Thống kê công lương"}],
        },
        //thu chi nội bộ
        {
          key: "finance-employee-salary-new",
          value: [{url: "finance/employee/salary/new", name: "Bảng lương"}],
        },
        {
          key: "finance-employee-static-salary-new",
          value: [{url: "/finance/employee/static-salary/new", name: "Biểu đồ công lương"}],
        },
        {
          key: "finace-cashinternal-paymoney",
          value: [{url: "/finace/cashinternal/paymoney", name: "Quản lý phiếu chi"}],
        },
        {
          key: "finace-cashinternal-collectmoney",
          value: [
            {
              url: "/finace/cashinternal/collectmoney",
              name: "Quản lý phiếu thu",
            },
          ],
        },
        {
          key: "finace-cashinternal-cashbook",
          value: [{url: "/finace/cashinternal/cashbook", name: "Quản lý quỹ"}],
        },
        {
          key: "finace-cashinternal-peopletype",
          value: [
            {
              url: "/finace/cashinternal/peopletype",
              name: "Danh sách đối tượng",
            },
          ],
        },
        {
          key: "finace-cashinternal-bankinfo",
          value: [
            {
              url: "/finace/cashinternal/bankinfo",
              name: "Thông tin thanh toán",
            },
          ],
        },
        //quản lý sinh nhật
        {
          key: "birthday-kids",
          value: [{url: "/birthday/kids", name: "Sinh nhật học sinh"}],
        },
        {
          key: "birthday-parent",
          value: [{url: "/birthday/parent", name: "Sinh nhật phụ huynh"}],
        },
        {
          key: "birthday-employee",
          value: [{url: "/birthday/employee", name: "Sinh nhật nhân viên"}],
        },
        //thống kê báo cáo
        {
          key: "finace-statistical-money",
          value: [{url: "/finace/statistical/money", name: "Thống kê"}],
        },
        {
          key: "finace-statistical-kids-group",
          value: [{url: "/finace/statistical/kids/group", name: "Nhóm học phí"}],
        },
        {
          key: "finace-statistical-finance",
          value: [{url: "/finace/statistical/finance", name: "Số liệu báo cáo"}],
        },
        {
          key: "finace-statistical-employee-group",
          value: [
            {
              url: "/finace/statistical/employee/group",
              name: "Nhóm công lương",
            },
          ],
        },
        //thống kê biểu đồ
        {
          key: "chart-statistical-status",
          value: [{url: "/chart/statistical/chart-status", name: "Trạng thái"}],
        },
        {
          key: "chart-statistical-attendance",
          value: [{url: "/chart/statistical/attendance", name: "Điểm danh"}],
        },
        {
          key: "chart-statistical-evaluate",
          value: [{url: "/chart/statistical/evaluate", name: "Nhận xét"}],
        },
        {
          key: "chart-statistical-album",
          value: [{url: "/chart/statistical/album", name: "Album ảnh"}],
        },
        {
          key: "chart-statistical-fees",
          value: [{url: "/chart/statistical/fees", name: "Học phí"}],
        },
        {
          key: "chart-statistical-status-employee",
          value: [
            {
              url: "/chart/statistical/chart-status-employee",
              name: "Trạng thái",
            },
          ],
        },
        {
          key: "chart-statistical-attendance-employee",
          value: [
            {
              url: "/chart/statistical/attendance-employee",
              name: "Điểm danh",
            },
          ],
        },
        {
          key: "chart-statistical-finace",
          value: [{url: "/chart/statistical/finnace", name: "Công lương"}],
        },
        {
          key: "chart-statistical-cashinternal-paymoney",
          value: [
            {
              url: "/chart/statistical/cashinternal-paymoney",
              name: "Thu chi",
            },
          ],
        },
        {
          key: "chart-statistical-cashinternal-cashbook",
          value: [
            {
              url: "/chart/statistical/cashinternal-cashbook",
              name: "Quỹ nhà trường",
            },
          ],
        },
        {
          key: "chart-statistical-wallet-parent",
          value: [{url: "/chart/statistical/wallet-parent", name: "Ví phụ huynh"}],
        },

        //lịch sử thông báo
        {
          key: "notifihis-schedulesms",
          value: [{url: "/notifihis/schedulesms", name: "Tin nhắn đặt lịch"}],
        },
        {
          key: "notifihis-custom",
          value: [{url: "/notifihis/custom", name: "Tin nhắn tùy chỉnh"}],
        },
        {
          key: "notifihis-historysms",
          value: [{url: "/notifihis/historysms", name: "Lịch sử nhắn tin SMS"}],
        },
        {
          key: "notifihis-historyapp",
          value: [{url: "/notifihis/historyapp", name: "Lịch sử thông báo App"}],
        },
        {
          key: "notifihis-notify-school",
          value: [{url: "/notifihis/notify-school", name: "Tin tức nhà trường"}],
        },
        //thống kê chung
        //Quản lý ra trường
        {
          key: "group-out-student",
          value: [
            {
              url: "/group-out/student",
              name: "Quản lý ra trường học sinh",
            },
          ],
        },
        {
          key: "group-out-employee",
          value: [
            {
              url: "/group-out/employee",
              name: "Quản lý ra trường nhân sự",
            },
          ],
        },
        {
          key: "group-out-category",
          value: [{url: "/group-out/category", name: "Quản lý thư mục"}],
        },
        //Cấu hình nhà trường
        {
          key: 'school-config-print',
          value: [{url: "/school/config-print", name: "Cấu hình in ấn"}],
        },
        {
          key: "school-infor",
          value: [{url: "/school/infor", name: "Thông tin trường"}],
        },
        {
          key: "school-config",
          value: [{url: "/school/config", name: "Cấu hình chung"}],
        },
        {
          key: "school-config-class",
          value: [{url: "/school/config-class", name: "Cấu hình ngày nghỉ"}],
        },
        {
          key: "school-config-employee",
          value: [{url: "/school/config-employee", name: "Cấu hình nhân sự"}],
        },
        {
          key: "school-config-common",
          value: [{url: "/school/config-common", name: "Cấu hình tài chính"}],
        },
        {
          key: "school-camera-school",
          value: [{url: "/school/camera-type", name: "Quản lý camera"}],
        },
        {
          key: "icon-app-school",
          value: [{url: "/school/icon-app", name: "Quản lý Icon App"}],
        },
        {
          key: "school-media",
          value: [{url: "/school/media", name: "Cấu hình Media"}],
        },
        {
          key: "school-notification-manage",
          value: [{url: "/school/notification-manage", name: "Tự động gửi tin"}],
        },
        {
          key: "school-config-fuction",
          value: [{url: "/school/config-fuction", name: "Ẩn hiển chức năng"}],
        },
        {
          key: "school-birthday-sample",
          value: [{url: "/school/birthday-sample", name: "Cấu hình gửi tin"}],
        },
        {
          key: "school-subject",
          value: [{url: "/school/subject", name: "Quản lý môn học"}],
        },
        {
          key: "school-evaluate-sample",
          value: [{url: "/school/evaluate-sample", name: "Mẫu nhận xét"}],
        },
        {
          key: "school-attendance-sample",
          value: [
            {
              url: "/school/attendance-sample",
              name: "Mẫu điểm danh",
            },
          ],
        },
        {
          key: "school-wish-sample",
          value: [
            {
              url: "/school/wish-sample",
              name: "Mẫu lời chúc",
            },
          ],
        },
        {
          key: "school-account-type",
          value: [{url: "/school/account-type", name: "Quản lý đối tượng"}],
        },
      ],
    }

  },
  created() {
    this.$store.dispatch('commonDataStore/fetchDataSupportList');
  },
  computed: {
    getIsOpenCloseManu() {
      return this.$store.getters["layout/getIsOpenClose"];
    },
    getAppTypeUserLogin() {
      return this.$store.state.auth.user.appType;
    },
    ...mapGetters('commonDataStore',['supportList']),
  },
  methods: {
    checkPermission,
    changeBreadcrumbMethod(val) {
      let selectUrl = this.breadcrumbDataList.filter((x) => x.key == val);
      if (selectUrl.length > 0) {
        let dataList = selectUrl[0].value;
        sessionStorage.setItem("breadcrumbList", JSON.stringify(dataList));
        this.$store.dispatch("layout/setBreadcrumbActions", dataList);
      }
    },

  },

}
</script>

<style lang="scss" scoped>
.menu-main {
  min-height: 950px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 210px;
  min-height: 700px;
  font-family: Arial;

}

.router-link {
  text-decoration: none;
}

.el-menu-item {
  background-color: #1f2d3d !important;
}

.el-menu-item:hover {
  background-color: #49575f !important;
}

/deep/ .el-message-box {
  display: inline-block;
  vertical-align: top !important;
  width: 420px;
  padding-bottom: 10px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  font-size: 18px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/deep/ .el-message-box__wrapper::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: top !important;
}

.el-menu {
  margin-bottom: 20px;
}

.onekids-title {
  font-weight: bold;
}
</style>