<template>
  <div class="menu-main">
    <el-menu
        class="el-menu-vertical-demo"
        :collapse="getIsOpenCloseMenu"
        background-color="#304156"
        text-color="#bfcbd9"
        active-text-color="#409EFF"
        :unique-opened="true"
    >
      <router-link to="/dashboard" class="router-link">
        <el-menu-item index="1">
          <i class="el-icon-s-home"></i>
          <span slot="title" class="onekids-title">ONEKIDS PARENT</span>
        </el-menu-item>
      </router-link>

      <router-link to="/parent/dashboard" class="router-link">
        <el-menu-item
            index="2"
            @click="changeBreadcrumbMethod('parent-dashboard')"
        >
          <i class="el-icon-s-data"/>
          <span>Biểu đồ</span>
        </el-menu-item>
      </router-link>

      <el-submenu index="3">
        <template slot="title">
          <i class="el-icon-document"/>
          <span>Bản tin</span>
        </template>
        <router-link to="/parent/notify" class="router-link">
          <el-menu-item
              index="3-1"
              @click="changeBreadcrumbMethod('parent-notify')"
          >Thông báo
          </el-menu-item
          >
        </router-link>
        <router-link to="/parent/news" class="router-link">
          <el-menu-item
              index="3-2"
              @click="changeBreadcrumbMethod('parent-news')"
          >Tin tức nhà trường
          </el-menu-item
          >
        </router-link>
      </el-submenu>
      <el-submenu index="4">
        <template slot="title">
          <i class="el-icon-notebook-1"/>
          <span>Hoạt động</span>
        </template>
        <router-link to="/parent/album" class="router-link">
          <el-menu-item
              index="4-1"
              @click="changeBreadcrumbMethod('parent-album')"
          >Album ảnh
          </el-menu-item
          >
        </router-link>
        <router-link to="/parent/video" class="router-link">
          <el-menu-item
              index="4-2"
              @click="changeBreadcrumbMethod('parent-video')"
          >Video
          </el-menu-item
          >
        </router-link>
        <router-link to="/parent/video/other" class="router-link">
          <el-menu-item
              index="5-3"
              @click="changeBreadcrumbMethod('parent-video-other')"
          >Thư viện
          </el-menu-item
          >
        </router-link>
      </el-submenu>

      <el-submenu index="5">
        <template slot="title">
          <i class="el-icon-question"/>
          <span>Hỗ trợ &amp; hướng dẫn</span>
        </template>
        <a
            v-for="item in supportList"
            :key="item.name"
            class="router-link"
            :href="item.link"
            target="_blank"
            style="color: #bfcbd9; display: block"
        >
          <el-menu-item :index="item.name">{{ item.name }}</el-menu-item>
        </a>
      </el-submenu>
    </el-menu>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'MenuParent',
  data() {
    return {
      breadcrumbDataList: [

        {
          key: "parent-dashboard",
          value: [{url: "/parent/dashboard", name: "Biểu đồ học sinh"}],
        },
        {
          key: "parent-notify",
          value: [{url: "/parent/notify", name: "Thông báo"}],
        },
        {
          key: "parent-news",
          value: [{url: "/parent/news", name: "Tin tức nhà trường"}],
        },
        {
          key: "parent-album",
          value: [{url: "/parent/album", name: "Album ảnh"}],
        },
        {
          key: "parent-video",
          value: [{url: "/parent/video", name: "Video"}],
        },
        {
          key: "parent-video-other",
          value: [{url: "/parent/video/other", name: "Thư viện"}],
        },
        {
          key: "school-permission-manage",
          value: [{url: "/school/permission-manage", name: "Quản lý quyền"}],
        },]
    }

  },
  created() {
    this.$store.dispatch('commonDataStore/fetchDataSupportList');
  },
  computed: {
    getIsOpenCloseMenu() {
      return this.$store.getters["layout/getIsOpenClose"];
    },
    getAppTypeUserLogin() {
      return this.$store.state.auth.user.appType;
    },
    ...mapGetters('commonDataStore',['supportList']),
  },
  methods: {
    changeBreadcrumbMethod(val) {
      let selectUrl = this.breadcrumbDataList.filter((x) => x.key === val);
      if (selectUrl.length === 1) {
        let dataList = selectUrl[0].value;
        sessionStorage.setItem("breadcrumbList", JSON.stringify(dataList));
        this.$store.dispatch("layout/setBreadcrumbActions", dataList);
        console.log(sessionStorage)
      }
    },
  },

}
</script>
<style lang="scss" scoped>
.menu-main {
  min-height: 950px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 210px;
  min-height: 700px;
  font-family: Arial;
}

.router-link {
  text-decoration: none;
}

.el-menu-item {
  background-color: #1f2d3d !important;
}

.el-menu-item:hover {
  background-color: #49575f !important;
}

/deep/ .el-message-box {
  display: inline-block;
  vertical-align: top !important;
  width: 420px;
  padding-bottom: 10px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  font-size: 18px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/deep/ .el-message-box__wrapper::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: top !important;
}

.el-menu {
  margin-bottom: 20px;
}

.onekids-title {
  font-weight: bold;
}
</style>
