//studentStore
import KidsGroupService from "@/services/KidsService/KidsGroupService";

export const studentStore = {
    namespaced: true,
    state: {
        kidsGroupList: [],
        studentList: [],
    },
    getters: {
        kidsGroupList: (state) => state.kidsGroupList,
        studentList: (state) => state.studentList,
    },
    mutations: {
        SET_KidsGroupList(state, kidsGroupList) {
            state.kidsGroupList = kidsGroupList;
        },
        ADD_KidsGroupList(state, newGroup) {
            state.kidsGroupList.push(newGroup); // Thêm Group vào danh sách
        },
        DELETE_KidsGroupList(state, idGroup) {
            state.kidsGroupList = state.kidsGroupList.filter(user => user.id !== idGroup); // Xoá Group khỏi danh sách
        },
        SET_StudentList(state, studentList) {
            state.studentList = studentList;
        },
    },
    // thực hiện hành động gọi api
    actions: {
        // Lấy toàn bộ các nhóm trong trường
        fetchDataKidsGroupList({commit}) {
            return KidsGroupService.getAll(1,50)
                .then((response) => {
                    commit('SET_KidsGroupList', response.data.data.kidsGroupResponseList)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        // Lấy toàn bộ các học sinh trong trường
        fetchDataStudentList({commit}) {
            return KidsGroupService.getAllKidsInGroup()
                .then((response) => {
                    commit('SET_StudentList', response.data.data)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    }

};
