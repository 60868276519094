var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-wrapper" },
    [
      _vm.getAppTypeUserLogin == _vm.appType.PARENT
        ? _c("MenuParent", { staticStyle: { float: "left" } })
        : _vm._e(),
      _vm.getAppTypeUserLogin == _vm.appType.SCHOOL
        ? _c("MenuSchool", { staticStyle: { float: "left" } })
        : _vm._e(),
      _vm.getAppTypeUserLogin == _vm.appType.TEACHER
        ? _c("MenuTeacher", { staticStyle: { float: "left" } })
        : _vm._e(),
      _vm.getAppTypeUserLogin == _vm.appType.SUPPER_SCHOOL
        ? _c("MenuSuperSchool", { staticStyle: { float: "left" } })
        : _vm._e(),
      _vm.getAppTypeUserLogin == _vm.appType.SUPPER_SYSTEM ||
      _vm.getAppTypeUserLogin == _vm.appType.SYSTEM
        ? _c("MenuAdminManagement", { staticStyle: { float: "left" } })
        : _vm._e(),
      _c("navbar"),
      _c("app-main"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }