//Lấy danh sách các đaại lý, các trường
import AgentService from "@/services/AdminService/AgentService";
import SchoolService from "@/services/SchoolService/SchoolService";
import SchoolConfigService from "@/services/SchoolService/SchoolConfigService";

export const schoolStore = {
    namespaced: true,
    state: {
        agentList: [],
        schoolList: [],
        schoolData: {},
    },

    getters: {
        agentList: (state) => state.agentList,
        schoolList: (state) => state.schoolList,
        schoolData: (state) => state.schoolData,
        schoolInfo: (state) => [
            "Phần mềm quản lý trường học OneKids",
            "Tên Trường: " + state.schoolData.schoolName,
            "SDT: " + state.schoolData.schoolPhone + ", Email: " + state.schoolData.schoolEmail,
        ]
    },

    mutations: {
        SET_AgentList(state, agentList) {
            state.agentList = agentList;
        },
        SET_SchoolList(state, schoolList) {
            state.schoolList = schoolList;
        },
        SET_SchoolData(state, schoolData) {
            state.schoolData = schoolData;
        },
    },
    // thực hiện hành động gọi api
    actions: {
        fetchDataAgentList({commit}) {
            return AgentService.getAllCommon()
                .then((resp) => {
                    commit('SET_AgentList', resp.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        fetchDataSchoolList({commit}, idAgent) {
            return SchoolService.getSchoolInAgent(idAgent)
                .then((resp) => {
                    commit('SET_SchoolList', resp.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });

        },

        // Lấy thông tin trường hiện tại
        fetchDataSchoolData({commit},appTye) {
            return SchoolConfigService.getSchoolData(appTye)
                .then((resp) => {
                    commit('SET_SchoolData', resp.data.data);
                })
                .catch((err) => {
                    console.log(err)
                });
        }
    }

};
