<template>
  <el-dialog
      title="Đổi mật khẩu"
      :visible.sync="dialogVisible"
      width="570px"
      :before-close="closeDialog"
      :close-on-click-modal="false"
  >
    <el-form
        label-width="160px"
        :model="passwordData"
        label-position="left"
        :rules="rules"
        ref="passwordData"
    >
      <el-form-item label="Mật khẩu cũ" prop="oldPassword">
        <el-input
            prefix-icon="el-icon-lock"
            v-model="passwordData.oldPassword"
            @keyup.enter.native="submitForm"
            placeholder="Nhập mật khẩu cũ"
            clearable
            show-password
        ></el-input>
      </el-form-item>
      <el-form-item label="Mật khẩu mới" prop="password">
        <el-input
            prefix-icon="el-icon-lock"
            v-model="passwordData.password"
            @keyup.enter.native="submitForm"
            placeholder="Nhập mật khẩu mới"
            clearable
            show-password
        ></el-input>
      </el-form-item>
      <el-form-item label="Xác nhận mật khẩu" prop="confirmPassword">
        <el-input
            prefix-icon="el-icon-lock"
            v-model="passwordData.confirmPassword"
            @keyup.enter.native="submitForm"
            placeholder="Nhập lại mật khẩu mới"
            clearable
            show-password
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="danger" size="medium" @click="closeDialogByButton">
        <i class="el-icon-circle-close"/>
        <span>Đóng</span>
      </el-button>
      <el-button
          type="success"
          size="medium"
          :loading="loadingButton"
          mini
          @click="submitForm"
      >
        <i class="el-icon-circle-check"/>
        <span>Lưu</span>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import CredentialService from "@/services/CredentialService";

export default {
  props: {
    dialogVisible: null,
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value !== this.passwordData.password) {
        callback(new Error("Mật khẩu không khớp"));
      } else {
        callback();
      }
    };
    return {
      passwordData: {
        oldPassword: "",
        password: "",
        confirmPassword: "",
      },
      loadingButton: false,
      rules: {
        oldPassword: [
          {
            required: true,
            message: "Mật khẩu cũ không được để trống",
            trigger: "blur",
          },
          {
            min: 6,
            message: "Mật khẩu phải lớn hơn hoặc bằng 6 ký tự",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Mật khẩu mới không được để trống",
            trigger: "blur",
          },
          {
            min: 6,
            message: "Mật khẩu phải lớn hơn hoặc bằng 6 ký tự",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: "Xác nhận mật khẩu không được để trống",
            trigger: "blur",
          },
          {
            min: 6,
            message: "Mật khẩu phải lớn hơn hoặc bằng 6 ký tự",
            trigger: "blur",
          },
          {
            validator: validatePass,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    //reset when click x
    closeDialog() {
      this.$emit("dialog-close");
      setTimeout(() => {
        this.$refs["passwordData"].resetFields();
      }, 300);
    },
    //click when click button
    closeDialogByButton() {
      this.$emit("dialog-close");
    },
    resetFormData() {
      this.$refs["passwordData"].resetFields();
    },
    submitForm() {
      this.$refs["passwordData"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          const data = {...this.passwordData}
          CredentialService.changePassword(data)
              .then((resp) => {
                this.$confirm(resp.data.message + '! Xin vui lòng đăng nhập lại', 'Thông báo', {
                  confirmButtonText: 'OK',
                  showCancelButton: false,
                  type: 'success'
                }).then(() => {
                  localStorage.clear();
                  this.$router.push("/login");
                })
                this.closeDialogByButton();
              })
              .catch((err) => {
                this.$message({
                  message: err.response.data.message,
                  type: "error",
                });
              })
              .finally(() => {
                setTimeout(() => {
                  this.loadingButton = false;
                }, 500);
              });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__title {
  font-size: 25px;
  color: #606266;
}

/deep/ .el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

/deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
  content: "*";
  color: #f56c6c;
  margin-left: 3px;
}

/deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: "";
  color: #f56c6c;
  margin-left: -4px;
}
</style>
