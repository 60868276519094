import http from "@/http-common";

export const commonDataStore = {
    namespaced: true,
    state: {
        supportList: [],
    },
    getters: {
        supportList: (state) => state.supportList,
    },
    mutations: {
        SET_SupportList(state, idInfoList) {
            state.supportList = idInfoList;
        },
    },

    actions: {
        fetchDataSupportList({commit}) {
            return http.get(`/common-data/support`)
                .then((resp) => {
                    commit('SET_SupportList', resp.data.data);
                }).catch(error => {
                    console.error(error);
                });
        },
    }
}