import NewSalaryService from "@/services/EmployeeService/NewSalaryService";

export const staticSalaryNew = {
    namespaced: true,
    state:{
        loadingData: true,
        responseFilterList: [],
        dataSearch:{},
        idInfoList: [],
        updateStatus: false,
    },

    mutations:{
        setLoadingData(state){
            state.loadingData = true
        },
        setIdEmployeeList(state, payload) {
            state.idInfoList = payload.join(',')
        },
        setUpdateStatusExcel(state) {
            state.updateStatus = !state.updateStatus
        },
        setResponseFilterList(state, payload) {
            state.loadingData = true;
            if (payload) {
                state.responseFilterList = payload;
                state.loadingData = false;
            }
        },
        setUpdateDataSearch(state, payload){
            state.dataSearch = payload; // dùng lấy dữ liệu data tìm kiếm
        }

    },

    actions:{
        getResponseFilterList({commit}, filterParams) {
            return NewSalaryService
                .filterStaticEmployeeSalary(filterParams)
                .then((data) => {
                    commit('setUpdateDataSearch', filterParams);
                    commit('setResponseFilterList', data.data);
                })
                .catch((error) => {
                    console.log(error)
                });
        },
    }
}