var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-main" },
    [
      _c(
        "el-menu",
        {
          staticClass: "el-menu-vertical-demo",
          attrs: {
            collapse: _vm.getIsOpenCloseMenu,
            "background-color": "#304156",
            "text-color": "#bfcbd9",
            "active-text-color": "#409EFF",
            "unique-opened": true,
          },
        },
        [
          _c(
            "router-link",
            { staticClass: "router-link", attrs: { to: "/dashboard" } },
            [
              _c("el-menu-item", { attrs: { index: "1" } }, [
                _c("i", { staticClass: "el-icon-s-home" }),
                _c(
                  "span",
                  {
                    staticClass: "onekids-title",
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [_vm._v("ONEKIDS PARENT")]
                ),
              ]),
            ],
            1
          ),
          _c(
            "router-link",
            { staticClass: "router-link", attrs: { to: "/parent/dashboard" } },
            [
              _c(
                "el-menu-item",
                {
                  attrs: { index: "2" },
                  on: {
                    click: function ($event) {
                      return _vm.changeBreadcrumbMethod("parent-dashboard")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-s-data" }),
                  _c("span", [_vm._v("Biểu đồ")]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-submenu",
            { attrs: { index: "3" } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "el-icon-document" }),
                _c("span", [_vm._v("Bản tin")]),
              ]),
              _c(
                "router-link",
                { staticClass: "router-link", attrs: { to: "/parent/notify" } },
                [
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "3-1" },
                      on: {
                        click: function ($event) {
                          return _vm.changeBreadcrumbMethod("parent-notify")
                        },
                      },
                    },
                    [_vm._v("Thông báo\n        ")]
                  ),
                ],
                1
              ),
              _c(
                "router-link",
                { staticClass: "router-link", attrs: { to: "/parent/news" } },
                [
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "3-2" },
                      on: {
                        click: function ($event) {
                          return _vm.changeBreadcrumbMethod("parent-news")
                        },
                      },
                    },
                    [_vm._v("Tin tức nhà trường\n        ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-submenu",
            { attrs: { index: "4" } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "el-icon-notebook-1" }),
                _c("span", [_vm._v("Hoạt động")]),
              ]),
              _c(
                "router-link",
                { staticClass: "router-link", attrs: { to: "/parent/album" } },
                [
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "4-1" },
                      on: {
                        click: function ($event) {
                          return _vm.changeBreadcrumbMethod("parent-album")
                        },
                      },
                    },
                    [_vm._v("Album ảnh\n        ")]
                  ),
                ],
                1
              ),
              _c(
                "router-link",
                { staticClass: "router-link", attrs: { to: "/parent/video" } },
                [
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "4-2" },
                      on: {
                        click: function ($event) {
                          return _vm.changeBreadcrumbMethod("parent-video")
                        },
                      },
                    },
                    [_vm._v("Video\n        ")]
                  ),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: { to: "/parent/video/other" },
                },
                [
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "5-3" },
                      on: {
                        click: function ($event) {
                          return _vm.changeBreadcrumbMethod(
                            "parent-video-other"
                          )
                        },
                      },
                    },
                    [_vm._v("Thư viện\n        ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-submenu",
            { attrs: { index: "5" } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "el-icon-question" }),
                _c("span", [_vm._v("Hỗ trợ & hướng dẫn")]),
              ]),
              _vm._l(_vm.supportList, function (item) {
                return _c(
                  "a",
                  {
                    key: item.name,
                    staticClass: "router-link",
                    staticStyle: { color: "#bfcbd9", display: "block" },
                    attrs: { href: item.link, target: "_blank" },
                  },
                  [
                    _c("el-menu-item", { attrs: { index: item.name } }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }